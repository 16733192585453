import { NavLink } from 'react-router-dom'
import './styles.scss'

const DesktopNavigation = () => {
  return (
    <nav id="desktop-navigation" className="hide-mobile">
      <NavLink to="/">Dashboard</NavLink>
      <NavLink to="/leads">Leads</NavLink>
      <NavLink to="/leads-list">Leads List</NavLink>
      <NavLink to="/project-tracker">Project Tracker</NavLink>
      <NavLink to="/activity">Activity</NavLink>
    </nav>
  )
}

export default DesktopNavigation

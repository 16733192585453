import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import DeleteActivityButton from './components/DeleteActivityButton'

const ActivityDeleteMutation = gql`
  mutation DeleteActivity($id: ID!) {
    deleteActivity(id: $id) {
      id
    }
  }
`

const ComponentWithActivityDeleteMutation = graphql(ActivityDeleteMutation, {
  props: ({ mutate }) => ({
    onDelete: (id) =>
      mutate({
        variables: { id },
        refetchQueries: ['LeadActivities', 'ActivitiesGoalsList'],
      }),
  }),
})(DeleteActivityButton)

export default ComponentWithActivityDeleteMutation

import React, { Component } from 'react'

import Modal from '../../../core/components/Modal'
import UserForm from '../../../UserForm'

class AdminProfileCreateUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
    }

    this.onAddClick = this.onAddClick.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onUserAdded = this.onUserAdded.bind(this)
  }

  onAddClick() {
    this.setState({ isModalOpen: true })
  }

  onModalClose() {
    this.setState({ isModalOpen: false })
  }

  onUserAdded(user) {
    this.onModalClose()
    this.props.userCreated(user)
  }

  render() {
    const { user } = this.props

    if (!user.admin) return null

    const { isModalOpen } = this.state

    return (
      <div id="admin-profile-create-user">
        <div className="row">
          <div className="columns six">
            <span className="btn primary full-width" onClick={this.onAddClick}>
              Add New User
            </span>
          </div>
        </div>
        <Modal
          id="create-user-modal"
          isOpen={isModalOpen}
          label="Add User"
          onClose={this.onModalClose}>
          <h5>Add New User</h5>
          <UserForm
            onComplete={this.onUserAdded}
            onCancel={this.onModalClose}
          />
        </Modal>
      </div>
    )
  }
}

export default AdminProfileCreateUser

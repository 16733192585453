import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Label from '../../../core/components/Label'
import './styles.scss'
const LinkField = (props) => {
  const { label, url, text } = props

  const value = url ? (
    <Link to={url} target="_blank">
      {text}
    </Link>
  ) : (
    text
  )
  return (
    <div className="field link-field">
      <Label text={label} />
      <span className="value link">{value}</span>
    </div>
  )
}
LinkField.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
}

export default LinkField

import { REMOVE_MESSAGE } from '../core/action-types'

export const dismiss = (timestamp) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_MESSAGE,
      payload: timestamp,
    })
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdDelete } from 'react-icons/md'

import Modal from '../../../core/components/Modal'

class DeleteActivityButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
    }

    this.onDeleteClick = this.onDeleteClick.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.deleteActivity = this.deleteActivity.bind(this)
  }

  onDeleteClick() {
    this.setState({ isModalOpen: true })
  }

  onModalClose() {
    this.setState({ isModalOpen: false })
  }

  deleteActivity() {
    const { id, revert } = this.props
    this.props.onDelete(id, revert).then(() => {
      this.setState({ isModalOpen: false })
    })
  }

  render() {
    const { enabled } = this.props
    if (!enabled) return null

    return (
      <div className="delete-activity-button">
        <MdDelete onClick={this.onDeleteClick} size={20} />
        <Modal
          id="delete-activity-modal"
          isOpen={this.state.isModalOpen}
          label="Delete Activity"
          onClose={this.onModalClose}>
          <div>Are you sure you want to delete this activity?</div>
          <blockquote>{this.props.content}</blockquote>
          <div>
            <span className="btn primary" onClick={this.deleteActivity}>
              Delete
            </span>
            <span className="btn" onClick={this.onModalClose}>
              Cancel
            </span>
          </div>
        </Modal>
      </div>
    )
  }
}

DeleteActivityButton.propTypes = {
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  revert: PropTypes.bool,
}

DeleteActivityButton.defaultProps = {
  enabled: false,
  revert: false,
}

export default DeleteActivityButton

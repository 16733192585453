import axios from 'axios'
import { getSessionKey } from '../../common/local-storage'
import { ADD_MESSAGE } from '../../modules/core/action-types'

export const uploadBuzzBuzzHomeCsv = ({ file, name }) => {
  return (dispatch) => {
    let data = new FormData()
    data.append('file', file)
    data.append('name', name)
    const token = getSessionKey()
    const auth = token ? `Bearer ${token}` : null
    return axios({
      method: 'post',
      url: `${import.meta.env.REACT_APP_API_URL}/ingest/buzzbuzzhome`,
      data,
      headers: {
        Authorization: auth,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        dispatch({
          type: ADD_MESSAGE,
          payload: { type: 'info', content: res.data.message },
        })
      })
      .catch((error) => {
        dispatch({
          type: ADD_MESSAGE,
          payload: { type: 'error', content: error.response.data.message },
        })
      })
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import UserSelectField from '../../core/components/UserSelectField'

const AddLeadAssignmentForm = (props) => {
  const potentialUsers = props.data.users || []
  const assignedUsers = props.lead.assignedUsers || []
  const assignableUsers = potentialUsers.filter(
    (user) =>
      !assignedUsers.find((assignedUser) => assignedUser.id === user.id),
  )

  const users = assignableUsers.map((user) => ({
    label: user.name,
    value: { userId: parseInt(user.id), leadId: parseInt(props.lead.id) },
    user,
  }))

  return (
    <UserSelectField
      placeholder="Add Project Owner"
      options={users}
      onChange={props.onSelect}
    />
  )
}

AddLeadAssignmentForm.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.string,
    assignedUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
}

export default AddLeadAssignmentForm

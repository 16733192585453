import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MdMenu, MdClose } from 'react-icons/md'
import { slide as Menu } from 'react-burger-menu'
import './styles.scss'

const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false)

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <nav id="mobile-navigation" className="hide-desktop">
      <Menu
        right
        isOpen={isOpen}
        onStateChange={({ isOpen }) => setIsOpen(isOpen)}
        noOverlay
        width={200}
        customBurgerIcon={<MdMenu size={30} color={'#fff'} />}
        customCrossIcon={<MdClose color={'#fff'} />}>
        <NavLink onClick={closeMenu} to="/">
          Dashboard
        </NavLink>
        <NavLink onClick={closeMenu} to="/leads">
          Leads
        </NavLink>
        <NavLink onClick={closeMenu} to="/leads-list">
          Leads List
        </NavLink>
        <NavLink onClick={closeMenu} to="/project-tracker">
          Project Tracker
        </NavLink>
        <NavLink onClick={closeMenu} to="/activity">
          Activity
        </NavLink>
      </Menu>
    </nav>
  )
}

export default MobileNavigation

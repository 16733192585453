import { connect } from 'react-redux'
import Form from './components/Form'
import { login } from './actions'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(login(data)),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)(Form)

export default connector

import { ADD_MESSAGE, REMOVE_MESSAGE } from '../action-types'

export const messages = (state = [], action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [
        ...state,
        Object.assign(action.payload, {
          timestamp: Date.now(),
        }),
      ]
    case REMOVE_MESSAGE:
      return state.filter(({ timestamp }) => action.payload !== timestamp)
    default:
      return state
  }
}

export default {
  messages,
}

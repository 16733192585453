import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import { Link } from 'react-router-dom'

import ResponsiveTable from '../../../ResponsiveTable'
import {
  LeadStatusTypes,
  LeadPriorityTypes,
} from '../../../../common/enumerations'

const DashboardLeads = ({ leads }) => {
  const leadsList = leads.map((lead) => ({
    ...lead,
    updatedAt: Moment(lead.updatedAt).fromNow(),
    status: LeadStatusTypes[lead.status].label,
    priority: LeadPriorityTypes[lead.priority],
  }))

  const columns = [
    {
      cellRenderer: (param) => {
        return <Link to={`/leads/${param.data.id}`}>{param.value}</Link>
      },
      field: 'projectName',
      flex: 2,
      headerName: 'Project Name',
      sortable: true,
    },
    {
      field: 'developerName',
      flex: 1,
      headerName: 'Client',
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
    },
    {
      field: 'units',
      headerName: 'Units',
      sortable: true,
      width: 80,
    },
    {
      field: 'percentageSold',
      headerName: '% Sold',
      sortable: true,
      width: 80,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      sortable: true,
      width: 80,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      sortable: true,
      width: 110,
    },
  ]

  return (
    <div id="dashboard-leads">
      <ResponsiveTable
        rowData={leadsList}
        columnDefs={columns}
        paginationPageSize={10}
      />
    </div>
  )
}

DashboardLeads.propTypes = {
  leads: PropTypes.array,
}

DashboardLeads.defaultProps = {
  leads: [],
}

export default DashboardLeads

import serializeToCsv from '../../common/reporting/csv-serializer'
import writeCsvFile from '../../common/reporting/csv-file-writer'
import { Lead as LeadLabels } from '../../common/property-labels'
import { CSV_EXPORT_ERRORED } from '../core/action-types'

export const downloadCsv = (leads) => {
  return (dispatch) => {
    const leadsFields = [
      'projectName',
      'developerName',
      'units',
      'percentageSold',
      'priority',
      'status',
      'salesStartedAt',
      'salesStatus',
      'constructionStatus',
      'zoningDetail',
      'clientType',
      'closedAt',
      'closingOutcome',
      'createdAt',
      'updatedAt',
    ]

    const leadsFieldNames = leadsFields.map((field) => LeadLabels[field].label)

    const updatedLeads = leads.map((lead) => {
      const updatedLead = { ...lead }
      leadsFields.forEach((key) => {
        const property = LeadLabels[key]
        if (property.value) {
          const value = property.value(lead[key])
          updatedLead[key] = value
        }
      })
      return updatedLead
    })

    try {
      const csv = serializeToCsv(updatedLeads, leadsFields, leadsFieldNames)
      writeCsvFile(csv, 'leads-export')
    } catch (e) {
      return dispatch({
        type: CSV_EXPORT_ERRORED,
        payload: 'Error creating Leads CSV file.',
      })
    }
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import Modal from '../../../core/components/Modal'

class AddLinkModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      label: '',
      labelError: null,
      uri: '',
      uriError: null,
    }

    this.onClose = this.onClose.bind(this)
    this.updateLink = this.updateLink.bind(this)
    this.onSaveClick = this.onSaveClick.bind(this)
  }

  onClose() {
    this.setState({
      label: '',
      labelError: null,
      uri: '',
      uriError: null,
    })
    this.props.onClose()
  }

  updateLink(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
  }

  onSaveClick() {
    const { label, uri } = this.state
    const leadId = this.props.leadId
    const re = /(^https?:\/\/)(.*)/gi

    if (re.test(uri)) {
      this.props
        .onSaveClick({
          label,
          uri: uri.replace(re, (match, p1, p2) => `${p1.toLowerCase()}${p2}`),
          leadId,
        })
        .then(this.onClose)
        .catch(({ graphQLErrors }) => {
          graphQLErrors.forEach((error) => {
            switch (error.message) {
              case 'Missing Label':
                this.setState({ labelError: error.message, uriError: null })
                break
              case 'Missing URI':
                this.setState({ uriError: 'Missing URL', labelError: null })
                break
              case 'Malformed URI':
                this.setState({ uriError: 'Invalid URL', labelError: null })
                break
            }
          })
        })
    } else {
      this.setState({
        uriError: 'Invalid URL - Links must begin with http:// or https://',
        labelError: null,
      })
    }
  }

  render() {
    const { isOpen } = this.props
    const { label, labelError, uri, uriError } = this.state

    const modalProps = {
      isOpen,
      label: 'New Link',
      onClose: this.onClose,
    }

    return (
      <Modal {...modalProps} id="add-link-modal">
        <h5>New Link</h5>
        <label htmlFor="link-title">Title</label>
        <input
          type="text"
          id="link-title"
          value={label}
          className={labelError ? 'invalid' : ''}
          onChange={(event) => this.updateLink('label', event.target.value)}
        />
        <span className="errorDetail">{labelError}</span>
        <label htmlFor="link-uri">URL</label>
        <input
          type="text"
          id="link-uri"
          className={uriError ? 'invalid' : ''}
          value={uri}
          onChange={(event) => this.updateLink('uri', event.target.value)}
        />
        <span className="errorDetail">{uriError}</span>
        <div>
          <span className="btn primary" onClick={this.onSaveClick}>
            Save
          </span>
          <span className="btn" onClick={this.onClose}>
            Cancel
          </span>
        </div>
      </Modal>
    )
  }
}

AddLinkModal.propTypes = {
  leadId: PropTypes.number.isRequired,
  onSaveClick: PropTypes.func.isRequired,
}

export default AddLinkModal

import React from 'react'
import PropTypes from 'prop-types'
import { MdClose, MdCheck, MdDoNotDisturb } from 'react-icons/md'
import './styles.scss'

import {
  LeadStatusTypes,
  ClosingOutcomeTypes,
} from '../../../../common/enumerations'

const Flag = (props) => {
  const { status, closingOutcome, activities, goalNeeded, missedGoals } = props

  const closed = status === 'CLOSED'
  const hasActivity = activities.length > 0
  const hasMissedGoals = missedGoals.length > 0

  const contentClass = ['content', closed ? closingOutcome : ''].join(' ')

  const flagClass = [
    'flag',
    hasActivity ? 'has-activity' : '',
    hasMissedGoals ? 'missed-goal' : '',
    goalNeeded ? 'goal-needed' : '',
  ].join(' ')

  let content

  if (closed) {
    switch (closingOutcome) {
      case 'WON':
        content = <MdCheck />
        break
      case 'LOST':
        content = <MdClose />
        break
      case 'REJECTED':
        content = <MdDoNotDisturb />
        break
    }
  } else if ((hasActivity || hasMissedGoals) && !goalNeeded) {
    if (hasMissedGoals) {
      content = missedGoals.length
    } else {
      content = activities.length
    }
  }

  return (
    <div className="flag-container">
      <div className={contentClass}>{content}</div>
      <div className={flagClass} />
    </div>
  )
}

Flag.propTypes = {
  status: PropTypes.oneOf(Object.keys(LeadStatusTypes)).isRequired,
  closingOutcome: PropTypes.oneOf(Object.keys(ClosingOutcomeTypes)),
  activities: PropTypes.array.isRequired,
  goalNeeded: PropTypes.bool.isRequired,
  missedGoals: PropTypes.array.isRequired,
}

export default Flag

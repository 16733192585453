import React, { Component } from 'react'
import { MdFileDownload } from 'react-icons/md'
import { filteredLeads } from '../../../../common/filtered-leads'

class LeadsExportButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      leads: [],
    }
    this.onClick = this.onClick.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.leads) {
      this.setState({
        leads: nextProps.data.leads,
      })
    }
  }

  render() {
    return (
      <span onClick={this.onClick} className="btn link">
        <MdFileDownload />
        &nbsp; Download as CSV
      </span>
    )
  }

  onClick() {
    const leads = filteredLeads(this.state.leads, this.props.filters)
    this.props.downloadCsv(leads)
  }
}
export default LeadsExportButton

import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import GoalForm from './components/GoalForm'
import { updateGoalForm, clearGoalForm, validateGoalForm } from './actions'

const mapStateToProps = (state) => {
  const { goalFormData, goalFormErrors } = state

  return {
    goalFormData,
    goalFormErrors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateForm: (formData) => dispatch(updateGoalForm(formData)),
    clearForm: () => dispatch(clearGoalForm()),
    validate: (fields) => dispatch(validateGoalForm(fields)),
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const id = ownProps.id
  const goalFormData = Object.assign({}, stateProps.goalFormData, { id })
  const newStateProps = Object.assign({}, stateProps, { goalFormData })

  const newProps = Object.assign({}, newStateProps, ownProps, dispatchProps)

  return newProps
}

const CreateGoalMutation = gql`
  mutation CreateGoal($id: Int!, $description: String!, $deadline: DateTime!) {
    addGoal(leadId: $id, description: $description, deadline: $deadline) {
      id
      description
      deadline
    }
  }
`

const componentWithCreate = graphql(CreateGoalMutation, {
  props: ({ ownProps, mutate }) => ({
    onSubmit: ({ id, description, deadline }) => {
      return mutate({
        variables: { id, description, deadline },
        refetchQueries: ['LeadActivities', 'LeadGoals', 'Lead'],
      })
        .then(() => ownProps.clearForm())
        .catch(() => ownProps.validate({ description, deadline }))
    },
  }),
})(GoalForm)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentWithCreate)

export default connector

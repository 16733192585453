import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import Moment from 'moment'
import './styles.scss'

import Label from '../Label'
import Value from '../Value'

class DateField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      value: null,
    }

    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props
    this.setState({ value: value ? Moment(value).utc().toDate() : null })
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.state.value) {
      this.setState({ value: value ? Moment(value).utc().toDate() : null })
    }
  }

  onClick() {
    if (!this.props.editable) {
      return
    }

    this.setState({ editing: true })
  }

  onChange(value) {
    if (!value && !this.props.nullable) {
      value = this.state.value
    }

    const newValue = value ? Moment(value).utc().startOf('day').toDate() : null

    this.setState({ value: newValue }, () => {
      this.props.onChange(newValue.toISOString())
    })
  }

  onBlur() {
    this.setState({ editing: false })
  }

  render() {
    const {
      label,
      editable,
      keepEditorOpen,
      className,
      placeholderText,
      minDate,
      maxDate,
    } = this.props

    const { editing, value } = this.state

    const classNames = [
      editable ? 'editable' : null,
      value ? null : 'invalid',
      className,
    ].join(' ')

    const editProps = {
      className: `input ${className}`,
      calendarClassName: 'input',
      selected: value,
      autoFocus: !keepEditorOpen,
      dateFormat: 'dd/MM/yyyy',
      onChange: this.onChange,
      onBlur: this.onBlur,
      placeholderText: placeholderText || 'DD/MM/YYYY',
      minDate,
      maxDate,
    }

    const valueProps = {
      className: classNames,
      value: value ? Moment(value).format('dd/MM/yyyy').toString() : 'Unknown',
      onClick: this.onClick,
    }

    const showEditor = editing || keepEditorOpen

    return (
      <div className="field date">
        <Label text={label} />
        {(showEditor && <DatePicker {...editProps} />) || (
          <Value {...valueProps} />
        )}
      </div>
    )
  }
}

DateField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Moment),
    PropTypes.instanceOf(Date),
  ]),
  editable: PropTypes.bool,
}

export default DateField

import gql from 'graphql-tag'

export const GetForecastsQuery = gql`
  fragment ProjectDetails on ProjectForecastRow {
    wgnetClientName
    projectName
    units
    salesStartedAt
    salesStatus
    constructionStatus
    clientType
    tarionBond
    potentialDiFacility
    averageUnitPrice
    averageRevenues
  }

  query Query($projectForecastOpts: ProjectForecastOpts!) {
    projectForecast(projectForecastOpts: $projectForecastOpts) {
      reportName
      rows {
        ...ProjectDetails
      }
      messages
      sample {
        ...ProjectDetails
      }
    }
  }
`

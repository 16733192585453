import React from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import './styles.scss'

const Graph = ({ data, className }) => {
  const totals = data.reduce(
    (acc, item) => {
      acc.leads += item.leads
      acc.units += item.units
      return acc
    },
    { leads: 0, units: 0 },
  )

  return (
    <div className={`graph ${className}`}>
      <div className="info">
        <div className="left">{totals.leads}</div>
        <div className="right">
          <div className="title">Leads {className}</div>
          <div className="unit-count">
            {totals.units.toLocaleString()} units
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={160}>
        <AreaChart
          data={data}
          margin={{ top: 5, right: 0, bottom: 0, left: 0 }}>
          <defs>
            <linearGradient
              id={`gradient-${className}`}
              x1="0"
              x2="0"
              y1="0"
              y2="1">
              <stop className="stop-1" offset="0%" />
              <stop className="stop-2" offset="100%" />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="range"
            tickLine={false}
            interval="preserveStartEnd"
            tick={<CustomizedAxisTick />}
            minTickGap={0}
          />
          <YAxis hide type="number" interval={0} />
          <CartesianGrid vertical={false} />
          <Tooltip
            content={TooltipContent}
            isAnimationActive={false}
            cursor={false}
          />
          <Area
            dataKey="leads"
            fill={`url(#gradient-${className})`}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

const TooltipContent = (props) => {
  const { active } = props

  if (!active) return null

  const { label, payload } = props

  return (
    <div className="tooltip">
      {payload[0].value} Deals at
      <br />
      {getUnitRange(label)} Units
    </div>
  )
}

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props
  const tickValue = payload.value >= 500 ? '500+' : `${payload.value}`
  return (
    <g transform={`translate(${x + 10},${y - 5})`}>
      <text x={0} y={0} dy={16} textAnchor="end">
        {tickValue}
      </text>
    </g>
  )
}

const getUnitRange = (num) => {
  switch (num) {
    case 10:
      return '0 to 99'
    case 500:
      return '500+'
    default:
      return `${num} to ${num + 99}`
  }
}

Graph.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
}

Graph.defaultProps = {
  data: [],
  className: '',
}

export default Graph

import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import LeadCloseForm from './components/LeadCloseForm'

const LeadCloseMutation = gql`
  mutation CloseLead(
    $id: ID!
    $closingOutcome: ClosingOutcomeType!
    $closingReasons: String
    $closedAt: DateTime!
    $closingComment: String
  ) {
    closeLead(
      id: $id
      closingOutcome: $closingOutcome
      closingReasons: $closingReasons
      closedAt: $closedAt
      closingComment: $closingComment
    ) {
      status
    }
  }
`

const ComponentWithLeadCloseMutation = graphql(LeadCloseMutation, {
  props: ({ mutate }) => ({
    onSubmitForm: ({
      id,
      closingOutcome,
      closingReasons,
      closedAt,
      closingComment,
    }) =>
      mutate({
        variables: {
          id,
          closingOutcome,
          closingReasons,
          closedAt,
          closingComment,
        },
        refetchQueries: ['Lead', 'LeadList', 'LeadActivities'],
      }),
  }),
})(LeadCloseForm)

export default ComponentWithLeadCloseMutation

import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import LeadLinks from './components/LeadLinks'

const LinkListQuery = gql`
  query LinkList($leadId: Int!) {
    links(leadId: $leadId) {
      id
      leadId
      label
      uri
    }
  }
`

const AddLinkMutation = gql`
  mutation AddLink($label: String!, $uri: String!, $leadId: Int!) {
    addLink(label: $label, uri: $uri, leadId: $leadId) {
      id
    }
  }
`

const RemoveLinkMutation = gql`
  mutation RemoveLink($id: ID!) {
    deleteLink(id: $id) {
      id
    }
  }
`

const ComponentWithData = graphql(LinkListQuery, {
  options: (props) => ({
    variables: { leadId: props.id },
    forceFetch: true,
  }),
})(LeadLinks)

const ComponentWithAddMutation = graphql(AddLinkMutation, {
  props: ({ mutate }) => ({
    onSaveLink: (data) =>
      mutate({
        variables: data,
        refetchQueries: ['LinkList'],
      }),
  }),
})(ComponentWithData)

const ComponentWithRemoveMutation = graphql(RemoveLinkMutation, {
  props: ({ mutate }) => ({
    onRemoveLink: (id) =>
      mutate({
        variables: { id },
        refetchQueries: ['LinkList'],
      }),
  }),
})(ComponentWithAddMutation)

export default ComponentWithRemoveMutation

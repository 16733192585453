import React from 'react'
import PropTypes from 'prop-types'

import UserForm from '../../../UserForm'

const UserProfileBasics = ({ user, setUser }) => {
  return (
    <div id="user-profile-basics">
      <UserForm user={user} onComplete={setUser} />
    </div>
  )
}

UserProfileBasics.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserProfileBasics

import PropTypes from 'prop-types'
import './styles.scss'

const Button = ({ label, onClick, icon, badgeCount }) => {
  return (
    <span onClick={onClick} className="btn link">
      {icon}&nbsp;{label}
      {badgeCount > 0 && (
        <span title={`${badgeCount} items`} className="btn-badge">
          {badgeCount}
        </span>
      )}
    </span>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  badgeCount: PropTypes.number,
}

export default Button

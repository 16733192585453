import Moment from 'moment'
import {
  FORM_CLEARED,
  GOAL_FORM_UPDATED,
  GOAL_FORM_ERRORED,
  COMMENT_FORM_UPDATED,
  COMMENT_FORM_ERRORED,
  LEAD_DETAILS_FORM_UPDATED,
} from '../action-types'
import { COMMENT_FORM } from '../lead-details-form-types'

const defaultFormState = COMMENT_FORM

export const leadDetailsForm = (state = defaultFormState, action) => {
  switch (action.type) {
    case LEAD_DETAILS_FORM_UPDATED:
      return action.payload
    default:
      return state
  }
}

const defaultGoalFormState = {
  id: 0,
  description: '',
  deadline: Moment().utc().startOf('day').toISOString(),
}

export const goalFormData = (state = defaultGoalFormState, action) => {
  switch (action.type) {
    case GOAL_FORM_UPDATED:
      return action.payload
    case FORM_CLEARED:
      return defaultGoalFormState
    default:
      return state
  }
}

const defaultGoalFormErrors = {}

export const goalFormErrors = (state = defaultGoalFormErrors, action) => {
  switch (action.type) {
    case GOAL_FORM_ERRORED:
      return action.payload
    case GOAL_FORM_UPDATED:
    case FORM_CLEARED:
      return defaultGoalFormErrors
    default:
      return state
  }
}

const defaultCommentFormState = {
  leadId: null,
  content: '',
}

export const commentFormData = (state = defaultCommentFormState, action) => {
  switch (action.type) {
    case COMMENT_FORM_UPDATED:
      return action.payload
    case FORM_CLEARED:
      return defaultCommentFormState
    default:
      return state
  }
}

const defaultCommentFormErrors = {}

export const commentFormErrors = (state = defaultCommentFormErrors, action) => {
  switch (action.type) {
    case COMMENT_FORM_ERRORED:
      return action.payload
    case COMMENT_FORM_UPDATED:
    case FORM_CLEARED:
      return defaultCommentFormErrors
    default:
      return state
  }
}

export default {
  leadDetailsForm,
  goalFormData,
  goalFormErrors,
  commentFormData,
  commentFormErrors,
}

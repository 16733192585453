import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import UserForm from './components/UserForm'

const mapStateToProps = (state) => {
  const { user } = state
  return { currentUser: user }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const UserCreateMutation = gql`
  mutation UserCreate($name: String!, $email: String!, $admin: Boolean!) {
    addUser(name: $name, email: $email, admin: $admin) {
      id
      name
      email
      admin
    }
  }
`

const UserUpdateMutation = gql`
  mutation UserUpdate(
    $id: ID!
    $name: String!
    $email: String!
    $admin: Boolean!
  ) {
    updateUser(id: $id, name: $name, email: $email, admin: $admin) {
      id
      name
      email
      admin
      weeklyNotification
      deadlineNotification
      commentNotification
      passwordChangeRequired
      avatarFilename
    }
  }
`

const ComponentWithCreateMutation = graphql(UserCreateMutation, {
  props: ({ mutate }) => ({
    onUserCreate: ({ name, email, admin }) =>
      mutate({
        variables: { name, email, admin },
        refetchQueries: ['UserList'],
      }),
  }),
})(UserForm)

const ComponentWithUpdateMutation = graphql(UserUpdateMutation, {
  props: ({ mutate }) => ({
    onUserUpdate: ({ id, name, email, admin }) =>
      mutate({
        variables: { id, name, email, admin },
      }).then(({ data: { updateUser } }) => updateUser),
  }),
})(ComponentWithCreateMutation)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithUpdateMutation)

export default connector

import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { setSessionKey } from '../../common/local-storage'
import { AUTHENTICATED, PASSWORD_CHANGE_REQUIRED } from '../core/action-types'
import { OK } from '../../common/http-codes'

export const login = ({ email, password, rememberMe }) => {
  return (dispatch) => {
    const request = axios({
      method: 'post',
      url: `${import.meta.env.REACT_APP_API_URL}/authentication/login`,
      data: { email, password, rememberMe },
      config: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    })

    request.then((res) => {
      if (res.status === OK) {
        setSessionKey(res.data.token)
        dispatch({ type: AUTHENTICATED, payload: jwtDecode(res.data.token) })
        if (res.data.passwordChangeRequired) {
          dispatch({ type: PASSWORD_CHANGE_REQUIRED })
        }
      }
    })

    return request
  }
}

import React from 'react'

import {
  SourceTypes,
  ClosingOutcomeTypes,
  ClosingReasonTypes,
} from '../../../../common/enumerations'

export const generateList = (items) => {
  const itemCount = items.length
  if (itemCount > 2) {
    const start = items
      .slice(0, itemCount - 1)
      .map((item, index) => <span key={index}>{item}, </span>)
    const end = items[itemCount - 1]
    return (
      <span>
        {start} and {end}
      </span>
    )
  } else if (itemCount === 2) {
    return (
      <span>
        {items[0]} and {items[1]}
      </span>
    )
  } else {
    return <span>{items[0]}</span>
  }
}

export const LeadAddedContent = ({ source }) => {
  return (
    <div>
      Added from <span>{SourceTypes[source]}</span>
    </div>
  )
}

export const LeadUpdatedContent = ({ fieldsChanged }) => {
  const changes = fieldsChanged.map((field, index) => {
    const value = field.value === null ? 'Unknown' : field.value
    return (
      <span key={index}>
        <span className="field">{field.label}</span> was changed to{' '}
        <span className="value">{value}</span>
      </span>
    )
  })
  return generateList(changes)
}

export const LeadClosedContent = (props) => {
  const { closingOutcome, closingReasons, closingComment, closedAt } = props
  const closingReasonList = closingReasons
    ? closingReasons
        .split(',')
        .map((closingReason) => ClosingReasonTypes[closingReason])
        .join(', ')
    : ''
  const content =
    closingOutcome === 'WON'
      ? ClosingOutcomeTypes[closingOutcome]
      : `${ClosingOutcomeTypes[closingOutcome]} (${closingReasonList})`
  const comment = closingComment ? (
    <div className="comment">&quot;{closingComment}&quot;</div>
  ) : null

  return (
    <div className="lead-closed">
      <div>{content}</div>
      {comment}
      <div className="closed-at">{closedAt}</div>
    </div>
  )
}

export const OwnersEditedContent = ({ owners }) => {
  const users = owners
    .map(({ user }) => user.name)
    .filter((name, index, self) => {
      return self.indexOf(name) === index
    })
  return generateList(users)
}

export const PairingRelatedContent = ({ diff, type }) => {
  const { projectName } = diff
  let preamble = 'It may be possible to link to WG Net Project:'
  let postamble = ''

  if (type === 'PAIRING_ACCEPTED') {
    preamble = 'Lead is now linked to WG Net Project:'
  } else if (type === 'PAIRING_REJECTED') {
    preamble = 'Link to WG Net Project'
    postamble = 'has been rejected'
  }

  return (
    <div>
      <span>{preamble}</span>&nbsp;<span className="value">{projectName}</span>
      &nbsp;<span>{postamble}</span>
    </div>
  )
}

import React from 'react'

const RecoverySuccess = ({ backToLogin }) => {
  return (
    <div id="recovery-success" className="login-form panel">
      <div className="row">
        <div className="columns twelve">
          <p>
            An email has been sent to you with instructions to reset your
            password. Please allow up to 15 minutes for the email to arrive.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="columns twelve">
          <span className="btn primary full-width" onClick={backToLogin}>
            Back to Login
          </span>
        </div>
      </div>
    </div>
  )
}

export default RecoverySuccess

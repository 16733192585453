import React, { Component } from 'react'
import sortBy from 'sort-array'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import withRouter from '../../../core/withRouter'
import { LeadStatusTypes } from '../../../../common/enumerations'
import TransitionActionModal from '../TransitionActionModal'
import StatusColumn from '../StatusColumn'
import './styles.scss'

const sortOrder = {
  priority: ['NONE', 'HIGH', 'MEDIUM', 'LOW'],
}

class LeadsBoard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      leads: [],
    }

    this.onDrop = this.onDrop.bind(this)
    this.onTransitionCompleted = this.onTransitionCompleted.bind(this)
    this.onTransitionCancelled = this.onTransitionCancelled.bind(this)
    this.onHeaderClick = this.onHeaderClick.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { leads } = this.props
    this.props.resetFilters()
    this.addLeadsToState(leads)
  }

  UNSAFE_componentWillReceiveProps({ leads }) {
    this.addLeadsToState(leads)
  }

  addLeadsToState(leads) {
    if (leads) this.setState({ leads })
  }

  onTransitionCompleted(updatedLead) {
    if (updatedLead.status !== 'CLOSED') {
      // The closing form with handle the transition of the lead
      // and we don't want to double up on recorded actions
      this.props.onUpdateLeadStatus(updatedLead)
    }
    this.props.clearTransition()
  }

  onTransitionCancelled() {
    this.props.clearTransition()
  }

  onHeaderClick(statusType) {
    this.props.filterByStatus({
      value: statusType,
      label: LeadStatusTypes[statusType].label,
    })
    this.props.router.navigate('/leads-list?keepFilters')
  }

  onDrop(updatedLead) {
    const { leads } = this.state
    const lead = {
      ...leads.find((lead) => lead.id === updatedLead.id),
      ...updatedLead,
    }
    this.props.transitionLead(lead.status, lead)
    this.setState({ leads: [...leads, lead] })
  }

  render() {
    const { transitionAction } = this.props

    const statusColumns = Object.keys(LeadStatusTypes).map((status, index) => {
      const leads = this.state.leads.filter((lead) => lead.status === status)
      const sortedLeads =
        status !== 'CLOSED'
          ? sortBy(leads, ['priority', 'projectName'], sortOrder)
          : sortBy(leads, ['closedAt']).reverse()
      return (
        <StatusColumn
          key={index}
          status={status}
          rules={LeadStatusTypes[status].rules}
          label={LeadStatusTypes[status].label}
          leads={sortedLeads}
          onDrop={this.onDrop}
          onHeaderClick={this.onHeaderClick}
        />
      )
    })

    return (
      <DndProvider backend={HTML5Backend}>
        <div id="leads-board">
          {statusColumns}
          <TransitionActionModal
            {...transitionAction}
            onTransitionCompleted={this.onTransitionCompleted}
            onTransitionCancelled={this.onTransitionCancelled}
          />
        </div>
      </DndProvider>
    )
  }
}

export default withRouter(LeadsBoard)

import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import Notifications from './components/Notifications'
import { aggregate } from '../../common/activities'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const UserNotificationsQuery = gql`
  query UserNotifications($userId: ID!) {
    userNotifications(userId: $userId) {
      id
      createdAt
      user {
        id
        name
        avatarFilename
      }
      type
      lead {
        id
        projectName
        closingOutcome
        closingReasons
        closedAt
        closingComment
      }
      diff
      isMultiDiff
      activityIds
      targets {
        __typename
        ... on Goal {
          id
          leadId
          deadline
          description
          complete
        }
        ... on Comment {
          id
          content
        }
        ... on LeadAssignment {
          user {
            id
            name
          }
        }
        ... on Lead {
          priority
          status
          projectName
          developerName
          wgnetClientName
          clientType
          salesStatus
          constructionStatus
          zoningDetail
          address
          developerRating
          units
          percentageSold
          salesStartedAt
          source
        }
      }
    }
  }
`

const MarkUserNotificationAsReadMutation = gql`
  mutation MarkUserNotificationAsRead(
    $userId: ID!
    $leadId: ID!
    $activityIds: [ID]!
  ) {
    markUserNotificationAsRead(
      userId: $userId
      leadId: $leadId
      activityIds: $activityIds
    ) {
      activityIds
    }
  }
`

const ComponentWithData = graphql(UserNotificationsQuery, {
  skip: ({ user }) => !user.id,
  options: ({ user }) => ({
    variables: { userId: user.id },
    forceFetch: true,
    pollInterval: 30000,
  }),
  props: ({ data: { userNotifications } }) => {
    return !userNotifications
      ? null
      : { activities: aggregate(userNotifications) }
  },
})(Notifications)

const ComponentWithMutation = graphql(MarkUserNotificationAsReadMutation, {
  props: ({ ownProps: { user }, mutate }) => ({
    markUserNotificationAsRead: (leadId, activityIds) =>
      mutate({
        variables: { userId: user.id, leadId, activityIds },
        refetchQueries: ['UserNotifications'],
      }),
  }),
})(ComponentWithData)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithMutation)

export default connector

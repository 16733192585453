import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import StringField from '../../../core/components/StringField'
import ToggleField from '../../../core/components/ToggleField'
import UserErrors from '../../../../common/errors/user-errors'

class UserForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: {},
      ...props.user,
    }

    this.updateState = this.updateState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onComplete = this.onComplete.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user } = nextProps
    this.setState({ ...user })
  }

  updateState(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
  }

  onSubmit() {
    const { user, onUserCreate, onUserUpdate } = this.props

    const mutate = !user.id ? onUserCreate : onUserUpdate

    mutate(this.state)
      .then((data) => {
        this.setState({ error: {} }, () => {
          this.onComplete(data)
        })
      })
      .catch((error) => {
        const { message } = error.graphQLErrors[0]
        this.setState({ error: UserErrors[message] })
      })
  }

  onComplete(data) {
    const { onComplete, user } = this.props
    onComplete({
      ...user,
      ...this.state,
      ...data,
    })
  }

  onCancel() {
    const { onCancel, user } = this.props
    onCancel({
      ...user,
      ...this.state,
    })
  }

  render() {
    const { user, currentUser } = this.props

    const { name, email, admin, error } = this.state

    return (
      <div id="user-form">
        <div className="row">
          <div className="columns twelve">
            <StringField
              editable
              keepEditorOpen
              label="Name"
              className={error.name ? 'invalid' : ''}
              value={name}
              onChange={(value) => this.updateState('name', value)}
            />
            <span className={`error-message ${error.name ? 'visible' : ''}`}>
              {error.name}
            </span>
            <StringField
              editable
              keepEditorOpen
              type="email"
              label="Email"
              className={error.email ? 'invalid' : ''}
              value={email}
              onChange={(value) => this.updateState('email', value)}
            />
            <span className={`error-message ${error.email ? 'visible' : ''}`}>
              {error.email}
            </span>
            <ToggleField
              editable={currentUser.admin && !user.id}
              label="This user is an administrator"
              value={admin}
              onChange={(value) => this.updateState('admin', value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="columns six">
            <span className="btn primary full-width" onClick={this.onSubmit}>
              Save
            </span>
          </div>
        </div>
      </div>
    )
  }
}

UserForm.propTypes = {
  user: PropTypes.object,
  onUserCreate: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
}

UserForm.defaultProps = {
  user: {
    name: '',
    email: '',
    admin: false,
  },
  onComplete: () => {},
  onCancel: () => {},
}

export default UserForm

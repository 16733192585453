import React, { Component } from 'react'
import './styles.scss'

import {
  LeadStatusTypes,
  LeadPriorityTypes,
  ClientTypes,
  SalesStatusTypes,
  ConstructionStatusTypes,
  SourceTypes,
} from '../../../../common/enumerations'

import { Lead as LeadLabels } from '../../../../common/property-labels'
import EnumField from '../../../core/components/EnumField'
import StringField from '../../../core/components/StringField'
import OwnersField from '../OwnersField'
import ContactsField from '../ContactsField'
import DateField from '../../../core/components/DateField'
import LinkField from '../LinkField'
import LeadDetailsAddLeadAssignment from '../../../LeadDetailsAddLeadAssignment'

class ProjectDetailsPanel extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(property, value) {
    const { lead } = this.props.data
    if (lead[property] !== value) {
      this.props.onUpdateLead({
        ...lead,
        [property]: value,
      })
    }
  }

  priorityRenderer(option) {
    return (
      <span className={`priority ${option.value.toLowerCase()}`}>
        {option.label}
      </span>
    )
  }

  render() {
    const { lead, refetch } = this.props.data
    if (!lead) return null

    const {
      id,
      priority,
      status,
      clientType,
      salesStatus,
      constructionStatus,
      zoningDetail,
      source,
      salesStartedAt,
      updatedAt,
      createdAt,
      assignedUsers,
      contacts,
      sourceUrl,
    } = lead

    const editable = LeadStatusTypes[status].editable

    return (
      <div id="project-details-panel">
        <h5 className="panel-title">Project Details</h5>
        <div className="panel">
          <EnumField
            editable
            enumerations={LeadPriorityTypes}
            label={LeadLabels['priority'].label}
            value={priority}
            className="priority"
            renderer={this.priorityRenderer}
            onChange={(value) => this.onChange('priority', value)}
          />
          <OwnersField leadId={id} users={assignedUsers} />
          <div className="project-owner-selection-container">
            <LeadDetailsAddLeadAssignment lead={lead} />
          </div>
          <EnumField
            editable={editable}
            enumerations={ClientTypes}
            nullable
            label={LeadLabels['clientType'].label}
            value={clientType}
            onChange={(value) => this.onChange('clientType', value)}
          />
          <ContactsField contacts={contacts} onSyncComplete={refetch} />
          <EnumField
            editable={editable}
            enumerations={SalesStatusTypes}
            nullable
            label={LeadLabels['salesStatus'].label}
            value={salesStatus}
            onChange={(value) => this.onChange('salesStatus', value)}
          />
          <EnumField
            editable={editable}
            enumerations={ConstructionStatusTypes}
            nullable
            label={LeadLabels['constructionStatus'].label}
            value={constructionStatus}
            onChange={(value) => this.onChange('constructionStatus', value)}
          />
          <StringField
            editable
            nullable
            label={LeadLabels['zoningDetail'].label}
            value={zoningDetail}
            onChange={(value) => this.onChange('zoningDetail', value)}
          />
          <DateField
            editable={editable}
            classes="field date"
            label={LeadLabels['salesStartedAt'].label}
            value={salesStartedAt}
            onChange={(value) => this.onChange('salesStartedAt', value)}
          />
          <LinkField
            label={LeadLabels['source'].label}
            url={sourceUrl}
            text={SourceTypes[source]}
          />
          <DateField
            classes="field date"
            label={LeadLabels['updatedAt'].label}
            value={updatedAt}
          />
          <DateField
            classes="field date"
            label={LeadLabels['createdAt'].label}
            value={createdAt}
          />
        </div>
      </div>
    )
  }
}

export default ProjectDetailsPanel

import { connect } from 'react-redux'

import UserProfileBasics from './components/UserProfileBasics'
import { setUser } from './actions'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileBasics)

export default connector

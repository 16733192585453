import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import AdminProfileUserList from './components/AdminProfileUserList'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const UserListQuery = gql`
  query UserList {
    users(activeUsersOnly: false) {
      id
      name
      email
      admin
      active
      avatarFilename
    }
  }
`

const ToggleUserActiveMutation = gql`
  mutation ToggleUserActive($id: ID!, $admin: Boolean!, $active: Boolean!) {
    updateUser(id: $id, admin: $admin, active: $active) {
      id
      active
      admin
    }
  }
`

const ComponentWithData = graphql(UserListQuery, {
  props: ({ ownProps: { user }, data: { users } }) => {
    return users ? { users: users.filter(({ id }) => id !== user.id) } : null
  },
  options: (props) => ({
    forceFetch: true,
  }),
})(AdminProfileUserList)

const ComponentWithMutation = graphql(ToggleUserActiveMutation, {
  props: ({ mutate }) => ({
    toggleUserActiveState: (id, { admin, active }) =>
      mutate({
        variables: { id, admin, active },
      }),
  }),
})(ComponentWithData)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithMutation)

export default connector

import React from 'react'
import PropTypes from 'prop-types'

const Value = (props) => {
  const { value, onClick } = props

  const className = [
    'value',
    value
      .toString()
      .replace(/[\W|\d]/g, '')
      .toLowerCase(),
    props.className,
  ].join(' ')

  return (
    <span className={className} title={value} onClick={onClick}>
      {value}
    </span>
  )
}

Value.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  onClick: PropTypes.func,
}

export default Value

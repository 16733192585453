import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  concat,
  InMemoryCache,
} from '@apollo/client'
import { getSessionKey } from './local-storage'

const httpLink = new HttpLink({
  uri: `${import.meta.env.REACT_APP_API_URL}/graphql`,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getSessionKey()

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }))

  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export default client

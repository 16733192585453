import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import LeadsFilter from './components/LeadsFilter'
import { updateFilters } from './actions'

const mapStateToProps = (state) => {
  const { filters } = state
  return { filters }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilters: (filters) => dispatch(updateFilters(filters)),
  }
}

const LeadListQuery = gql`
  query LeadList {
    leads {
      id
      priority
      status
      projectName
      developerName
      units
      createdAt
      updatedAt
      assignedUsers {
        id
        name
        avatarFilename
      }
    }
    users {
      id
      name
      avatarFilename
    }
  }
`

const ComponentWithData = graphql(LeadListQuery, {
  props: ({ data: { leads, users } }) => ({
    leads,
    users,
  }),
  options: { forceFetch: true },
})(LeadsFilter)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector

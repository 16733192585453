import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import GoalList from '../GoalList'

const LeadDetailsGoals = ({ missedGoals, upcomingGoals, goalNeeded }) => {
  return (
    <div id="lead-goals">
      <GoalList
        title="Missed Goals"
        className="missed"
        goals={missedGoals}
        goalNeeded={false}
      />
      <GoalList
        title="Upcoming Goals"
        goals={upcomingGoals}
        goalNeeded={goalNeeded}
      />
    </div>
  )
}

LeadDetailsGoals.propTypes = {
  missedGoals: PropTypes.array,
  upcomingGoals: PropTypes.array,
  goalNeeded: PropTypes.bool,
}

LeadDetailsGoals.defaultProps = {
  missedGoals: [],
  upcomingGoals: [],
  goalNeeded: false,
}

export default LeadDetailsGoals

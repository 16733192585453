import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import UserAvatar from '../../../core/components/UserAvatar'

import {
  LeadAddedContent,
  LeadUpdatedContent,
  LeadClosedContent,
  OwnersEditedContent,
} from '../../../core/components/ActivityCard/content'

import {
  LeadAddedMeta,
  LeadUpdatedMeta,
  LeadClosedMeta,
  GoalAddedMeta,
  GoalUpdatedMeta,
  GoalCompletedMeta,
  OwnerAddedMeta,
  OwnersAddedMeta,
  OwnerRemovedMeta,
  OwnersRemovedMeta,
  CommentAddedMeta,
  PairingCandidateMeta,
  PairingAcceptedMeta,
  PairingRejectedMeta,
} from './meta'

const NotificationCard = (props) => {
  const { activity, onNavigateToLead } = props
  const { user, type, targets, changes, lead, isMultiDiff, createdAt } =
    activity

  // let Icon
  let Meta, content
  switch (type) {
    case 'LEAD_ADDED':
      // Icon = MdSearch
      Meta = LeadAddedMeta
      content = <LeadAddedContent source={targets[0].source} />
      break
    case 'LEAD_UPDATED':
    case 'LEAD_STATUS_CHANGED':
      // Icon = MdList
      Meta = LeadUpdatedMeta
      content = <LeadUpdatedContent fieldsChanged={changes} />
      break
    case 'LEAD_CLOSED':
      // Icon = MdClose
      Meta = LeadClosedMeta
      content = (
        <LeadClosedContent
          closingOutcome={lead.closingOutcome}
          closingReasons={lead.closingReasons}
          closingComment={lead.closingComment}
          closedAt={lead.closedAt}
        />
      )
      break
    case 'GOAL_ADDED':
      // Icon = MdInsertInvitation
      Meta = GoalAddedMeta
      content = targets[0].description
      break
    case 'GOAL_UPDATED':
      // Icon = MdInsertInvitation
      Meta = GoalUpdatedMeta
      content = targets[0].description
      break
    case 'GOAL_COMPLETED':
      Meta = GoalCompletedMeta
      // Icon = MdCheck
      content = targets[0].description
      break
    case 'OWNER_ADDED':
      Meta = isMultiDiff ? OwnersAddedMeta : OwnerAddedMeta
      // Icon = MdPerson
      content = <OwnersEditedContent owners={targets} />
      break
    case 'OWNER_REMOVED':
      Meta = isMultiDiff ? OwnersRemovedMeta : OwnerRemovedMeta
      // Icon = MdPersonOutline
      content = <OwnersEditedContent owners={targets} />
      break
    case 'COMMENT_ADDED':
      // Icon = MdTextsms
      Meta = CommentAddedMeta
      content = targets[0].content
      break
    case 'PAIRING_CANDIDATE':
      Meta = PairingCandidateMeta
      content = targets[0].content
      break
    case 'PAIRING_ACCEPTED':
      Meta = PairingAcceptedMeta
      content = targets[0].content
      break
    case 'PAIRING_REJECTED':
      Meta = PairingRejectedMeta
      content = targets[0].content
      break
    default:
      return null
  }

  return (
    <div className="notification-item">
      <UserAvatar user={user} size="medium" />
      <div className="info">
        <Meta {...activity} onNavigateToLead={onNavigateToLead} />
        <div className="content">{content}</div>
        <div className="date">{createdAt}</div>
      </div>
    </div>
  )
}

NotificationCard.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.oneOf([
      'LEAD_ADDED',
      'LEAD_UPDATED',
      'LEAD_STATUS_CHANGED',
      'LEAD_CLOSED',
      'GOAL_ADDED',
      'GOAL_UPDATED',
      'GOAL_COMPLETED',
      'COMMENT_ADDED',
      'OWNER_ADDED',
      'OWNER_REMOVED',
      'PAIRING_CANDIDATE',
      'PAIRING_ACCEPTED',
      'PAIRING_REJECTED',
    ]).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    createdAt: PropTypes.string.isRequired,
    targets: PropTypes.array.isRequired,
  }),
}

export default NotificationCard

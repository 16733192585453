import { connect } from 'react-redux'

import UserProfileAvatar from './components/UserProfileAvatar'
import { uploadAvatar } from './actions'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadAvatar: (file) => dispatch(uploadAvatar(file)),
  }
}
const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileAvatar)

export default connector

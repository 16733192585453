import {
  GOAL_FORM_UPDATED,
  FORM_CLEARED,
  GOAL_FORM_ERRORED,
} from '../core/action-types'

export const updateGoalForm = (formData) => ({
  type: GOAL_FORM_UPDATED,
  payload: formData,
})

export const clearGoalForm = () => ({
  type: FORM_CLEARED,
})

export const validateGoalForm = ({ description, deadline }) => {
  const errors = {}
  if (!description || description.length === 0) errors.description = 1
  if (description && description.length > 255) errors.descriptionLength = 1
  if (!deadline) errors.deadline = 1

  return {
    type: GOAL_FORM_ERRORED,
    payload: errors,
  }
}

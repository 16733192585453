import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GOAL_FORM, COMMENT_FORM } from '../../../core/lead-details-form-types'
import LeadGoalForm from '../../../LeadGoalForm'
import LeadCommentForm from '../../../LeadCommentForm'
import FormSelector from '../FormSelector'
import './styles.scss'

class LeadDetailsInteractions extends Component {
  componentWillUnmount() {
    this.props.unloadForms()
  }

  render() {
    const { canAddGoal } = this.props
    let Form = LeadGoalForm

    switch (this.props.leadDetailsForm) {
      case GOAL_FORM:
        Form = LeadGoalForm
        break
      case COMMENT_FORM:
        Form = LeadCommentForm
        break
    }

    return (
      <div id="form-interactions-container">
        <h5 className="panel-title">New</h5>
        <div className="panel">
          <div className="row">
            <FormSelector
              onClick={this.props.changeForm}
              canAddGoal={canAddGoal}
            />
          </div>
          <Form id={this.props.id} />
        </div>
      </div>
    )
  }
}

LeadDetailsInteractions.propTypes = {
  id: PropTypes.number.isRequired,
  canAddGoal: PropTypes.bool,
}

LeadDetailsInteractions.defaultProps = {
  canAddGoal: false,
}

export default LeadDetailsInteractions

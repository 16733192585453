const forbidden = () => false

const isAssigned = (lead) => !!lead.isAssigned

export default {
  NEW: {
    label: 'New',
    rules: [forbidden],
    editable: false,
  },
  OWNER_ASSIGNED: {
    label: 'Owner Assigned',
    rules: [isAssigned],
    editable: false,
  },
  APPLICATION_SENT: {
    label: 'Application Sent',
    rules: [isAssigned],
    editable: true,
  },
  UNDERWRITING: {
    label: 'Underwriting',
    rules: [isAssigned],
    editable: true,
  },
  TERMS_SENT: {
    label: 'Terms Sent',
    rules: [isAssigned],
    editable: true,
  },
  CLOSED: {
    label: 'Closed',
    rules: [],
    editable: true,
  },
}

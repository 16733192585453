import React from 'react'
import './styles.scss'

import DashboardLeads from '../../../DashboardLeads'

const Dashboard = () => {
  return (
    <div id="dashboard">
      <div className="container">
        <div className="row">
          <div className="columns twelve">
            <DashboardLeads />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard

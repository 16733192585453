import { FaExchangeAlt } from 'react-icons/fa'
import Button from '@/modules/core/components/Button'
import { PairingsListQuery } from '@/modules/Pairings/queries'
import { useQuery } from '@apollo/client'
import { GQLPairing } from '@/modules/Pairings/types'

interface PairingsIndicatorButtonProps {
  onClick: () => void
}

const PairingsIndicatorButton = ({ onClick }: PairingsIndicatorButtonProps) => {
  const { data, error } = useQuery<{ pairings: GQLPairing[] }>(
    PairingsListQuery,
    {
      variables: { triageStatus: 'PENDING' },
      pollInterval: 30000,
    },
  )

  if (error) {
    console.error('error while fetching pairings list for triage', error)
  }

  const pendingCount =
    data?.pairings?.filter((pairing) => pairing.triageStatus === 'PENDING')
      .length || 0

  return (
    <Button
      label="Review WGNet Pairings"
      onClick={onClick}
      icon={<FaExchangeAlt />}
      badgeCount={pendingCount}
    />
  )
}

export default PairingsIndicatorButton

import React from 'react'
import PropTypes from 'prop-types'

const Label = (props) => {
  const { text } = props

  if (!text) return null

  return <span className="label">{text}</span>
}

Label.propTypes = {
  text: PropTypes.string,
}

export default Label

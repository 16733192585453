import React, { Component } from 'react'

import withRouter from '../../../core/withRouter'
import Label from '../../../core/components/Label'
import ToggleField from '../../../core/components/ToggleField'

class LoginForm extends Component {
  constructor() {
    super()

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      authenticationFailed: false,
    }

    this.updateState = this.updateState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.state.email && this.state.password) {
      this.props
        .onSubmit(this.state)
        .catch(() => {
          this.setState({ authenticationFailed: true })
        })
        .finally(() => {
          this.props.router.navigate('/')
        })
    }
  }

  render() {
    const { email, password, rememberMe, authenticationFailed } = this.state
    return (
      <div id="sign-up-form" className="login-form panel">
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div
            className="row"
            style={{ display: authenticationFailed ? 'initial' : 'none' }}>
            <div className="columns twelve">
              <span className="error-message">Invalid email or password</span>
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <div className="field string">
                <Label text="Email" />
                <input
                  id="username"
                  className="input"
                  type="email"
                  placeholder="example@westmountguarantee.com"
                  value={email}
                  onChange={({ target: { value } }) =>
                    this.updateState('email', value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <div className="field string">
                <Label text="Password" />
                <input
                  id="password"
                  className="input"
                  type="password"
                  value={password}
                  onChange={({ target: { value } }) =>
                    this.updateState('password', value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <button
                className="main-login-btn btn primary full-width"
                type="submit">
                Log In
              </button>
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <ToggleField
                editable
                label="Remember me"
                value={rememberMe}
                onChange={(value) => this.updateState('rememberMe', value)}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default withRouter(LoginForm)

import axios from 'axios'
import { getSessionKey } from '../../common/local-storage'
import { ADD_MESSAGE, SET_USER_AVATAR } from '../../modules/core/action-types'
import { OK } from '../../common/http-codes'

export const uploadAvatar = (file) => {
  return (dispatch) => {
    const data = new FormData()
    data.append('file', file)
    const token = getSessionKey()
    const auth = token ? `Bearer ${token}` : null
    return axios({
      method: 'post',
      url: `${import.meta.env.REACT_APP_API_URL}/avatar/upload`,
      data,
      headers: {
        Authorization: auth,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        if (res.status === OK) {
          dispatch({ type: SET_USER_AVATAR, payload: res.data.avatarFilename })
          dispatch({
            type: ADD_MESSAGE,
            payload: {
              type: 'info',
              content: 'User profile photo successfully updated',
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status === 415) {
          dispatch({
            type: ADD_MESSAGE,
            payload: { type: 'error', content: 'Invalid file type' },
          })
        } else {
          dispatch({
            type: ADD_MESSAGE,
            payload: {
              type: 'error',
              content: 'Failed to set the user profile photo',
            },
          })
        }
      })
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../core/components/Modal'

class TransitionActionModal extends Component {
  shouldComponentUpdate(nextProps) {
    const { lead, actionRequired, onTransitionCompleted } = nextProps

    if (lead !== this.props.lead) {
      if (!actionRequired && lead) {
        onTransitionCompleted(lead)
      }
      return true
    }

    return false
  }

  render() {
    const {
      actionRequired,
      onTransitionCompleted,
      onTransitionCancelled,
      lead,
    } = this.props

    if (!actionRequired) {
      return null
    }

    const ActionComponent = this.props.actionComponent

    return (
      <Modal
        id="lead-transition-modal"
        isOpen={actionRequired}
        label="Lead Transition"
        onClose={onTransitionCancelled}>
        <ActionComponent
          lead={lead}
          onComplete={onTransitionCompleted}
          onCancel={onTransitionCancelled}
        />
      </Modal>
    )
  }
}

TransitionActionModal.propTypes = {
  actionRequired: PropTypes.bool.isRequired,
  onTransitionCompleted: PropTypes.func,
  onTransitionCancelled: PropTypes.func,
  lead: PropTypes.object,
}

export default TransitionActionModal

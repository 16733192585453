import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import Authenticated from './components/Authenticated'
import { setUser } from './actions'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  }
}

const AuthenticatedUserQuery = gql`
  query Me {
    me {
      id
      name
      email
      admin
      weeklyNotification
      deadlineNotification
      commentNotification
      passwordChangeRequired
      avatarFilename
    }
  }
`

const ComponentWithData = graphql(AuthenticatedUserQuery, {
  options: { pollInterval: 30000 },
  props: ({ data: { error, me } }) => {
    return {
      auth: {
        error,
        me,
      },
    }
  },
})(Authenticated)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector

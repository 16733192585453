import { connect } from 'react-redux'
import { uploadBuzzBuzzHomeCsv } from './actions'
import LeadsUploadButton from './components/LeadsUploadButton'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadBuzzBuzzHomeCsv: (file) => dispatch(uploadBuzzBuzzHomeCsv(file)),
  }
}
const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadsUploadButton)

export default connector

import React, { Component } from 'react'
import { MdFileDownload } from 'react-icons/md'
import { filteredProjectTrackerLeads } from '../../../../common/filtered-leads'

class ProjectTrackerExportButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      leads: [],
    }
    this.onClick = this.onClick.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.leads) {
      this.setState({
        leads: nextProps.data.leads,
      })
    }
  }

  render() {
    return (
      <div id="project-tracker-export-btn">
        <span onClick={this.onClick} className="btn link">
          <MdFileDownload />
          Download as CSV
        </span>
      </div>
    )
  }

  onClick() {
    const leads = filteredProjectTrackerLeads(
      this.state.leads,
      this.props.projectTrackerFilters,
    )
    this.props.downloadCsv(leads)
  }
}
export default ProjectTrackerExportButton

import { useState } from 'react'
import PairingsList from './PairingsList'
import './styles.scss'

const noticeDuration = 4000
const initialMessage = { text: '', isError: false }

type PairingsProps = {
  refetch: boolean
}

const Pairings = ({ refetch }: PairingsProps) => {
  const [message, setMessage] = useState(initialMessage)
  const onError = (errorMessage: string) => {
    setMessage({ text: errorMessage, isError: true })
    setTimeout(() => {
      setMessage(initialMessage)
    }, 4000)
  }

  const onNotify = (notice: string) => {
    setMessage({ text: notice, isError: false })
    setTimeout(() => {
      setMessage(initialMessage)
    }, noticeDuration)
  }

  const messageStyle = `notices ${
    message.text ? (message.isError ? 'error' : 'notice') : ''
  }`

  return (
    <div id="pairings-triage">
      <p className="title">Pairings Triage</p>
      <p className="subtitle">
        Here are some candidates for linking WG Leads to corresponding WGN
        Projects. To accept the proposed match, change the status to 'ACCEPTED'.
      </p>
      <div className={messageStyle}>{message.text}</div>
      <PairingsList onError={onError} onNotify={onNotify} refetch={refetch} />
    </div>
  )
}

export default Pairings

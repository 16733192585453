import { Link } from 'react-router-dom'
import { MdLocationOn } from 'react-icons/md'
import { GQLLead } from '../types'

export type LeadBoxProps = {
  value: GQLLead
}

export default ({ value: lead }: LeadBoxProps) => {
  return (
    <div className="triage-item-cell">
      <div className="celltitle">
        <Link to={`/leads/${lead.id}`} target="_blank">
          {lead.projectName}
        </Link>
      </div>
      <div className="detail units">
        <b>{lead.units} units</b>
      </div>
      <div className="detail">
        <span className="label">Developer</span>
        <span className="value">{lead.developerName}</span>
      </div>
      <div className="detail">
        <span className="label">WG Net Client</span>
        <span className="value">{lead.wgnetClientName || '(unknown)'}</span>
      </div>
      <div className="locator">
        <MdLocationOn size={20} />{' '}
        {`${lead.address.split(',').slice(0, 3).join(', ')}`}
      </div>
    </div>
  )
}

export default {
  MISSING_NAME: {
    name: 'Missing Name',
  },
  MISSING_EMAIL: {
    email: 'Missing Email',
  },
  INVALID_EMAIL: {
    email: 'Invalid Email',
  },
  EMAIL_TAKEN: {
    email: 'Email is Taken',
  },
  MISSING_PASSWORD: {
    password: 'Missing Password',
  },
  INCORRECT_PASSWORD: {
    password: 'Incorrect Password',
  },
  MISSING_NEW_PASSWORD: {
    newPassword: 'Missing New Password',
  },
  NEW_PASSWORD_LENGTH: {
    newPassword: 'New Password is too Short',
  },
}

import React from 'react'
import { Link } from 'react-router-dom'

const Meta = (props, info) => {
  const { user, lead, onNavigateToLead } = props
  const { action, preposition } = info

  return (
    <div className="meta">
      <div className="details">
        {user.name} {action} {preposition}{' '}
        <span className="lead-name">
          <Link to={`/leads/${lead.id}`} onClick={onNavigateToLead}>
            {lead.projectName}
          </Link>
        </span>
      </div>
    </div>
  )
}

export const LeadAddedMeta = (props) =>
  Meta(props, { action: '', preposition: '' })
export const LeadUpdatedMeta = (props) =>
  Meta(props, { action: 'updated the lead', preposition: 'for' })
export const LeadClosedMeta = (props) =>
  Meta(props, { action: 'closed the lead', preposition: 'for' })
export const GoalAddedMeta = (props) =>
  Meta(props, { action: 'added a goal', preposition: 'for' })
export const GoalUpdatedMeta = (props) =>
  Meta(props, { action: 'updated a goal', preposition: 'for' })
export const GoalCompletedMeta = (props) =>
  Meta(props, { action: 'marked a goal as complete', preposition: 'for' })
export const OwnerAddedMeta = (props) =>
  Meta(props, { action: 'added an owner', preposition: 'to' })
export const OwnersAddedMeta = (props) =>
  Meta(props, { action: 'added owners', preposition: 'to' })
export const OwnerRemovedMeta = (props) =>
  Meta(props, { action: 'removed an owner', preposition: 'from' })
export const OwnersRemovedMeta = (props) =>
  Meta(props, { action: 'removed owners', preposition: 'from' })
export const CommentAddedMeta = (props) =>
  Meta(props, { action: 'added a comment', preposition: 'to' })
export const PairingCandidateMeta = (props) =>
  Meta(props, { action: 'proposed a wgnet project link', preposition: 'for' })
export const PairingAcceptedMeta = (props) =>
  Meta(props, { action: 'accepted a wgnet project link', preposition: 'for' })
export const PairingRejectedMeta = (props) =>
  Meta(props, { action: 'rejected a wgnet project link', preposition: 'for' })

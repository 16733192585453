import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import withRouter from '../../core/withRouter'
import { destroySessionKey } from '../../../common/local-storage'
import Banner from './Banner'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import Messages from '../../Messages'
import Notifications from '../../Notifications'
import CurrentUserControls from '../../CurrentUserControls'

class Authenticated extends Component {
  constructor(props) {
    super(props)
    this.checkPasswordChange = this.checkPasswordChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.checkPasswordChange(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkPasswordChange(nextProps)

    const { auth } = nextProps

    if (auth.error !== undefined) {
      destroySessionKey()
      this.props.router.navigate('/')
    } else if (auth.me && !this.props.user.id) {
      this.props.setUser(auth.me)
    }
  }

  checkPasswordChange({ user }) {
    if (
      user.passwordChangeRequired &&
      this.props.router.location.pathname !== '/password-change'
    ) {
      this.props.router.navigate('/password-change')
    }
  }

  render() {
    return (
      <div className="westmount">
        <Banner>
          <DesktopNavigation
            location={this.props.router.location}
            routes={this.props.routes}
          />
          <div style={{ flexGrow: 1 }}></div>
          <Notifications />
          <CurrentUserControls />
          <MobileNavigation
            location={this.props.router.location}
            routes={this.props.routes}
          />
        </Banner>
        <Messages />
        <Outlet />
      </div>
    )
  }
}

Authenticated.defaultProps = {
  auth: {},
}

export default withRouter(Authenticated)

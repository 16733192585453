import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import Label from '../Label'
import Value from '../Value'

const isValidNumber = (value) => {
  if (value === null) return false
  if (isNaN(value)) return false
  return true
}

class NumberField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      value: null,
    }

    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props
    this.setState({ value })
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.state.value) {
      this.setState({ value })
    }
  }

  onClick() {
    if (!this.props.editable) {
      return
    }

    this.setState({ editing: true })
  }

  onChange(event) {
    const { value } = event.target
    const { min, max } = this.props
    let numericValue = parseInt(value, 10)

    if (numericValue < min) {
      numericValue = min
    }

    if (numericValue > max) {
      numericValue = max
    }

    this.setState({ value: isValidNumber(numericValue) ? numericValue : null })
  }

  onBlur() {
    this.setState({ editing: false }, () => {
      const newValue = parseInt(this.state.value, 10)
      const prevValue = parseInt(this.props.value, 10)

      if (newValue !== prevValue) {
        this.props.onChange(isValidNumber(newValue) ? newValue : null)
      }
    })
  }

  onKeyPress({ key }) {
    if (key === 'Enter') {
      this.onBlur()
    }
  }

  render() {
    const { label, editable, keepEditorOpen, className, min, max } = this.props

    const { editing, value } = this.state

    const classNames = [
      editable ? 'editable' : null,
      !isValidNumber(value) ? 'invalid' : null,
      className,
    ].join(' ')

    const editProps = {
      type: 'number',
      className: `input ${className}`,
      min,
      max,
      autoFocus: !keepEditorOpen,
      value: isValidNumber(value) ? value : '',
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      onBlur: this.onBlur,
    }

    const valueProps = {
      className: classNames,
      value: isValidNumber(value) ? value : 'Unknown',
      onClick: this.onClick,
    }

    const showEditor = editing || keepEditorOpen

    return (
      <div className="field number">
        <Label text={label} />
        {(showEditor && <input {...editProps} />) || <Value {...valueProps} />}
      </div>
    )
  }
}

NumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  editable: PropTypes.bool,
}

export default NumberField

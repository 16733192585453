import React from 'react'
import './styles.scss'

import PasswordRecovery from '../../../PasswordRecovery'

const Login = (props) => {
  return (
    <div className="login row">
      <div className="three columns">&nbsp;</div>
      <div className="six columns">
        <div>
          <img className="logo" src="/images/login-logo.png" />
        </div>
        <h1 className="title">Password Reset Request</h1>
        <PasswordRecovery />
      </div>
      <div className="three columns">&nbsp;</div>
    </div>
  )
}

export default Login

import React from 'react'
import { Link } from 'react-router-dom'
import { useDrag } from 'react-dnd'
import Tooltip from 'rc-tooltip'
import './styles.scss'

import Flag from '../Flag'
import UserAvatar from '../../../core/components/UserAvatar'

export const LeadType = 'LEAD'

const generateOwnerBoxStyles = (count) => {
  if (count === 0) {
    return {}
  }

  return {
    height: '18px',
    width: count > 1 ? `${(count + 1) * 9}px` : '18px',
  }
}

const OwnerListToolTip = (props) => {
  if (props.users.length === 0) {
    return null
  }

  return (
    <div className="owners">
      {props.users.map((user) => (
        <span key={user.id}>{user.name}</span>
      ))}
    </div>
  )
}

const Lead = (props) => {
  const {
    id,
    priority,
    status,
    projectName,
    developerName,
    units,
    assignedUsers,
    closingOutcome,
    goalNeeded,
    missedGoals,
    activities,
  } = props

  const [{ isDragging }, drag] = useDrag(() => ({
    type: LeadType,
    item: {
      id,
      isAssigned: assignedUsers.length > 0,
      status,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  let componentClass = `lead ${priority.toLowerCase()} ${status.toLowerCase()}`

  if (isDragging) {
    componentClass += ' dragging'
  }

  const tooltipProps = {
    placement: 'top',
    overlayClassName: 'ownerTooltip',
    align: { offset: [0, 5] },
    overlay: <OwnerListToolTip users={assignedUsers} />,
    destroyTooltipOnHide: true,
  }

  const ownersBoxStyles = generateOwnerBoxStyles(assignedUsers.length)

  return (
    <div ref={drag} className={componentClass} title={projectName}>
      <Flag
        status={status}
        closingOutcome={closingOutcome}
        activities={activities}
        goalNeeded={goalNeeded}
        missedGoals={missedGoals}
      />
      <Link className="projectName" to={`/leads/${id}`}>
        {projectName}
      </Link>
      <p className="developerName">{developerName}</p>
      <Tooltip {...tooltipProps}>
        <span className="owners" style={ownersBoxStyles}>
          {assignedUsers.map((user) => (
            <UserAvatar key={user.id} user={user} />
          ))}
        </span>
      </Tooltip>
      {units !== undefined && <span className="units">{units} Units</span>}
    </div>
  )
}

export default Lead

import { connect } from 'react-redux'

import CurrentUserControls from './components/CurrentUserControls'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const connector = connect(mapStateToProps)(CurrentUserControls)

export default connector

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import UserAvatar from '../../../core/components/UserAvatar'

class UserProfileAvatar extends Component {
  constructor(props) {
    super(props)

    this.uploadAvatar = this.uploadAvatar.bind(this)
  }

  uploadAvatar({ target: { files } }) {
    this.props.uploadAvatar(files[0]).then(() => {
      this.refs.file.value = null
    })
  }

  render() {
    const { user } = this.props
    return (
      <div id="user-profile-avatar">
        <UserAvatar user={user} size="large" />
        <div className="upload-btn">
          <input
            id="file"
            type="file"
            ref="file"
            onChange={this.uploadAvatar}
            className="inputfile"
            accept=".jpg, .png, .jpeg, .bmp|images/*"
          />
          <label htmlFor="file" className="btn primary">
            Upload Photo
          </label>
        </div>
      </div>
    )
  }
}

UserProfileAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  uploadAvatar: PropTypes.func.isRequired,
}

export default UserProfileAvatar

import { Cell, Column, Worksheet } from 'exceljs'
import { CellCursor, ProjectDataObj, ProjectSummaryObj } from './types'
import { projectColumnHeaders, projectSummaryHeaders } from './columnHeaders'
import { toLabel } from './labels'
import { makeCellCursor } from './utils'

export const projectsMainTable = (
  prjSheet: Worksheet,
  mainTableRows: ProjectDataObj[],
) => {
  prjSheet.columns = projectColumnHeaders
  const header = prjSheet.getRow(1)
  header.eachCell((cell) => {
    cell.font = { bold: true }
  })

  mainTableRows.forEach((project: ProjectDataObj) => {
    prjSheet.addRow(project)
  })
}

export const projectsSummaryTable = (
  cursor: CellCursor,
  summaryRows: ProjectSummaryObj[],
) => {
  // Since this isn't a perfect table, the headers can't be used directly as column headers. Render each manually (and always replace first one with the segment name)
  const segmentName = toLabel(summaryRows[0].clientType)
  const columnDefinitions = structuredClone(projectSummaryHeaders)
  const caption = columnDefinitions.shift()
  if (caption) {
    caption.header = segmentName
    columnDefinitions.unshift(caption)
  }

  columnDefinitions.forEach((def, index) => {
    cursor.pin()

    cursor.bold().styleAs(def, 'heading').down(1)

    let columnTotal = 0
    summaryRows.forEach((row: ProjectSummaryObj) => {
      const contents = def.key ? row[def.key] : undefined
      const numericContents = Number(contents)
      !isNaN(numericContents) && (columnTotal += numericContents)
      cursor.bold(false).styleAs(def, 'content').value(contents).down(1)
    })

    if (index === 0) {
      cursor.styleAs(def, 'total').value('Total').down(1)
    } else {
      cursor.styleAs(def, 'total').value(columnTotal).down(1)
    }

    cursor.returnToLastPin().across(1)
  })
}

export const disclaimersTable = (startCell: Cell, messages: string[]) => {
  const cursor = makeCellCursor(startCell)
  cursor.bold().value('Disclaimers & Notes')
  messages.forEach((message) => {
    cursor.down(1).value(message)
  })
}

export const reportConfigTable = (
  startCell: Cell,
  tableData: Record<string, number>,
) => {
  const cursor = makeCellCursor(startCell)
  cursor.width(40)

  cursor.bold().value('Report Configuration').down(1).bold(false)
  Object.keys(tableData).forEach((key) => {
    cursor.value(key).across(1).value(tableData[key])
    cursor.down(1).across(-1)
  })
}

export const retrofitFormulas = (
  col: Column,
  formula: (cell: Cell, rownum: number) => string,
  skips: string[] = [],
) => {
  col.eachCell((cell, rownum) => {
    if (rownum > 1 && !skips.includes(cell.value as string)) {
      cell.value = {
        formula: formula(cell, rownum),
        result: cell.value as number,
      }
    }
  })
}

import React from 'react'
import PropTypes from 'prop-types'
import { MdInsertInvitation } from 'react-icons/md'
import { Link } from 'react-router-dom'
import './styles.scss'

const GoalNeededCard = ({ showLeadInfo, lead }) => {
  const text = showLeadInfo ? (
    <p>
      <strong>You</strong> need to create a <span>Project Goal</span> for{' '}
      <Link to={`/leads/${lead.id}`}>{lead.projectName}</Link>
    </p>
  ) : (
    <p>
      <strong>You</strong> need to create a <span>Project Goal</span>
    </p>
  )

  return (
    <div className="activity-card goal-needed">
      <div className="type-icon">
        <MdInsertInvitation size={20} />
      </div>
      <div className="panel">{text}</div>
    </div>
  )
}

GoalNeededCard.propTypes = {
  showLeadInfo: PropTypes.bool.isRequired,
  lead: PropTypes.shape({
    id: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
  }),
}

GoalNeededCard.defaultProps = {
  showLeadInfo: false,
}

export default GoalNeededCard

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaCheck, FaTimes } from 'react-icons/fa'
import Moment from 'moment'
import './styles.scss'

import TextareaField from '../../../core/components/TextareaField'
import DateField from '../../../core/components/DateField'

class GoalEditForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.id,
      description: props.description,
      deadline: props.deadline,
      leadId: props.leadId,
    }

    this.onUpdateState = this.onUpdateState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onUpdateState(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
  }

  onSubmit() {
    const { onSubmit, onComplete } = this.props
    onSubmit(this.state).then(onComplete(this.state))
  }

  render() {
    const { onCancel } = this.props

    const { description, deadline } = this.state

    return (
      <div className="goal-edit-form">
        <div className="row">
          <TextareaField
            placeholderText="Enter a goal for this project"
            value={description}
            onChange={(value) => this.onUpdateState('description', value)}
            editable
            keepEditorOpen
          />
        </div>
        <div className="row">
          <div className="three columns">
            <DateField
              label="Due Date"
              value={deadline}
              onChange={(value) => this.onUpdateState('deadline', value)}
              editable
              keepEditorOpen
            />
          </div>
        </div>
        <div className="row">
          <div className="action-buttons">
            <span className="btn primary" onClick={this.onSubmit}>
              <FaCheck /> Update
            </span>
            <span className="btn cancel" onClick={onCancel}>
              <FaTimes /> Cancel
            </span>
          </div>
        </div>
      </div>
    )
  }
}

GoalEditForm.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  deadline: PropTypes.instanceOf(Moment).isRequired,
  leadId: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
}

GoalEditForm.defaultProps = {
  onComplete: () => {},
  onCancel: () => {},
}

export default GoalEditForm

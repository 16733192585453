import React, { Component } from 'react'
import withRouter from '../../../core/withRouter'
import './styles.scss'

import StringField from '../../../core/components/StringField'
import UserErrors from '../../../../common/errors/user-errors'

class UserProfilePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordCheck: '',
      error: {},
      success: '',
    }

    this.updateState = this.updateState.bind(this)
    this.onChangeClick = this.onChangeClick.bind(this)
  }

  updateState(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
  }

  onChangeClick(e) {
    e.preventDefault(e)
    this.props
      .onPasswordUpdate({
        id: this.props.user.id,
        ...this.state,
      })
      .then(() => {
        this.setState({
          error: {},
          currentPassword: '',
          newPassword: '',
          newPasswordCheck: '',
          success: 'Password Successfully Updated!',
        })

        if (this.props.redirectOnComplete) {
          this.props.router.navigate(this.props.redirectOnComplete)
        }
      })
      .catch((error) => {
        const { message } = error.graphQLErrors[0]
        this.setState({ error: UserErrors[message] })
      })
  }

  render() {
    const { currentPassword, newPassword, newPasswordCheck, error, success } =
      this.state

    const passwordMismatch =
      newPasswordCheck !== '' && newPassword !== newPasswordCheck

    return (
      <div id="user-profile-password">
        <form onSubmit={(e) => this.onChangeClick(e)}>
          <div className="row">
            <div className="columns twelve">
              <h5>Change Password</h5>
              <span className={`message info ${success ? 'visible' : ''}`}>
                {success}
              </span>
              <StringField
                editable
                nullable
                keepEditorOpen
                type="password"
                label="Current Password"
                className={error.password ? 'invalid' : ''}
                value={currentPassword}
                onChange={(value) => this.updateState('currentPassword', value)}
              />
              <span
                className={`message error ${error.password ? 'visible' : ''}`}>
                {error.password}
              </span>
              <StringField
                editable
                nullable
                keepEditorOpen
                type="password"
                label="New Password"
                className={error.newPassword ? 'invalid' : ''}
                value={newPassword}
                onChange={(value) => this.updateState('newPassword', value)}
              />
              <StringField
                editable
                nullable
                keepEditorOpen
                type="password"
                label="Confirm New Password"
                className={
                  error.newPassword || passwordMismatch ? 'invalid' : ''
                }
                value={newPasswordCheck}
                onChange={(value) =>
                  this.updateState('newPasswordCheck', value)
                }
              />
              <span
                className={`message error ${
                  passwordMismatch ? 'visible' : ''
                }`}>
                Password Missmatch
              </span>
              <span
                className={`message error ${
                  error.newPassword ? 'visible' : ''
                }`}>
                {error.newPassword}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="columns six">
              <button className="btn primary full-width" type="submit">
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default withRouter(UserProfilePassword)

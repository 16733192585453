import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import ProjectDetailsPanel from './components/ProjectDetailsPanel'

const LeadQuery = gql`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      priority
      status
      clientType
      salesStatus
      constructionStatus
      zoningDetail
      developerRating
      salesStartedAt
      source
      createdAt
      updatedAt
      sourceUrl
      assignedUsers {
        id
        name
        avatarFilename
      }
      contacts {
        id
        name
        company
        email
        phone
      }
    }
  }
`

const UpdateLeadMutation = gql`
  mutation UpdateLead(
    $id: ID!
    $priority: LeadPriorityType!
    $clientType: ClientType
    $salesStatus: SalesStatusType
    $constructionStatus: ConstructionStatusType
    $zoningDetail: String
    $developerRating: String
    $salesStartedAt: DateTime
  ) {
    updateLead(
      id: $id
      priority: $priority
      clientType: $clientType
      salesStatus: $salesStatus
      constructionStatus: $constructionStatus
      zoningDetail: $zoningDetail
      developerRating: $developerRating
      salesStartedAt: $salesStartedAt
    ) {
      id
      priority
      clientType
      salesStatus
      constructionStatus
      zoningDetail
      developerRating
      salesStartedAt
      source
      createdAt
      updatedAt
    }
  }
`

const ComponentWithData = graphql(LeadQuery, {
  options: (props) => ({
    forceFetch: true,
    variables: { id: props.id },
  }),
})(ProjectDetailsPanel)

const ComponentWithMutation = graphql(UpdateLeadMutation, {
  props: ({ mutate }) => ({
    onUpdateLead: ({
      id,
      priority,
      clientType,
      salesStatus,
      constructionStatus,
      zoningDetail,
      developerRating,
      salesStartedAt,
    }) =>
      mutate({
        variables: {
          id,
          priority,
          clientType,
          salesStatus,
          constructionStatus,
          zoningDetail,
          developerRating,
          salesStartedAt,
        },
        refetchQueries: ['Lead', 'LeadActivities'],
      }),
  }),
})(ComponentWithData)

export default ComponentWithMutation

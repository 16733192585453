import Moment from 'moment'

export const filteredLeads = (leads, filters) => {
  const {
    projectName,
    clientType,
    activityType,
    assignedUser,
    developerName,
    percentageSold,
    minUnits,
    maxUnits,
    startDate,
    endDate,
    statusType,
    priorityType,
  } = filters

  return leads.filter((lead) => {
    if (projectName && lead.projectName !== projectName.value) return false
    if (clientType && lead.clientType !== clientType.value) return false
    if (activityType) {
      if (
        activityType.value === 'NEW_ACTIVITY' &&
        (!lead.hasActivities || lead.hasMissedGoals || lead.goalNeeded)
      )
        return false
      if (activityType.value === 'MISSED_GOAL' && !lead.hasMissedGoals)
        return false
      if (activityType.value === 'GOAL_NEEDED' && !lead.goalNeeded) return false
    }
    if (assignedUser) {
      if (
        (!assignedUser.value && lead.assignedUsers.length !== 0) ||
        (!!assignedUser.value &&
          !lead.assignedUsers.find((user) => user.id === assignedUser.value))
      )
        return false
    }
    if (developerName && lead.developerName !== developerName.value)
      return false
    if (percentageSold) {
      if (lead.percentageSold < percentageSold.min) return false
      if (lead.percentageSold > percentageSold.max) return false
    }
    if (minUnits !== null && lead.units < minUnits) return false
    if (maxUnits !== null && lead.units > maxUnits) return false
    if (startDate && Moment(lead.createdAt).isBefore(startDate)) return false
    if (endDate && Moment(lead.createdAt).isAfter(endDate)) return false
    if (statusType && lead.status !== statusType.value) return false
    if (priorityType && lead.priority !== priorityType.value) return false
    return true
  })
}

export const filteredProjectTrackerLeads = (leads, projectTrackerFilters) => {
  const { startDate, endDate } = projectTrackerFilters

  const result = leads.filter((lead) => {
    if (startDate && Moment(lead.closedAt).isBefore(startDate)) return false
    if (endDate && Moment(lead.closedAt).isAfter(endDate)) return false
    return true
  })
  return result
}

import { connect } from 'react-redux'

import Messages from './components/Messages'
import { dismiss } from './actions'

const mapStateToProps = (state) => {
  const { messages } = state
  return { messages }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dismiss: (message) => dispatch(dismiss(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)

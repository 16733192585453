import { combineReducers } from 'redux'

import user from './user'
import filters from './filters'
import leadDetailsForms from './lead-details-forms'
import transitionActions from './transition-actions'
import messages from './messages'

export default combineReducers({
  ...user,
  ...filters,
  ...leadDetailsForms,
  ...transitionActions,
  ...messages,
})

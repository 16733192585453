import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { GetForecastsQuery } from './queries'
import apolloClient from '@/common/graphql-api'
import { buildProjectForecastReports, buildCoverReport } from './reportBuilders'
import { assumptionsMatrix } from './assumptions'
import { useSampleDataForExcel } from '@/config/feature-flags'

const fetchForecasts = async () => {
  const { projectForecast, tarionDepositProtectionAmt, depositRate } =
    assumptionsMatrix
  const response = await apolloClient.query({
    query: GetForecastsQuery,
    variables: {
      projectForecastOpts: {
        averagePricePerUnit: projectForecast.averagePricePerUnit.value,
        tarionDepositProtectionAmt: tarionDepositProtectionAmt.value,
        depositRate: depositRate.value,
      },
    },
    // always use the latest data
    fetchPolicy: 'no-cache',
  })
  return response.data
}

const initWorkbook = (): Workbook => {
  const workbook = new Workbook()
  const now = new Date()
  workbook.creator = 'WG Leads App'
  workbook.lastModifiedBy = 'WG Leads App'
  workbook.created = now
  workbook.modified = now

  // Set workbook dates to 1904 date system (compatibility with earlier versions of excel jic)
  workbook.properties.date1904 = true

  // Force workbook calculation on load
  workbook.calcProperties.fullCalcOnLoad = true

  // one main window
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible',
    },
  ]

  return workbook
}

export const buildExcelReport = async () => {
  const workbook = initWorkbook()

  try {
    console.log('building file for download!')
    const { projectForecast } = await fetchForecasts()
    const {
      rows: prRows,
      sample: prSampleRows,
      messages: prNotes,
    } = projectForecast

    buildCoverReport(workbook)

    if (useSampleDataForExcel) {
      buildProjectForecastReports(workbook, prSampleRows, prNotes)
    } else {
      buildProjectForecastReports(workbook, prRows, prNotes)
    }

    const buffer = await workbook.xlsx.writeBuffer({
      useStyles: true,
      useSharedStrings: true,
    })
    saveAs(new Blob([buffer]), `portfolio-forecasting-${Date.now()}.xlsx`)
  } catch (err) {
    console.error('Error writing excel export!', err)
  }
}

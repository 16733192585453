import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { filteredProjectTrackerLeads } from '../../common/filtered-leads'
import ProjectTrackerList from './components/ProjectTrackerList'

const mapStateToProps = (state) => {
  const { projectTrackerFilters } = state
  return { projectTrackerFilters }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const LeadListQuery = gql`
  query LeadList {
    leads(status: CLOSED) {
      id
      status
      projectName
      developerName
      units
      clientType
      closedAt
      closingOutcome
      closingReasons
    }
  }
`

const ComponentWithData = graphql(LeadListQuery, {
  props: ({ ownProps: { projectTrackerFilters }, data: { leads } }) => {
    return leads
      ? { leads: filteredProjectTrackerLeads(leads, projectTrackerFilters) }
      : null
  },
  options: { forceFetch: true },
})(ProjectTrackerList)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector

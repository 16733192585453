import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

class PercentageSold extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ percentageSold: selection })
  }

  render() {
    const { visible, percentageSold } = this.props

    if (!visible) return null

    const options = [
      { value: 0, label: '0 - 30', min: 0, max: 30 },
      { value: 1, label: '31 - 50', min: 31, max: 50 },
      { value: 2, label: '51 - 70', min: 51, max: 70 },
      { value: 3, label: '71 - 100', min: 71, max: 100 },
    ]

    return (
      <div id="percentage-sold-filter" className="filter columns two">
        <div className="label">% Sold</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={percentageSold}
            onChange={this.onChange}
            placeholder="Any"
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default PercentageSold

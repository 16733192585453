import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

import LoginForm from '../../../LoginForm'

const Login = (props) => {
  return (
    <div className="login row">
      <div className="three columns">&nbsp;</div>
      <div className="six columns">
        <div>
          <img className="logo" src="/images/login-logo.png" />
        </div>
        <LoginForm />
        <Link to="/auth/recovery" className="forgot-pwd">
          Forgot your password?
        </Link>
      </div>
      <div className="three columns">&nbsp;</div>
    </div>
  )
}

export default Login

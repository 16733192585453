import React from 'react'
import PropTypes from 'prop-types'
import GoalCard from '../../../core/components/GoalCard'
import GoalNeededCard from '../../../core/components/GoalNeededCard'

const GoalList = ({ title, className, goals, goalsNeeded }) => {
  if (goals.concat(goalsNeeded).length === 0) return null

  const goalList = goals.map((goal) => (
    <GoalCard key={goal.id} editable={false} showLeadInfo {...goal} />
  ))
  const goalNeededList = goalsNeeded.map((lead) => (
    <GoalNeededCard key={lead.id} showLeadInfo lead={lead} />
  ))

  return (
    <div className={`section ${className}`}>
      <p className="title">{title}</p>
      {goalList}
      {goalNeededList}
    </div>
  )
}

GoalList.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  goals: PropTypes.array.isRequired,
  goalsNeeded: PropTypes.array,
}

GoalList.defaultProps = {
  goalsNeeded: [],
}

export default GoalList

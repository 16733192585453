import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import UserProfileNotificationSettings from './components/UserProfileNotificationSettings'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const NotificationUpdateMutation = gql`
  mutation NotificationUpdate(
    $id: ID!
    $weeklyNotification: Boolean!
    $deadlineNotification: Boolean!
    $commentNotification: Boolean!
  ) {
    updateUser(
      id: $id
      weeklyNotification: $weeklyNotification
      deadlineNotification: $deadlineNotification
      commentNotification: $commentNotification
    ) {
      id
      weeklyNotification
      deadlineNotification
      commentNotification
    }
  }
`

const ComponentWithMutation = graphql(NotificationUpdateMutation, {
  props: ({ mutate }) => ({
    updateNotificationSettings: ({
      id,
      weeklyNotification,
      deadlineNotification,
      commentNotification,
    }) =>
      mutate({
        variables: {
          id,
          weeklyNotification,
          deadlineNotification,
          commentNotification,
        },
      }),
  }),
})(UserProfileNotificationSettings)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithMutation)

import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import './styles.scss'

class DateRange extends Component {
  constructor(props) {
    super(props)
    this.onStartDateChanged = this.onStartDateChanged.bind(this)
    this.onEndDateChanged = this.onEndDateChanged.bind(this)
  }

  onStartDateChanged(date) {
    const startDate = date ? date.startOf('day') : null
    this.props.onChange({ startDate })
  }

  onEndDateChanged(date) {
    const endDate = date ? date.endOf('day') : null
    this.props.onChange({ endDate })
  }

  render() {
    const { visible, minDate, maxDate, startDate, endDate } = this.props

    if (!visible) return null

    return (
      <div id="date-range-filter" className="filter columns two">
        <div className="label">Date Range</div>
        <div className="value">
          <DatePicker
            selected={startDate}
            placeholderText="Start"
            isClearable
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.onStartDateChanged}
          />
          <DatePicker
            selected={endDate}
            placeholderText="End"
            isClearable
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.onEndDateChanged}
          />
        </div>
      </div>
    )
  }
}

export default DateRange

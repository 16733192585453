import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import { updateForm, unloadForms } from './actions'
import FormContainer from './components/FormContainer'

const mapStateToProps = (state) => {
  return {
    leadDetailsForm: state.leadDetailsForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeForm: (form) => {
      dispatch(updateForm(form))
    },
    unloadForms: () => {
      dispatch(unloadForms())
    },
  }
}

const LeadQuery = gql`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      status
    }
  }
`

const ComponentWithData = graphql(LeadQuery, {
  props: ({ data: { lead } }) => {
    if (!lead) return null
    return {
      canAddGoal: lead.status !== 'CLOSED',
    }
  },
  options: ({ id }) => ({
    variabled: { id },
    forceFetch: true,
  }),
})(FormContainer)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector

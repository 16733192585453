import React from 'react'
import PropTypes from 'prop-types'
import ContactsSyncButton from '../../../ContactsSyncButton'
import resolvePhone from '../../../../common/phone'
import './styles.scss'

const ContactsField = ({ contacts, onSyncComplete }) => {
  const hasContacts = contacts.length > 0

  const resolveEmail = (email) => {
    return email ? <a href={`mailto:${email}`}>{email}</a> : ''
  }

  const contactsList = hasContacts
    ? contacts.map((contact) => {
        const formattedPhone = resolvePhone(contact.phone)
        const formattedEmail = resolveEmail(contact.email)

        return (
          <div className="contact" key={contact.id}>
            <div title={contact.name}>
              <strong>{contact.name}</strong>
            </div>
            <div title={formattedPhone}>{formattedPhone}</div>
            <div title={contact.email}>{formattedEmail}</div>
          </div>
        )
      })
    : 'Unknown'

  const className = hasContacts ? 'value' : 'value invalid'

  return (
    <div className="contacts-field">
      <span className="label">
        Contact(s)
        <br />
        <ContactsSyncButton onComplete={onSyncComplete} />
      </span>
      <span className={className}>{contactsList}</span>
    </div>
  )
}

ContactsField.propTypes = {
  contacts: PropTypes.array,
}

ContactsField.defaultProps = {
  contacts: [],
  onSyncComplete: () => {},
}

export default ContactsField

import React from 'react'

import UserProfilePassword from '../../../UserProfilePassword'

const PasswordChange = (props) => {
  return (
    <div className="login row">
      <div className="three columns">&nbsp;</div>
      <div className="six columns panel">
        <UserProfilePassword redirectOnComplete="/" />
      </div>
      <div className="three columns">&nbsp;</div>
    </div>
  )
}

export default PasswordChange

import React, { Component } from 'react'
import { MdInsertLink, MdDelete } from 'react-icons/md'
import './styles.scss'

import AddLinkModal from '../AddLinkModal'

class LeadLinks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }

    this.onNewLinkClick = this.onNewLinkClick.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onRemoveClick = this.onRemoveClick.bind(this)
  }

  onNewLinkClick() {
    this.setState({ showModal: true })
  }

  onModalClose() {
    this.setState({ showModal: false })
  }

  onRemoveClick(event, id) {
    event.preventDefault()
    this.props.onRemoveLink(id)
  }

  render() {
    const { links } = this.props.data

    if (!links) return null

    const linkList =
      links.length > 0 ? (
        links.map((link) => (
          <span key={link.id} className="link">
            <a href={link.uri} title={link.label} target="_blank">
              {link.label}
            </a>
            <MdDelete
              size={16}
              title="Remove"
              onClick={(event) => this.onRemoveClick(event, link.id)}
            />
          </span>
        ))
      ) : (
        <span className="no-links">No links for this lead</span>
      )

    return (
      <div id="links-list">
        <h5 className="panel-title">Links</h5>
        <div className="panel">
          <div className="link-list">{linkList}</div>
          <span className="btn link" onClick={this.onNewLinkClick}>
            <MdInsertLink size={16} /> New Link
          </span>
        </div>
        <AddLinkModal
          isOpen={this.state.showModal}
          onClose={this.onModalClose}
          leadId={this.props.id}
          onSaveClick={this.props.onSaveLink}
        />
      </div>
    )
  }
}

export default LeadLinks

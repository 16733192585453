import React, { Component } from 'react'
import Tooltip from 'rc-tooltip'
import './styles.scss'

import NotificationCard from '../NotificationCard'

class Notifications extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tooltipVisible: false,
    }

    this.hideTooltip = this.hideTooltip.bind(this)
    this.toggleTooltip = this.toggleTooltip.bind(this)
    this.renderNotificationList = this.renderNotificationList.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.hideTooltip, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.hideTooltip, false)
  }

  hideTooltip(event) {
    if (event.target.className !== 'rc-tooltip-inner') {
      this.setState({ tooltipVisible: false })
    }
  }

  toggleTooltip() {
    this.setState({ tooltipVisible: !this.state.tooltipVisible })
  }

  render() {
    const { activities } = this.props
    const notificationsCount = activities.length

    if (notificationsCount > 0) {
      return (
        <Tooltip
          placement="bottomRight"
          trigger="[click]"
          visible={this.state.tooltipVisible}
          onClick={this.toggleTooltip}
          align={{ offset: [-6, -8] }}
          overlayClassName="notifitications-overlay"
          overlay={this.renderNotificationList}>
          <div id="notifications" className="hide-mobile">
            <span
              title={`${notificationsCount} notification(s)`}
              className="notification-count">
              {notificationsCount}
            </span>
            <img
              title="Notifications"
              src="/images/notifications.svg"
              height={32}
              width={32}
            />
          </div>
        </Tooltip>
      )
    } else {
      return (
        <div id="notifications" className="hide-mobile">
          <img
            title="Notifications"
            src="/images/notifications.svg"
            height={32}
            width={32}
          />
        </div>
      )
    }
  }

  renderNotificationList() {
    const { activities, markUserNotificationAsRead } = this.props
    const activityList = activities.map((activity) => (
      <NotificationCard
        key={activity.id}
        activity={activity}
        onNavigateToLead={() =>
          markUserNotificationAsRead(activity.lead.id, activity.activityIds)
        }
      />
    ))

    return (
      <div className="notification-list" onClick={this.hideTooltip}>
        {activityList}
      </div>
    )
  }
}

Notifications.defaultProps = {
  activities: [],
}

export default Notifications

import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import GoalEditForm from './components/GoalEditForm'

const GoalEditMutation = gql`
  mutation UpdateGoal(
    $id: ID!
    $leadId: Int!
    $description: String!
    $deadline: DateTime!
  ) {
    updateGoal(
      id: $id
      leadId: $leadId
      description: $description
      deadline: $deadline
    ) {
      id
    }
  }
`

const ComponentWithGoalEditMutation = graphql(GoalEditMutation, {
  props: ({ mutate }) => ({
    onSubmit: ({ id, description, deadline, leadId }) =>
      mutate({
        variables: { id, description, deadline, leadId },
        refetchQueries: ['LeadGoals', 'LeadActivities'],
      }),
  }),
})(GoalEditForm)

export default ComponentWithGoalEditMutation

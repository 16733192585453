import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ProgressButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    if (this.state.loading) return

    this.setState({ loading: true })
    this.props.onClick().then(() => {
      this.setState({ loading: false })
      this.props.onComplete()
    })
  }

  render() {
    const { loading } = this.state
    const { icon, text } = this.props

    const displayIcon = loading ? <span className="Select-loading" /> : icon
    return (
      <span className="btn link" onClick={this.handleClick}>
        {displayIcon}&nbsp; {text}
      </span>
    )
  }
}

ProgressButton.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
}

ProgressButton.defaultProps = {
  icon: null,
  onComplete: () => {},
}

export default ProgressButton

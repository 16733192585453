import React from 'react'
import PropTypes from 'prop-types'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

import UserAvatar from '../UserAvatar'

const formattedOption = (option) => {
  return (
    <span className="option">
      <UserAvatar user={option.user} />
      {option.label}
    </span>
  )
}

const UserSelectField = (props) => {
  const id = props.id || 'user-select-field'
  const className = props.className

  return (
    <div id={id} className={className}>
      <DropdownSelect
        placeholder={props.placeholder}
        options={props.options}
        formatOptionLabel={formattedOption}
        onChange={props.onChange}
      />
    </div>
  )
}

UserSelectField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
      user: PropTypes.object.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  onChange: PropTypes.func,
}

export default UserSelectField

import React, { Component } from 'react'
import './styles.scss'

const postJsonp = (url, data) => {
  // TODO: RealNet API Integration
}

class LeadLinks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      baseRealNetUrl: 'https://realinfo.realnet.ca/client/newhomes/',
    }

    this.realNetSearchStep1 = this.realNetSearchStep1.bind(this)
    this.realNetSearchStep2 = this.realNetSearchStep2.bind(this)
    this.realNetSearchStep3 = this.realNetSearchStep3.bind(this)
    this.realNetSearchStep4 = this.realNetSearchStep4.bind(this)

    this.onRealNetActiveButtonClick = this.onRealNetActiveButtonClick.bind(this)
    this.onRealNetComingSoonButtonClick =
      this.onRealNetComingSoonButtonClick.bind(this)
  }

  realNetSearchStep1() {
    const { baseRealNetUrl } = this.state

    return postJsonp(baseRealNetUrl + 'search.do?exec=selection&reset=1', {})
  }

  realNetSearchStep2() {
    const { baseRealNetUrl, comingSoon } = this.state

    return postJsonp(baseRealNetUrl + 'search.do?exec=criteria', {
      markets: 1,
      sectorId: comingSoon ? 'ComingSoon' : 'HighRise',
    })
  }

  realNetSearchStep3() {
    const { baseRealNetUrl, comingSoon } = this.state
    const { developerName } = this.props.lead
    const url = comingSoon
      ? baseRealNetUrl + 'searchComingSoon.do?exec=search'
      : baseRealNetUrl + 'searchHighRise.do?exec=search'

    const data = {
      builderName: developerName,
    }

    if (!comingSoon) {
      ;({
        ...data,
        pageNumber: 1,
        availPricePerSqFtLow: 0,
        availPricePerSqFtHigh: -1,
        statusId: 12,
      })
    }

    return postJsonp(url, data)
  }

  realNetSearchStep4() {
    const { baseRealNetUrl, comingSoon } = this.state
    const url = comingSoon
      ? baseRealNetUrl + 'searchComingSoon.do?exec=search'
      : baseRealNetUrl + 'searchHighRise.do?exec=search'

    window.open(url)
  }

  searchRealNet() {
    const {
      realNetSearchStep1,
      realNetSearchStep2,
      realNetSearchStep3,
      realNetSearchStep4,
    } = this
    realNetSearchStep1()
      .always(realNetSearchStep2)
      .always(realNetSearchStep3)
      .always(realNetSearchStep4)
  }

  onRealNetActiveButtonClick() {
    return
    // TODO: RealNet API Integration
    // this.setState({ comingSoon: false }, this.searchRealNet())
  }

  onRealNetComingSoonButtonClick() {
    return
    // TODO: RealNet API Integration
    // this.setState({ comingSoon: true }, this.searchRealNet())
  }

  render() {
    const { lead } = this.props

    if (!lead) return null
    const tarionUrl = `https://obd.hcraontario.ca/buildersearchresults?builderName=${lead.developerName}&page=1`

    return (
      <div id="resource-links-list">
        <h5 className="panel-title">Resources</h5>
        <div className="panel">
          <div className="link-list">
            <span className="link">
              <a href={tarionUrl} title="Search Tarion" target="_blank">
                Search Tarion
              </a>
            </span>
            <span
              onClick={this.onRealNetActiveButtonClick}
              className="link disabled"
              title="RealNet Integration Disabled">
              Search RealNet - High Rise
            </span>
            <span
              onClick={this.onRealNetComingSoonButtonClick}
              className="link  disabled"
              title="RealNet Integration Disabled">
              Search RealNet - Upcoming
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default LeadLinks

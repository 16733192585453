import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import {
  LeadStatusTypes,
  ClosingOutcomeTypes,
} from '../../../../common/enumerations'

const StatusBars = (props) => {
  const { currentStatus, closingOutcome, statusDates } = props
  const statuses = Object.keys(LeadStatusTypes)
  const maxIndex = statuses.findIndex((status) => status === currentStatus)
  const statusBars = statuses.map((status, index) => {
    const enabled = index <= maxIndex ? 'enabled' : ''
    const closed = status === 'CLOSED' ? closingOutcome : ''
    const current = maxIndex === index ? 'current' : ''
    const className = `colour-bar ${enabled} ${current} ${closed}`
    const statusDate = statusDates.find(
      (statusDate) => statusDate.status === status,
    )
    const formattedStatusDate =
      enabled && statusDate ? (
        <div>{statusDate.createdAt}</div>
      ) : (
        <div>&nbsp;</div>
      )
    return (
      <div key={status} className={className}>
        {LeadStatusTypes[status].label}
        {formattedStatusDate}
      </div>
    )
  })

  return (
    <div id="status-bar">
      <p>Lead Status</p>
      {statusBars}
    </div>
  )
}

StatusBars.propTypes = {
  currentStatus: PropTypes.oneOf(Object.keys(LeadStatusTypes)).isRequired,
  closingOutcome: PropTypes.oneOf(Object.keys(ClosingOutcomeTypes)),
}

export default StatusBars

import React, { Component } from 'react'

import withRouter from '../../../core/withRouter'
import RecoverySuccess from '../RecoverySuccess'
import RecoveryForm from '../RecoveryForm'

class PasswordRecovery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      success: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(data) {
    this.props.onSubmit(data).then(() => {
      this.setState({ success: true })
    })
  }

  render() {
    const { success } = this.state
    const backToLogin = () => this.props.router.navigate('/auth')

    return success ? (
      <RecoverySuccess backToLogin={backToLogin} />
    ) : (
      <RecoveryForm onSubmit={this.onSubmit} onCancel={backToLogin} />
    )
  }
}

export default withRouter(PasswordRecovery)

import { Cell } from 'exceljs'
import { SegmentedData, CellCursor, CellPin, ColumnDef } from './types'
import _ from 'lodash'

export const toSegments = <T>(
  rows: T[],
  fieldName: string,
  skipFalseySegmentNames: boolean = true,
): SegmentedData<T> => {
  return rows.reduce<SegmentedData<T>>((acc: SegmentedData<T>, rowObj: T) => {
    const segmentName = (rowObj as any)[fieldName]
    if (skipFalseySegmentNames && !segmentName) return acc
    if (!acc[segmentName]) {
      acc[segmentName] = []
    }
    acc[segmentName].push(rowObj)
    return acc
  }, {} as SegmentedData<T>)
}

export const makeCellCursor = (fixedCell: Cell): CellCursor => {
  const ws = fixedCell.worksheet
  const pins: CellPin[] = []
  let cell = fixedCell
  return {
    down(n: number): CellCursor {
      const rowIndex = cell.fullAddress.row + n
      cell = ws.getCell(rowIndex, cell.col)
      return this
    },
    across(n: number): CellCursor {
      const colIndex = cell.fullAddress.col + n
      cell = cell.worksheet.getCell(cell.row, colIndex)
      return this
    },
    value(value: any): CellCursor {
      cell.value = value
      return this
    },
    bold(toggle: boolean = true): CellCursor {
      cell.style.font = { bold: toggle }
      return this
    },
    pin(): CellCursor {
      pins.push(cell.fullAddress)
      return this
    },
    returnToLastPin(): CellCursor {
      const last = pins.pop()
      if (last) {
        cell = ws.getCell(last.row, last.col)
      }
      return this
    },
    width(width: number): CellCursor {
      if (width) {
        const col = ws.getColumn(cell.fullAddress.col)
        col.width = width
      }
      return this
    },
    styleAs(coldef: ColumnDef, styleName: string): CellCursor {
      const { styles, header, width } = coldef
      header && this.value(header)
      width && this.width(width)
      if (styles) {
        const style = styles[styleName]
        style && (cell.style = _.merge({}, style))
      }
      return this
    },
  }
}

export const keySort = (inputObj: Record<string, any>) =>
  Object.keys(inputObj)
    .sort()
    .reduce(
      (obj, key) => {
        obj[key] = inputObj[key]
        return obj
      },
      {} as Record<string, any>,
    )

import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import UserAvatar from '../../../core/components/UserAvatar'
import ToggleField from '../../../core/components/ToggleField'

const AdminProfileUserList = ({ user, users, toggleUserActiveState }) => {
  if (!user.admin) return null

  const userList = users.map((user) => {
    return (
      <div key={user.id} className="user">
        <UserAvatar user={user} size="medium" />
        <div className="user-name">{user.name}</div>
        <div className="fields">
          <ToggleField
            editable
            value={user.admin}
            label="Administrator"
            onChange={(admin) =>
              toggleUserActiveState(user.id, { ...user, admin })
            }
          />
          <ToggleField
            editable
            value={user.active}
            label="Active"
            onChange={(active) =>
              toggleUserActiveState(user.id, { ...user, active })
            }
          />
        </div>
      </div>
    )
  })

  return (
    <div id="admin-profile-user-list">
      <div className="row">
        <div className="columns twelve">
          <h5>Modify Users</h5>
          {userList}
        </div>
      </div>
    </div>
  )
}

AdminProfileUserList.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.array,
  toggleUserActiveState: PropTypes.func.isRequired,
}

AdminProfileUserList.defaultProps = {
  users: [],
}

export default AdminProfileUserList

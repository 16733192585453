import gql from 'graphql-tag'

export const PairingsListQuery = gql`
  query Pairings($triageStatus: PairingTriageStatus) {
    pairings(triageStatus: $triageStatus) {
      id
      triageStatus
      basis
      triagedBy {
        name
      }
      leadId
      lead {
        id
        projectName
        developerName
        wgnetClientName
        address
        units
      }
      wgnetProject
    }
  }
`

export const TriagePairingMutation = gql`
  mutation TriagePairing($triageList: [PairingTriageInput]!) {
    triagePairings(triageList: $triageList) {
      id
      triageStatus
    }
  }
`

import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import { filteredProjectTrackerLeads } from '../../common/filtered-leads'
import ProjectTrackerGraphs from './components/ProjectTrackerGraphs'

const mapStateToProps = (state) => {
  const { projectTrackerFilters } = state
  return { projectTrackerFilters }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const ClosedLeadsQuery = gql`
  query ClosedLeads {
    leads(status: CLOSED) {
      units
      closingOutcome
      closedAt
    }
  }
`

const ComponentWithData = graphql(ClosedLeadsQuery, {
  props: ({ ownProps: { projectTrackerFilters }, data: { leads } }) => {
    if (!leads) return null

    const filteredLeads = filteredProjectTrackerLeads(
      leads,
      projectTrackerFilters,
    )

    const stats = ['won', 'lost', 'rejected', 'closed'].reduce(
      (acc, outcome) => {
        acc[outcome] = [10, 100, 200, 300, 400, 500].map((range) => ({
          leads: 0,
          range,
          units: 0,
        }))
        return acc
      },
      {},
    )

    filteredLeads.forEach(({ closingOutcome, units }) => {
      let range = Math.floor(units / 100) * 100

      if (range === 0) {
        range = 10
      } else if (range > 500) {
        range = 500
      }

      const totalCounter = stats.closed.find(
        (counter) => counter.range === range,
      )
      totalCounter.leads++
      totalCounter.units += units

      const statSection = stats[closingOutcome.toLowerCase()]
      const sectionCounter = statSection.find(
        (counter) => counter.range === range,
      )
      sectionCounter.leads++
      sectionCounter.units += units
    })

    return {
      ...stats,
    }
  },
})(ProjectTrackerGraphs)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector

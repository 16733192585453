import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const AvatarSizes = {
  small: 20,
  medium: 30,
  large: 120,
}

const UserAvatar = (props) => {
  const { user, size } = props

  if (!user.id) return null

  const { id, name } = user

  const avatarStyles = {
    width: `${AvatarSizes[size]}px`,
    height: `${AvatarSizes[size]}px`,
  }

  if (user.avatarFilename) {
    avatarStyles.backgroundImage = `url('/avatars/${user.avatarFilename}')`
  }

  return (
    <div
      className="user-avatar"
      data-id={id}
      title={name}
      style={avatarStyles}
    />
  )
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    avatarFilename: PropTypes.string,
  }),
  size: PropTypes.oneOf(Object.keys(AvatarSizes)),
}

UserAvatar.defaultProps = {
  size: 'small',
}

export default UserAvatar

import { ADD_MESSAGE } from '../../modules/core/action-types'

export const userCreated = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: { type: 'info', content: `User ${user.name} has been created` },
    })
  }
}

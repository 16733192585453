import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import CommentForm from './components/CommentForm'
import {
  updateCommentForm,
  clearCommentForm,
  validateCommentForm,
} from './actions'

const mapStateToProps = (state) => {
  const { commentFormData, commentFormErrors } = state

  return {
    commentFormData,
    commentFormErrors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateForm: (formData) => dispatch(updateCommentForm(formData)),
    clearForm: () => dispatch(clearCommentForm()),
    validate: (fields) => dispatch(validateCommentForm(fields)),
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const id = ownProps.id
  const commentFormData = Object.assign({}, stateProps.commentFormData, { id })
  const newStateProps = Object.assign({}, stateProps, { commentFormData })

  const newProps = Object.assign({}, newStateProps, ownProps, dispatchProps)

  return newProps
}

const CreateCommentMutation = gql`
  mutation CreateComment($id: Int!, $content: String!) {
    addComment(leadId: $id, content: $content) {
      id
      content
    }
  }
`

const componentWithCreate = graphql(CreateCommentMutation, {
  props: ({ ownProps, mutate }) => ({
    onSubmit: ({ id, content }) => {
      ownProps.clearForm()

      return mutate({
        variables: { id, content },
        refetchQueries: ['LeadActivities'],
      })
        .then(() => ownProps.clearForm())
        .catch(() => ownProps.validate({ content }))
    },
  }),
})(CommentForm)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentWithCreate)

export default connector

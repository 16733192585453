import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

class ActivityType extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.valueRenderer = this.valueRenderer.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ activityType: selection })
  }

  valueRenderer(option) {
    return <span className={option.className}>{option.label}</span>
  }

  render() {
    const { visible, activityType } = this.props

    if (!visible) return null

    const ActivityTypes = {
      NEW_ACTIVITY: 'New Activity',
      MISSED_GOAL: 'Missed Goal',
      GOAL_NEEDED: 'New Goal Needed',
    }
    const options = Object.keys(ActivityTypes).map((activityType) => ({
      value: activityType,
      label: (
        <span className={activityType.toLowerCase()}>
          {ActivityTypes[activityType]}
        </span>
      ),
    }))

    return (
      <div id="activity-type-filter" className="filter columns two">
        <div className="label">Activity</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={activityType}
            onChange={this.onChange}
            optionRenderer={this.valueRenderer}
            placeholder="All"
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default ActivityType

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CreatableSelect, DropdownSelect } from '../DropdownSelect'
import './styles.scss'

import Label from '../Label'
import Value from '../Value'

class SelectField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      selection: null,
    }

    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.updateState(this.props)
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.updateState(props)
  }

  updateState(props) {
    const { value, options, nullable } = props

    if (nullable && options.length > 0) {
      options.splice(0, 0, { value: null, label: 'Unknown' })
    }

    const selection = options.find(
      (option) => option.value === value || option.label === value,
    ) || { value, label: value }
    this.setState({ selection, options })
  }

  onClick() {
    if (!this.props.editable) {
      return
    }

    this.setState({ editing: true })
    this.props.onClick()
  }

  onChange(selection) {
    if (!selection || selection.length === 0) {
      selection = { value: null, label: 'Unknown' }
    }

    if (selection.value || (!selection.value && this.props.nullable)) {
      this.setState({ selection }, () => {
        this.props.onChange(selection.value)
      })
    }
  }

  onBlur(value) {
    this.setState({ editing: false })
  }

  render() {
    const {
      label,
      editable,
      creatable,
      keepEditorOpen,
      className,
      nullable,
      noResultsValue,
      displayLoading,
    } = this.props

    const { editing, options, selection } = this.state

    const editProps = {
      className: `input ${className}`,
      options: options,
      value: selection,
      autofocus: !keepEditorOpen,
      openMenuOnFocus: true,
      isClearable: !!nullable,
      creatable: !!creatable,
      promptTextCreator: (label) => `Create "${label}"`,
      optionRenderer: this.props.optionRenderer,
      valueRenderer: this.props.valueRenderer,
      onChange: this.onChange,
      onMenuClose: this.onBlur,
      noResultsText: noResultsValue,
      isLoading: displayLoading,
    }

    const classNames = [
      editable ? 'editable' : null,
      !selection.value ? 'invalid' : null,
      className,
    ].join(' ')

    const valueProps = {
      className: classNames,
      value: selection.value ? this.props.valueRenderer(selection) : 'Unknown',
      onClick: this.onClick,
    }

    const showEditor = editing || keepEditorOpen
    const SelectElement = creatable ? CreatableSelect : DropdownSelect

    return (
      <div className="field select">
        <Label text={label} />
        {showEditor ? (
          <SelectElement {...editProps} />
        ) : (
          <Value {...valueProps} />
        )}
      </div>
    )
  }
}

SelectField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  creatable: PropTypes.bool,
  nullable: PropTypes.bool,
  onChange: (props, propName, componentName) => {
    if (props.editable) {
      if (!props[propName]) {
        return new Error(
          `'${propName}' prop is required for an editable ${componentName}.`,
        )
      }
      if (typeof props[propName] !== 'function') {
        return new Error(
          `Invalid prop ${propName} of type '${typeof props[
            propName
          ]}' passed to ${componentName}. Expecting a function.`,
        )
      }
    }
  },
  valueRenderer: PropTypes.func,
}

SelectField.defaultProps = {
  onClick: () => {},
  noResultsValue: 'No Results Found',
  valueRenderer: (selection) => selection.label,
}

export default SelectField

import React from 'react'
import ReactModal from 'react-modal'
import { MdClose } from 'react-icons/md'
import './styles.scss'

const defaultStyle = {
  borderRadius: '2px',
  border: 'none',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  width: '30%',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.3)',
}

const { border, borderRadius, boxShadow } = defaultStyle
const fullscreenStyle = Object.assign(
  {},
  {
    border,
    borderRadius,
    boxShadow,
    overflow: 'hidden',
  },
)

const Modal = (props) => {
  const { id, isOpen, label, onClose, profile } = props

  const contentStyle = profile === 'fullscreen' ? fullscreenStyle : defaultStyle

  const modalProps = {
    isOpen,
    contentLabel: label,
    onRequestClose: onClose,
    ariaHideApp: false,
    style: {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: '10000',
      },
      content: contentStyle,
    },
  }

  return (
    <ReactModal {...modalProps}>
      <MdClose size={30} className="close" onClick={onClose} />
      <div id={id} className="modal">
        {props.children}
      </div>
    </ReactModal>
  )
}

export default Modal

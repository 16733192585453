import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import TextareaField from '../../../core/components/TextareaField'

const COMMENT_PLACEHOLDER = 'Leave a comment for this project'

class CommentForm extends Component {
  constructor() {
    super()

    this.updateState = this.updateState.bind(this)
  }

  updateState(content) {
    const { commentFormData, onUpdateForm } = this.props

    const formData = { ...commentFormData, content }
    onUpdateForm(formData)
  }

  render() {
    const { commentFormData, commentFormErrors, onSubmit, clearForm } =
      this.props

    return (
      <div id="comment-form">
        <div className="row">
          <div className="twelve columns">
            <TextareaField
              editable
              keepEditorOpen
              nullable
              placeholderText={COMMENT_PLACEHOLDER}
              className={commentFormErrors.content ? 'invalid' : ''}
              value={commentFormData.content}
              onChange={this.updateState}
            />
          </div>
        </div>
        <div className="row">
          <div className="four columns">
            <span
              className="btn primary full-width"
              onClick={onSubmit.bind(this, commentFormData)}>
              Add Comment
            </span>
          </div>
          <div className="three columns">
            <span
              className="btn primary full-width"
              onClick={onSubmit.bind(this, clearForm)}>
              Clear
            </span>
          </div>
        </div>
      </div>
    )
  }
}

CommentForm.propTypes = {
  commentFormData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }),
}

export default CommentForm

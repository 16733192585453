import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import './styles.scss'

import { LeadStatusTypes } from '../../../../common/enumerations'
import { Lead as LeadLabels } from '../../../../common/property-labels'
import StringField from '../../../core/components/StringField'
import NumberField from '../../../core/components/NumberField'
import SelectField from '../../../core/components/SelectField'
import StatusBars from '../StatusBars'
import Modal from '../../../core/components/Modal'
import LeadCloseForm from '../../../LeadCloseForm'
import { ApolloClient } from '@apollo/client'
import gql from 'graphql-tag'

const ClientListQuery = gql`
  query Clients {
    clients {
      id
      name
      rating
      clientType
      underwriter {
        id
        email
      }
    }
  }
`

class LeadDetailsHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      clients: [],
    }

    this.onChange = this.onChange.bind(this)
    this.onCloseLeadClick = this.onCloseLeadClick.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onWgnetClientClick = this.onWgnetClientClick.bind(this)
    this.clientValueRenderer = this.clientValueRenderer.bind(this)
  }

  onChange(property, value) {
    const { lead } = this.props
    if (lead[property] !== value) {
      this.props.onUpdateLead({
        ...lead,
        [property]: value,
      })
    }
  }

  onWgnetClientClick() {
    if (this.state.clients.length === 0) {
      this.props.client
        .query({
          query: ClientListQuery,
        })
        .then((res) => {
          this.setState({ clients: res.data.clients })
        })
    }
  }

  onWgnetClientChange(value) {
    const { lead } = this.props
    const client = value ? this.state.clients.find((x) => x.id === value) : null
    const type = client
      ? !lead.clientType
        ? client.clientType
        : lead.clientType
      : null
    const ownerId = client && client.underwriter ? client.underwriter.id : null

    this.props.onUpdateLeadFromWGNetClient({
      id: lead.id,
      wgnetClientId: client ? client.id : null,
      wgnetClientName: client ? client.name : null,
      developerRating: client ? client.rating : null,
      ownerId: ownerId,
      clientType: type,
    })
  }

  onCloseLeadClick() {
    this.setState({ isModalOpen: true })
  }

  onModalClose() {
    this.setState({ isModalOpen: false })
  }

  clientValueRenderer(option) {
    const clientStatusIcon = this.props.lead.wgnetClientId ? (
      <MdCheckCircle size={16} color={'#00e3a2'} />
    ) : (
      <MdCancel size={16} color={'#f94040'} />
    )
    return (
      <span className="client-value" title={option.label}>
        {clientStatusIcon} {option.label}
      </span>
    )
  }

  render() {
    const { lead, developerList, statusDates } = this.props
    const { clients } = this.state
    if (!lead) return null

    const {
      status,
      projectName,
      address,
      developerName,
      wgnetClientId,
      developerRating,
      units,
      percentageSold,
      closingOutcome,
      wgnetClientName,
    } = lead

    const editable = LeadStatusTypes[status].editable

    const wgnetClientOptions =
      clients && clients.length > 0
        ? clients.map((client) => ({ value: client.id, label: client.name }))
        : [{ value: null, label: 'Loading WGNet Clients...' }]

    return (
      <div id="lead-details-header">
        <div className="row section-title">
          <div className="columns ten">
            <h3>
              <StringField
                editable={editable}
                value={projectName}
                className="title"
                onChange={(value) => this.onChange('projectName', value)}
              />
            </h3>
          </div>
          <div className="columns two">
            {status !== 'CLOSED' && (
              <span
                className="btn primary full-width"
                onClick={this.onCloseLeadClick}>
                Close Lead
              </span>
            )}
          </div>
        </div>
        <div className="row details">
          <div className="columns four">
            <StringField
              editable={editable}
              label={LeadLabels['address'].label}
              value={address}
              onChange={(value) => this.onChange('address', value)}
            />
          </div>
          <div className="columns four client">
            <SelectField
              nullable
              editable
              creatable
              label={LeadLabels['developerName'].label}
              value={developerName}
              className="bold"
              valueRenderer={this.clientValueRenderer}
              options={developerList.map((developer) => ({
                value: developer,
                label: developer,
              }))}
              onChange={(value) => this.onChange('developerName', value)}
            />
          </div>
          <div className="columns four client">
            <SelectField
              nullable={clients && clients.length > 0}
              editable
              label={LeadLabels['wgnetClientId'].label}
              value={clients.length === 0 ? wgnetClientName : wgnetClientId}
              className="bold"
              options={wgnetClientOptions}
              onClick={this.onWgnetClientClick}
              displayLoading={!clients || clients.length === 0}
              noResultsValue={'No matches found...'}
              onChange={(value) => this.onWgnetClientChange(value)}
            />
          </div>
        </div>
        <div className="row details">
          <div className="columns four">
            <StringField
              editable
              nullable
              label={LeadLabels['developerRating'].label}
              value={developerRating}
              className="bold"
              onChange={(value) => this.onChange('developerRating', value)}
            />
          </div>
          <div className="columns four">
            <NumberField
              editable={editable}
              label={LeadLabels['units'].label}
              value={units}
              className="bold"
              min={1}
              onChange={(value) => this.onChange('units', value)}
            />
          </div>
          <div className="columns four">
            <NumberField
              editable={editable}
              label={LeadLabels['percentageSold'].label}
              value={percentageSold}
              className="bold percent"
              min={0}
              max={100}
              onChange={(value) => this.onChange('percentageSold', value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="columns twelve">
            <StatusBars
              currentStatus={status}
              closingOutcome={closingOutcome}
              statusDates={statusDates}
            />
          </div>
        </div>
        <Modal
          id="close-lead-modal"
          isOpen={this.state.isModalOpen}
          label="Close Lead"
          onClose={this.onModalClose}>
          <LeadCloseForm
            lead={lead}
            onComplete={this.onModalClose}
            onCancel={this.onModalClose}
          />
        </Modal>
      </div>
    )
  }
}

LeadDetailsHeader.propTypes = {
  lead: PropTypes.object,
  developerList: PropTypes.array,
  clients: PropTypes.array,
  client: PropTypes.instanceOf(ApolloClient).isRequired,
}

LeadDetailsHeader.defaultProps = {
  developerList: [],
  clients: [],
}

export default LeadDetailsHeader

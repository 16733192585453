import React from 'react'
import { GOAL_FORM, COMMENT_FORM } from '../../../core/lead-details-form-types'
import { MdInsertInvitation, MdTextsms } from 'react-icons/md'
import './styles.scss'

export default ({ canAddGoal, onClick }) => {
  return (
    <div id="lead-details-form-selector">
      <span className="btn link" onClick={onClick.bind(this, COMMENT_FORM)}>
        <MdTextsms /> Comment
      </span>
      <span
        className={`btn link ${!canAddGoal ? 'disabled' : ''}`}
        onClick={() => {
          if (canAddGoal) {
            onClick(GOAL_FORM)
          }
        }}>
        <MdInsertInvitation /> Goal
      </span>
    </div>
  )
}

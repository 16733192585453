import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import TextareaField from '../../../core/components/TextareaField'
import DateField from '../../../core/components/DateField'

const GOAL_PLACEHOLDER = 'Enter a goal for this project (max 255 characters)'

class GoalForm extends Component {
  constructor() {
    super()

    this.onDeadlineChange = this.onDeadlineChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
  }

  onDeadlineChange(deadline) {
    const formData = { ...this.props.goalFormData, deadline }
    this.props.onUpdateForm(formData)
  }

  onDescriptionChange(description) {
    const formData = {
      ...this.props.goalFormData,
      description,
    }
    this.props.onUpdateForm(formData)
  }

  render() {
    const { goalFormData, goalFormErrors } = this.props

    return (
      <div id="goal-form">
        <div className="row">
          <div className="twelve columns">
            <TextareaField
              editable
              keepEditorOpen
              nullable
              placeholderText={GOAL_PLACEHOLDER}
              className={
                goalFormErrors.description || goalFormErrors.descriptionLength
                  ? 'invalid'
                  : ''
              }
              value={goalFormData.description}
              onChange={this.onDescriptionChange}
            />
            <span
              className="error-message"
              style={{
                display: goalFormErrors.descriptionLength ? 'block' : 'none',
              }}>
              Maximum length of 255 characters exceeded
            </span>
          </div>
        </div>
        <div className="row">
          <div className="three columns">
            <DateField
              editable
              keepEditorOpen
              nullable
              className={goalFormErrors.deadline ? 'invalid' : ''}
              label="Due Date"
              value={goalFormData.deadline}
              onChange={this.onDeadlineChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="four columns">
            <span
              className="btn primary full-width"
              onClick={this.props.onSubmit.bind(this, goalFormData)}>
              Add Goal
            </span>
          </div>
          <div className="three columns">
            <span
              className="btn primary full-width"
              onClick={this.props.clearForm}>
              Clear
            </span>
          </div>
        </div>
      </div>
    )
  }
}

GoalForm.propTypes = {
  goalFormData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    deadline: PropTypes.string,
  }),
}

export default GoalForm

import { BorderStyle, Column } from 'exceljs'
import _ from 'lodash'
import { ColumnDef } from './types'

// format: <positive>;<negative>;<zero>;<Text>
// prettier-ignore
const currencyFormat = '\$* ###,###,##0;[Red]\-\$* ###,###,##0'
const dateFormat = 'yyyy-mm-dd'

// style partials
export const borderBottomBold = {
  border: {
    bottom: { style: 'thin' as BorderStyle },
  },
  font: { bold: true },
}
export const doubleBorderTopBold = {
  border: {
    top: { style: 'double' as BorderStyle },
  },
  font: { bold: true },
}
export const money = {
  numFmt: currencyFormat,
}

// alignment partials
export const wrappingText = {
  wrapText: true,
  vertical: 'top',
  horizontal: 'left',
}

export const projectColumnHeaders: Partial<Column>[] = [
  {
    header: 'Client',
    key: 'wgnetClientName',
    width: 37,
  },
  {
    header: 'Project Name',
    key: 'projectName',
    width: 31,
  },
  {
    header: 'Units',
    key: 'units',
    width: 8,
  },
  {
    header: 'Sales Started',
    key: 'salesStartedAt',
    width: 12,
    style: {
      numFmt: dateFormat,
    },
  },
  {
    header: 'Sales Status',
    key: 'salesStatus',
    width: 14,
  },
  {
    header: 'Construction Status',
    key: 'constructionStatus',
    width: 17,
  },
  {
    header: 'Client Type',
    key: 'clientType',
    width: 13,
  },
  {
    header: 'Tarion Bond',
    key: 'tarionBond',
    width: 16,
    style: {
      numFmt: currencyFormat,
    },
  },
  {
    header: 'Potential DI facility',
    key: 'potentialDiFacility',
    width: 16,
    style: {
      numFmt: currencyFormat,
    },
  },
  {
    header: 'Average unit price',
    key: 'averageUnitPrice',
    width: 13,
    style: {
      numFmt: currencyFormat,
    },
  },
  {
    header: 'Average revenues',
    key: 'averageRevenues',
    width: 16,
    style: {
      numFmt: currencyFormat,
    },
  },
]

export const projectSummaryHeaders: ColumnDef[] = [
  {
    header: 'Client Type', // temp: changed for each segment
    key: 'salesStatus', // the segment data is based on this field
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: {},
      total: doubleBorderTopBold,
    },
  },
  {
    header: 'Number of Projects',
    key: 'numberOfProjects',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: {},
      total: doubleBorderTopBold,
    },
  },
  {
    header: 'Unit Count',
    key: 'units',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: {},
      total: doubleBorderTopBold,
    },
  },
  {
    header: 'Tarion Bond',
    key: 'tarionBond',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: _.merge({}, money),
      total: _.merge({}, money, doubleBorderTopBold),
    },
  },
  {
    header: 'DI Facility',
    key: 'potentialDiFacility',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: _.merge({}, money),
      total: _.merge({}, money, doubleBorderTopBold),
    },
  },
  {
    header: 'Total Facilities',
    key: 'totalFacilities',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: _.merge({}, money),
      total: _.merge({}, money, doubleBorderTopBold),
    },
  },
  {
    header: 'Unit Count Projects Coming to Market',
    key: 'unitsComingToMarket',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: {},
      total: doubleBorderTopBold,
    },
  },
  {
    header: 'Unit Count WG Market Share (%)',
    key: 'unitsMarketShare',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: {},
      total: doubleBorderTopBold,
    },
  },
  {
    header: 'WG Tarion Bond',
    key: 'wgTarionBond',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: _.merge({}, money),
      total: _.merge({}, money, doubleBorderTopBold),
    },
  },
  {
    header: 'WG DI Facility',
    key: 'wgDiFacility',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: _.merge({}, money),
      total: _.merge({}, money, doubleBorderTopBold),
    },
  },
  {
    header: 'WG Total Facilities',
    key: 'wgTotalFacilities',
    width: 18,
    styles: {
      heading: borderBottomBold,
      content: _.merge({}, money),
      total: _.merge({}, money, doubleBorderTopBold),
    },
  },
]

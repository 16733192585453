import {
  CLEAR_TRANSITION,
  CLOSE_TRANSITION,
  TRANSITION_COMPLETE,
  CLEAR_FILTERS,
  FILTERS_UPDATED,
} from '../core/action-types'

export const transitionLead = (status, lead) => {
  return (dispatch) => {
    switch (status) {
      case 'CLOSED':
        return dispatch({
          type: CLOSE_TRANSITION,
          payload: lead,
        })
      default:
        return dispatch({
          type: TRANSITION_COMPLETE,
          payload: lead,
        })
    }
  }
}

export const clearTransition = () => {
  return (dispatch) => {
    return dispatch({
      type: CLEAR_TRANSITION,
    })
  }
}

export const resetFilters = () => {
  return { type: CLEAR_FILTERS }
}

export const filterByStatus = (statusType) => {
  return (dispatch) => {
    dispatch(resetFilters())
    return dispatch({ type: FILTERS_UPDATED, payload: { statusType } })
  }
}

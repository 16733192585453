import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import DateRange from './components/DateRange'
import { updateFilters } from './actions'

const mapStateToProps = (state) => {
  const { projectTrackerFilters } = state
  return { projectTrackerFilters }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilters: (filters) => dispatch(updateFilters(filters)),
  }
}

const LeadListQuery = gql`
  query LeadList {
    leads(status: CLOSED) {
      closedAt
    }
  }
`

const ComponentWithData = graphql(LeadListQuery, {
  props: ({ data: { leads } }) => ({
    leads,
  }),
  options: { forceFetch: true },
})(DateRange)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)
export default connector

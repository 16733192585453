import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import './styles.scss'

import ResponsiveTable from '../../../ResponsiveTable'
import {
  ClientTypes,
  ClosingOutcomeTypes,
  ClosingReasonTypes,
} from '../../../../common/enumerations'

const ProjectTrackerList = (props) => {
  const { leads } = props
  const leadsList = leads.map((lead) => ({
    ...lead,
    closedAt: Moment(lead.closedAt).format('MM/DD/YY'),
    clientType: lead.clientType ? ClientTypes[lead.clientType] : '',
  }))

  const columns = [
    {
      cellRenderer: (param) => {
        return <Link to={`/leads/${param.data.id}`}>{param.value}</Link>
      },
      field: 'projectName',
      flex: 2,
      headerName: 'Project Name',
      sort: 'asc',
      sortable: true,
    },
    {
      field: 'clientType',
      headerName: 'Client Type',
      sortable: true,
      width: 100,
    },
    {
      field: 'developerName',
      flex: 1,
      headerName: 'Client',
      sortable: true,
    },
    {
      field: 'units',
      headerName: 'Units',
      sortable: true,
      width: 80,
    },
    {
      field: 'closedAt',
      headerName: 'Close Date',
      sortable: true,
      width: 100,
    },
    {
      cellRenderer: (param) => {
        const outcomeClass = `outcome ${param.value}`
        return (
          <div className={outcomeClass.toLowerCase()}>
            {ClosingOutcomeTypes[param.value]}
          </div>
        )
      },
      field: 'closingOutcome',
      headerName: 'Outcome',
      sortable: true,
      width: 100,
    },
    {
      autoHeight: true,
      field: 'closingReasons',
      headerName: 'Reasons',
      sortable: true,
      valueFormatter: (param) => {
        if (param.value !== null) {
          const closingReasonList = param.value
            .split(',')
            .map((closingReason) => ClosingReasonTypes[closingReason])
            .join(', ')
          return closingReasonList
        }
        return param.value
      },
      width: 200,
      wrapText: true,
    },
  ]

  return (
    <div id="leads-list">
      <ResponsiveTable rowData={leadsList} columnDefs={columns} />
    </div>
  )
}

ProjectTrackerList.propTypes = {
  leads: PropTypes.array,
}

ProjectTrackerList.defaultProps = {
  leads: [],
}

export default ProjectTrackerList

import LeadCloseForm from '../../LeadCloseForm'
import {
  CLOSE_TRANSITION,
  CLEAR_TRANSITION,
  TRANSITION_COMPLETE,
} from '../action-types'

const defaultActionsState = {
  actionRequired: false,
  actionComponent: null,
  onActionComplete: null,
  lead: null,
}

export const transitionAction = (state = defaultActionsState, action) => {
  switch (action.type) {
    case CLOSE_TRANSITION:
      return Object.assign({}, state, {
        actionRequired: true,
        actionComponent: LeadCloseForm,
        lead: action.payload,
      })
    case TRANSITION_COMPLETE:
      return Object.assign({}, defaultActionsState, {
        lead: action.payload,
      })
    case CLEAR_TRANSITION:
      return defaultActionsState
    default:
      return state
  }
}

export default {
  transitionAction,
}

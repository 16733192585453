import React, { useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import './styles.scss'

const Grid = ({
  rowData = [],
  columnDefs,
  paginationPageSize = 25,
  searchText = '',
  domLayout = 'autoHeight',
  paginationPageSizeSelector = [25, 50, 100],
  ...rest
}) => {
  const gridRef = useRef()

  useEffect(() => {
    gridRef.current.api?.sizeColumnsToFit()
  }, [rowData])

  useEffect(() => {
    gridRef.current.api?.setGridOption('quickFilterText', searchText)
  }, [searchText])

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit()
  }

  return (
    <>
      <div
        className="responsive-table ag-theme-alpine"
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          enableCellTextSelection
          rowData={rowData}
          defaultColDef={{
            unSortIcon: true,
            suppressSizeToFit: false,
          }}
          columnDefs={columnDefs}
          domLayout={domLayout}
          overlayNoRowsTemplate="<span>There is no data to display</span>"
          pagination
          paginationPageSize={paginationPageSize}
          suppressPaginationPanel={rowData.length <= paginationPageSize}
          onFirstDataRendered={onFirstDataRendered}
          paginationPageSizeSelector={paginationPageSizeSelector}
          {...rest}
        />
      </div>
    </>
  )
}

export default Grid

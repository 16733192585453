import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'

class DeleteBox extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if (this.props.enabled) {
      this.props.onDeleteAssignment().then(() => {
        this.props.onClick(true)
      })
    } else {
      this.props.onClick(false)
    }
  }

  render() {
    return (
      <MdClose className="lead-assignment-delete-box" onClick={this.onClick} />
    )
  }
}

DeleteBox.Proptypes = {
  onDeleteAssignment: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

export default DeleteBox

export default (value) => {
  if (value) {
    // Pull out the leading digit if it === 1 ie. 1-800-123-4567
    const leadingDigit = value.indexOf('1') === 0 ? '1' : null

    const phone = leadingDigit ? value.slice(1, value.length) : value

    const len = phone.length

    // Isolate a 10 digit phone number
    const phonePart = len > 10 ? phone.slice(0, 10) : phone

    // Isolate an extension if the phone number > 10 digits
    const extPart = len > 10 ? phone.slice(10, len) : null

    // format a 10 digit phone number (123) 456-7890
    const formattedNumber =
      phonePart.length === 10
        ? `(${phonePart.substring(0, 3)}) ${phonePart.substring(
            3,
            6,
          )}-${phonePart.substring(6, 10)}`
        : phonePart

    return !extPart
      ? `${leadingDigit || ''} ${formattedNumber}`
      : `${leadingDigit || ''} ${formattedNumber} ext. ${extPart}`
  }
  return value
}

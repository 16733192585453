import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './styles.scss'

const Banner = ({ children }) => {
  return (
    <header id="banner">
      <div className="logo">
        <Link to="/">
          <img
            className="desktop hide-mobile"
            title="Westmount CRM"
            src="/images/logo.png"
            width="175"
            height="26"
          />
          <img
            className="mobile hide-desktop"
            title="Westmount CRM"
            src="/images/mobile-logo.png"
            width="20"
            height="20"
          />
        </Link>
      </div>
      {children}
    </header>
  )
}

Banner.propTypes = {
  children: PropTypes.node,
}

export default Banner

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'rc-tooltip'
import { Link } from 'react-router-dom'
import { MdArrowDropDown } from 'react-icons/md'
import withRouter from '../../../core/withRouter'
import { destroySessionKey } from '../../../../common/local-storage'
import './styles.scss'

import UserAvatar from '../../../core/components/UserAvatar'

class CurrentUserControls extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tooltipVisible: false,
    }

    this.hideTooltip = this.hideTooltip.bind(this)
    this.toggleTooltip = this.toggleTooltip.bind(this)
    this.renderUserActions = this.renderUserActions.bind(this)
    this.logout = this.logout.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.hideTooltip, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.hideTooltip, false)
  }

  hideTooltip() {
    this.setState({ tooltipVisible: false })
  }

  toggleTooltip() {
    this.setState({ tooltipVisible: !this.state.tooltipVisible })
  }

  logout() {
    destroySessionKey()
    this.props.router.navigate('/auth')
  }

  renderUserActions() {
    return (
      <ul className="current-user-controls-actions">
        <li>
          <Link to="/users/profile" onClick={this.hideTooltip}>
            Profile Settings
          </Link>
        </li>
        <li>
          <Link to="#" onClick={this.logout}>
            Logout
          </Link>
        </li>
      </ul>
    )
  }

  render() {
    const { user } = this.props

    if (!user.id) return null

    return (
      <Tooltip
        placement="bottomRight"
        trigger="[click]"
        align={{
          offset: [-4, -16],
        }}
        showArrow={false}
        visible={this.state.tooltipVisible}
        onClick={this.toggleTooltip}
        overlay={this.renderUserActions}
        overlayClassName="current-user-controls-overlay">
        <div id="current-user-controls">
          <div className="current-user-controls-wrapper">
            <UserAvatar user={user} size="medium" />
            <div className="user-name hide-mobile">{user.name}</div>
            <MdArrowDropDown className="down-arrow hide-mobile" />
          </div>
        </div>
      </Tooltip>
    )
  }
}

CurrentUserControls.propTypes = {
  user: PropTypes.object.isRequired,
}

export default withRouter(CurrentUserControls)

import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import routes from './routes'
import reducers from './modules/core/reducers'
import client from './common/graphql-api'

import 'react-datepicker/dist/react-datepicker.css'
import './skeleton-overrides.scss'
import './global-styles.scss'
import './tooltip.scss'

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))
const router = createBrowserRouter(routes)
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ApolloProvider>,
)

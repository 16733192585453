import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import LeadDetailsActivity from './components/LeadDetailsActivity'
import { aggregate } from '../../common/activities'

const LeadActivitiesQuery = gql`
  query LeadActivities($leadId: ID!) {
    activities(leadId: $leadId) {
      id
      createdAt
      user {
        id
        name
        email
        avatarFilename
      }
      type
      lead {
        closingOutcome
        closingReasons
        closedAt
        closingComment
      }
      diff
      isMultiDiff
      targets {
        __typename
        ... on Goal {
          id
          leadId
          deadline
          description
          complete
        }
        ... on Comment {
          id
          content
        }
        ... on LeadAssignment {
          user {
            id
            name
          }
        }
        ... on Lead {
          priority
          status
          projectName
          developerName
          wgnetClientName
          clientType
          salesStatus
          constructionStatus
          zoningDetail
          address
          developerRating
          units
          percentageSold
          salesStartedAt
          source
        }
      }
    }
  }
`

const ComponentWithData = graphql(LeadActivitiesQuery, {
  props: ({ data: { activities } }) => {
    return !activities ? null : { activities: aggregate(activities) }
  },
  options: (props) => ({
    variables: { leadId: props.id },
    forceFetch: true,
  }),
})(LeadDetailsActivity)

export default ComponentWithData

import React, { Component } from 'react'
import './styles.scss'

class UnitRange extends Component {
  constructor(props) {
    super(props)
    this.onMinUnitsChanged = this.onMinUnitsChanged.bind(this)
    this.onMaxUnitsChanged = this.onMaxUnitsChanged.bind(this)
  }

  onMinUnitsChanged(event) {
    let { value } = event.target
    value = value ? parseInt(value, 10) : null
    this.props.onChange({ minUnits: value })
  }

  onMaxUnitsChanged(event) {
    let { value } = event.target
    value = value ? parseInt(value, 10) : null
    this.props.onChange({ maxUnits: value })
  }

  render() {
    const { visible, lowestUnitCount, highestUnitCount, minUnits, maxUnits } =
      this.props

    if (!visible) return null

    return (
      <div id="unit-range-filter" className="filter columns two">
        <div className="label">No. of Units</div>
        <div className="value">
          <input
            type="number"
            placeholder="Min"
            min={lowestUnitCount}
            max={highestUnitCount}
            value={minUnits !== null ? minUnits : ''}
            onChange={this.onMinUnitsChanged}
          />
          <input
            type="number"
            placeholder="Max"
            min={lowestUnitCount}
            max={highestUnitCount}
            value={maxUnits !== null ? maxUnits : ''}
            onChange={this.onMaxUnitsChanged}
          />
        </div>
      </div>
    )
  }
}

export default UnitRange

import { connect } from 'react-redux'
import { syncContacts } from './actions'
import ContactsSyncButton from './components/ContactsSyncButton'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    syncContacts: () => dispatch(syncContacts()),
  }
}
const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactsSyncButton)

export default connector

import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import GoalList from './GoalList'
import ActivityCard from '../../core/components/ActivityCard'

const ActivitiesList = ({
  missedGoals,
  upcomingGoals,
  needGoals,
  activities,
}) => {
  const activityList = activities.map((activity) => (
    <ActivityCard key={activity.id} activity={activity} showLeadInfo />
  ))

  return (
    <div id="activities">
      <GoalList title="Missed Goals" className="missed" goals={missedGoals} />
      <GoalList
        title="Upcoming Goals"
        goals={upcomingGoals}
        goalsNeeded={needGoals}
      />
      <div className="section">
        <p className="title">Recent Activity</p>
        {activityList}
      </div>
    </div>
  )
}

ActivitiesList.propTypes = {
  missedGoals: PropTypes.array,
  upcomingGoals: PropTypes.array,
  needGoals: PropTypes.array,
  activities: PropTypes.array,
}

ActivitiesList.defaultProps = {
  missedGoals: [],
  upcomingGoals: [],
  needGoals: [],
  activities: [],
}

export default ActivitiesList

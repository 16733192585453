import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import EnumField from '../../../core/components/EnumField'
import DateField from '../../../core/components/DateField'
import TextareaField from '../../../core/components/TextareaField'
import {
  ClosingOutcomeTypes,
  ClosingReasonTypes,
} from '../../../../common/enumerations'

class CloseLeadForm extends Component {
  constructor(props) {
    super(props)

    const { lead } = props

    this.state = lead

    this.updateState = this.updateState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onComplete = this.onComplete.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.formValid = this.formValid.bind(this)
  }

  updateState(key, value) {
    const state = this.state
    this.setState({
      ...state,
      [key]: value,
    })
  }

  onSubmit() {
    if (this.formValid()) {
      this.props.onSubmitForm(this.state).then(this.onComplete)
    }
  }

  onComplete() {
    const { onComplete, lead } = this.props
    onComplete({
      ...lead,
      ...this.state,
    })
  }

  onCancel() {
    const { onCancel, lead } = this.props
    onCancel({
      ...lead,
      ...this.state,
    })
  }

  formValid() {
    const { closingOutcome, closingReasons, closedAt } = this.state

    if (!closingOutcome) return false
    if (closingOutcome !== 'WON' && !closingReasons) return false
    if (!closedAt) return false

    return true
  }

  render() {
    const { closingOutcome, closingReasons, closedAt, closingComment } =
      this.state

    return (
      <div id="close-lead-form">
        <h5>Close Lead</h5>
        <EnumField
          keepEditorOpen
          label="Outcome"
          value={closingOutcome}
          enumerations={ClosingOutcomeTypes}
          onChange={(value) => this.updateState('closingOutcome', value)}
        />
        <EnumField
          keepEditorOpen
          disabled={closingOutcome === 'WON'}
          clearable
          label="Reason"
          value={closingReasons}
          enumerations={ClosingReasonTypes}
          multi
          onChange={(values) => {
            const value = values.map(({ value }) => value).join(',')
            this.updateState('closingReasons', value)
          }}
        />
        <DateField
          keepEditorOpen
          label="Close Date"
          value={closedAt}
          maxDate={new Date()}
          onChange={(value) => this.updateState('closedAt', value)}
        />
        <TextareaField
          keepEditorOpen
          placeholderText="Leave a comment..."
          value={closingComment}
          onChange={(value) => this.updateState('closingComment', value)}
        />
        <div>
          <span
            className={`btn primary ${this.formValid() ? '' : 'disabled'}`}
            onClick={this.onSubmit}>
            Close
          </span>
          <span className="btn" onClick={this.onCancel}>
            Cancel
          </span>
        </div>
      </div>
    )
  }
}

CloseLeadForm.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.string.isRequired,
    closingOutcome: PropTypes.string,
    closingReasons: PropTypes.string,
    closedAt: PropTypes.string,
    closingComment: PropTypes.string,
  }).isRequired,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
}

CloseLeadForm.defaultProps = {
  onComplete: () => {},
  onCancel: () => {},
}

export default CloseLeadForm

import React from 'react'
import PropTypes from 'prop-types'
import GoalCard from '../../../core/components/GoalCard'
import GoalNeededCard from '../../../core/components/GoalNeededCard'

const GoalList = ({ title, className, goals, goalNeeded }) => {
  if (goals.length === 0 && !goalNeeded) return null

  const goalNeededCard = goalNeeded ? <GoalNeededCard /> : null
  const goalList = goals.map((goal) => <GoalCard key={goal.id} {...goal} />)

  return (
    <div className={`section ${className}`}>
      <p className="title">{title}</p>
      {goalNeededCard}
      {goalList}
    </div>
  )
}

GoalList.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  goals: PropTypes.array.isRequired,
  goalNeeded: PropTypes.bool,
}

export default GoalList

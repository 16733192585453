import axios from 'axios'

export const recoverPassword = ({ email }) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${import.meta.env.REACT_APP_API_URL}/authentication/recovery`,
      data: { email },
      config: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    })
  }
}

import moment from 'moment'
import { ProjectDataObj, ProjectSummaryObj, AssumptionsMatrix } from './types'
import { toLabel, toClientTypeLabel } from './labels'

export const toProjectForecastObj = (reportRow: any): ProjectDataObj => {
  return {
    wgnetClientName: reportRow.wgnetClientName,
    projectName: reportRow.projectName,
    units: reportRow.units,
    salesStartedAt: reportRow.salesStartedAt
      ? moment(reportRow.salesStartedAt).format('YYYY-MM-DD')
      : '',
    salesStatus: toLabel(reportRow.salesStatus),
    constructionStatus: toLabel(reportRow.constructionStatus),
    clientType: toClientTypeLabel(reportRow.clientType),
    tarionBond: reportRow.tarionBond,
    potentialDiFacility: reportRow.potentialDiFacility,
    averageUnitPrice: reportRow.averageUnitPrice,
    averageRevenues: reportRow.averageRevenues,
  }
}

export const toProjectSummaryObj = (
  segment: ProjectDataObj[],
  assumptionsMatrix: AssumptionsMatrix,
): ProjectSummaryObj => {
  const {
    unitsComingToMarketRate,
    unitsWGMarketShare,
    tarionDepositProtectionAmt,
    projectForecast: { averagePricePerUnit },
    depositRate,
  } = assumptionsMatrix
  const salesStatus = segment[0].salesStatus || ''
  const clientType = segment[0].clientType || ''

  const totalUnits = segment.reduce((total, obj) => total + obj.units, 0)
  const totalTarionBond = segment.reduce(
    (total, obj) => total + obj.tarionBond,
    0,
  )
  const totalPotentialDiFacility = segment.reduce(
    (total, obj) => total + obj.potentialDiFacility,
    0,
  )
  const assumedUnitsComingToMarket = unitsComingToMarketRate.value * totalUnits
  const assumedUnitsMarketShare =
    unitsWGMarketShare.value * assumedUnitsComingToMarket
  const wgTarionBond =
    tarionDepositProtectionAmt.value * assumedUnitsMarketShare
  const wgDiFacility =
    averagePricePerUnit.value * assumedUnitsComingToMarket * depositRate.value -
    wgTarionBond

  const summaryObj = {
    clientType,
    salesStatus,
    units: totalUnits,
    numberOfProjects: segment.length,
    tarionBond: totalTarionBond,
    potentialDiFacility: totalPotentialDiFacility,
    totalFacilities: totalTarionBond + totalPotentialDiFacility,
    unitsComingToMarket: assumedUnitsComingToMarket,
    unitsMarketShare: assumedUnitsMarketShare,
    wgTarionBond,
    wgDiFacility,
    wgTotalFacilities: wgTarionBond + wgDiFacility,
  }

  return summaryObj
}

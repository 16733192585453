import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import DashboardLeads from './components/DashboardLeads'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const DashboardLeadsList = gql`
  query DashboardLeadsList($assignedUserId: ID) {
    leads(assignedUserId: $assignedUserId) {
      id
      priority
      status
      projectName
      developerName
      units
      percentageSold
      updatedAt
    }
  }
`

const ComponentWithData = graphql(DashboardLeadsList, {
  skip: ({ user }) => !user.id,
  options: ({ user }) => ({
    variables: { assignedUserId: user.id },
    forceFetch: true,
  }),
  props: ({ data: { leads } }) => {
    return leads
      ? { leads: leads.filter(({ status }) => status !== 'CLOSED') }
      : null
  },
})(DashboardLeads)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector

import { connect } from 'react-redux'

import PasswordRecovery from './components/PasswordRecovery'
import { recoverPassword } from './actions'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(recoverPassword(data)),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery)

export default connector

import React, { Component } from 'react'

import StringField from '../../../core/components/StringField'

class PasswordRecoveryForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
    }

    this.updateState = this.updateState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
  }

  onSubmit() {
    if (this.state.email) {
      this.props.onSubmit(this.state)
    }
  }

  render() {
    const { email } = this.state
    return (
      <div id="password-recovery-form" className="login-form panel">
        <div className="row">
          <div className="columns twelve">
            <p>
              Having trouble signing in? To reset your password, enter your
              Westmount Leads username
            </p>
            <StringField
              editable
              nullable
              keepEditorOpen
              type="email"
              label="Username"
              placeholder="example@westmount.ca"
              value={email}
              onChange={(value) => this.updateState('email', value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="columns six">
            <span className="btn primary full-width" onClick={this.onSubmit}>
              Request Reset
            </span>
          </div>
          <div className="columns six">
            <span className="btn full-width" onClick={this.props.onCancel}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default PasswordRecoveryForm

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import './styles.scss'

import Label from '../Label'

class ToggleField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: false,
    }

    this.onChange = this.onChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props
    this.setState({ value })
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.state.value) {
      this.setState({ value })
    }
  }

  onChange(event) {
    const newValue = event.target.checked
    const { value } = this.state
    this.setState(
      {
        value: newValue,
      },
      () => {
        if (newValue !== value) {
          this.props.onChange(newValue)
        }
      },
    )
  }

  render() {
    const { label, editable, className } = this.props

    const { value } = this.state

    const classNames = [editable ? 'editable' : null, className].join(' ')

    const editProps = {
      className: `input ${classNames}`,
      checked: value,
      disabled: !editable,
      icons: false,
      onChange: this.onChange,
    }

    return (
      <div className="field toggle">
        <Label text={label} />
        <Toggle {...editProps} />
      </div>
    )
  }
}

ToggleField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  editable: PropTypes.bool,
}

export default ToggleField

import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import ActivityCard from '../../../core/components/ActivityCard'

const LeadDetailsActivity = ({ activities }) => {
  if (activities.length === 0) return null

  const activityList = activities.map((activity) => (
    <ActivityCard key={activity.id} activity={activity} />
  ))

  return (
    <div id="lead-activity">
      <div className="section">
        <p className="title">Recent Activity</p>
        {activityList}
      </div>
    </div>
  )
}

LeadDetailsActivity.propTypes = {
  activities: PropTypes.array.isRequired,
}

LeadDetailsActivity.defaultProps = {
  activities: [],
}

export default LeadDetailsActivity

import React from 'react'
import PropTypes from 'prop-types'
import { MdFindReplace } from 'react-icons/md'
import './styles.scss'

const EmptyMessage = ({ visible }) => {
  if (!visible) return null

  return (
    <div id="empty-message" className="panel">
      <MdFindReplace size={100} />
      <h4>No leads found</h4>
      <p>Try another search</p>
    </div>
  )
}

EmptyMessage.protypes = {
  visible: PropTypes.bool.isRequired,
}

EmptyMessage.defaultProps = {
  visible: false,
}

export default EmptyMessage

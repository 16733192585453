import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

class DeveloperName extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ developerName: selection })
  }

  render() {
    const { visible, developerNames, developerName } = this.props

    if (!visible) return null

    const options = developerNames.sort().map((developerName) => ({
      value: developerName,
      label: developerName ? (
        developerName
      ) : (
        <strong>
          <em>Not Set</em>
        </strong>
      ),
    }))

    return (
      <div id="developer-name-filter" className="filter columns two">
        <div className="label">Client</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={developerName}
            onChange={this.onChange}
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default DeveloperName

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropdownSelect } from '../DropdownSelect'
import './styles.scss'

import Label from '../Label'
import Value from '../Value'

class EnumField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      value: null,
    }

    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { value, enumerations, nullable } = this.props

    const options = Object.keys(enumerations).map((e) => ({
      value: e,
      label: enumerations[e],
    }))

    if (nullable) {
      options.splice(0, 0, { value: null, label: 'Unknown' })
    }

    this.setState({ value, options })
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.state.value) {
      this.setState({ value })
    }
  }

  onClick() {
    if (!this.props.editable) {
      return
    }

    this.setState({ editing: true })
  }

  onChange(selection) {
    const value = selection
      ? this.props.multi
        ? selection
        : selection.value
      : null
    this.setState({ value }, () => {
      this.props.onChange(value)
    })
  }

  onBlur(value) {
    this.setState({ editing: false })
  }

  render() {
    const {
      label,
      enumerations,
      editable,
      keepEditorOpen,
      clearable,
      className,
      disabled,
      multi,
    } = this.props

    const { editing, options, value } = this.state

    const classNames = [
      editable ? 'editable' : null,
      value ? null : 'invalid',
      className,
    ].join(' ')

    const editProps = {
      className: `input ${className}`,
      options: options,
      value: options.find((option) => option.value === value),
      autofocus: !keepEditorOpen,
      maxMenuHeight: 150,
      isClearable: !!clearable,
      formatOptionLabel: this.props.renderer,
      onChange: this.onChange,
      onMenuClose: this.onBlur,
      disabled,
      isMulti: multi,
    }

    const valueProps = {
      className: classNames,
      value: enumerations[value] || 'Unknown',
      onClick: this.onClick,
    }

    const showEditor = editing || keepEditorOpen

    return (
      <div className="field enum">
        <Label text={label} />
        {(showEditor && <DropdownSelect {...editProps} />) || (
          <Value {...valueProps} />
        )}
      </div>
    )
  }
}

EnumField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  enumerations: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  nullable: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  onChange: (props, propName, componentName) => {
    if (props.editable) {
      if (!props[propName]) {
        return new Error(
          `'${propName}' prop is required for an editable ${componentName}.`,
        )
      }
      if (typeof props[propName] !== 'function') {
        return new Error(
          `Invalid prop ${propName} of type '${typeof props[
            propName
          ]}' passed to ${componentName}. Expecting a function.`,
        )
      }
    }
  },
}

export default EnumField

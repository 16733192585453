import React from 'react'
import { useDrop } from 'react-dnd'
import './styles.scss'

import Lead, { LeadType } from '../Lead'

const StatusColumn = (props) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: LeadType,
    canDrop: (item) => {
      if (item.status === props.status) return false

      return props.rules.every((rule) => rule(item))
    },
    drop: (item) =>
      props.onDrop({
        id: item.id,
        status: props.status,
      }),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  }))

  const onHeaderClicked = () => {
    props.onHeaderClick(props.status)
  }

  let componentClass = `two columns statusColumn ${props.status}`

  if (isOver && canDrop) {
    componentClass += ' over'
  }

  const leadCount = props.leads.length
  const leadCountText = `${leadCount} ` + (leadCount === 1 ? 'Lead' : 'Leads')

  return (
    <div ref={drop} className={componentClass}>
      <div className="columnInfo" onClick={onHeaderClicked}>
        <p>{props.label}</p>
        <span>{leadCountText}</span>
      </div>
      <div className="list">
        {props.leads.map((lead) => (
          <Lead key={lead.id} {...lead} />
        ))}
      </div>
    </div>
  )
}

export default StatusColumn

import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

import { LeadPriorityTypes } from '../../../../common/enumerations'

class PriorityType extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ priorityType: selection })
  }

  render() {
    const { visible, priorityType } = this.props

    if (!visible) return null

    const priorityTypes = Object.keys(LeadPriorityTypes)
    const options = priorityTypes.map((priorityType) => ({
      value: priorityType,
      label: (
        <span className={priorityType.toLowerCase()}>
          {LeadPriorityTypes[priorityType]}
        </span>
      ),
    }))

    return (
      <div id="priority-type-filter" className="filter columns two">
        <div className="label">Priority</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={priorityType}
            onChange={this.onChange}
            placeholder="All"
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default PriorityType

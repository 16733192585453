import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import DeleteBox from './components/DeleteBox'

const DeleteLeadAssignment = gql`
  mutation DeleteLeadAssignment($leadId: Int!, $userId: Int!) {
    deleteLeadAssignment(leadId: $leadId, userId: $userId) {
      id
    }
  }
`

const ComponentWithDeleteLeadAssignmentMutation = graphql(
  DeleteLeadAssignment,
  {
    props: ({ ownProps: { leadId, userId }, mutate }) => ({
      onDeleteAssignment: () =>
        mutate({
          variables: { leadId, userId },
          refetchQueries: ['Lead', 'LeadActivities'],
        }),
    }),
  },
)(DeleteBox)

export default ComponentWithDeleteLeadAssignmentMutation

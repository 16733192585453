import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { getSessionKey } from '../../../../common/local-storage'
import withRouter from '../../withRouter'

import RequireAuthComp from './RequireAuthComp'

const mapState = () => {
  const sessionKey = getSessionKey()

  return {
    user: !sessionKey ? null : jwtDecode(sessionKey),
  }
}

export default withRouter(connect(mapState)(RequireAuthComp))

import { type AssumptionsMatrix } from './types'

// ¯\_(ツ)_/¯
// Likely future UX input params. Using latest (FY23-24) or average values in the report samples for now as there is no automatic source for these data.
export const assumptionsMatrix: AssumptionsMatrix = {
  // common items
  depositRate: {
    value: 0.2,
    label: 'Deposit Rate',
    cellRef: 'Coversheet!$D$10',
  },
  unitsComingToMarketRate: {
    value: 0.8,
    label: 'Units Coming to Market Rate',
    cellRef: 'Coversheet!$D$11',
  },
  unitsWGMarketShare: {
    value: 0.54,
    label: 'Units WG Market Share',
    cellRef: 'Coversheet!$D$12',
  },
  tarionDepositProtectionAmt: {
    value: 20000,
    label: 'Tarion Deposit Protection Amount',
    cellRef: 'Coversheet!$D$13',
  },

  projectForecast: {
    averagePricePerUnit: {
      value: 726664,
      label: 'Project Forecast - Average Price Per Unit',
      cellRef: 'Coversheet!$D$14',
    },
  },

  toHumanReadableFlatTable: function () {
    const result: Record<string, number> = {}

    const processObject = (obj: any) => {
      for (const key in obj) {
        if ('value' in obj[key]) {
          result[`${obj[key].label}`] = obj[key].value
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          processObject(obj[key])
        }
      }
    }

    processObject(this)
    return result
  },
}

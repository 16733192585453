import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import Label from '../Label'
import Value from '../Value'

class StringField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      value: null,
    }

    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props
    this.setState({ value })
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.state.value) {
      this.setState({ value })
    }
  }

  onClick() {
    if (!this.props.editable) {
      return
    }

    this.setState({ editing: true })
  }

  onChange(event) {
    const { value } = event.target
    this.setState({ value: value || null })
  }

  onBlur() {
    let newValue = this.state.value
      ? this.state.value.trim()
        ? this.state.value.trim()
        : null
      : null
    const prevValue = this.props.value

    if (!newValue && !this.props.nullable) {
      newValue = prevValue
      this.setState({ value: newValue })
    }

    this.setState(
      {
        editing: false,
        value: newValue,
      },
      () => {
        if (newValue !== prevValue) {
          this.props.onChange(newValue)
        }
      },
    )
  }

  onKeyPress({ key }) {
    if (key === 'Enter') {
      this.onBlur()
    }
  }

  render() {
    const {
      id,
      type,
      label,
      editable,
      keepEditorOpen,
      className,
      placeholder,
    } = this.props

    const { editing, value } = this.state

    const classNames = [
      editable ? 'editable' : null,
      !value ? 'invalid' : null,
      className,
    ].join(' ')

    const editProps = {
      id,
      type,
      className: `input ${className}`,
      autoFocus: !keepEditorOpen,
      value: value || '',
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      onBlur: this.onBlur,
      placeholder,
    }

    const valueProps = {
      id,
      className: classNames,
      value: value || 'Unknown',
      onClick: this.onClick,
    }

    const showEditor = editing || keepEditorOpen

    return (
      <div className="field string">
        <Label text={label} />
        {(showEditor && <input {...editProps} />) || <Value {...valueProps} />}
      </div>
    )
  }
}

StringField.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'password']).isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  editable: PropTypes.bool,
  nullable: PropTypes.bool,
}

StringField.defaultProps = {
  type: 'text',
}

export default StringField

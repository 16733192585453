import { graphql, withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import Moment from 'moment'

import LeadDetailsHeader from './components/LeadDetailsHeader'

const LeadQuery = gql`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      projectName
      status
      address
      developerName
      developerRating
      units
      percentageSold
      closingOutcome
      wgnetClientId
      wgnetClientName
      clientType
      createdAt
    }
    activities(leadId: $id) {
      createdAt
      type
      targets {
        ... on Lead {
          status
        }
      }
    }
  }
`

const DeveloperListQuery = gql`
  query DeveloperList {
    developerList
  }
`

const LeadUpdateMutation = gql`
  mutation UpdateLead(
    $id: ID!
    $projectName: String!
    $address: String
    $developerName: String
    $clientType: ClientType
    $developerRating: String
    $units: Int
    $percentageSold: Float
  ) {
    updateLead(
      id: $id
      projectName: $projectName
      address: $address
      developerName: $developerName
      clientType: $clientType
      developerRating: $developerRating
      units: $units
      percentageSold: $percentageSold
    ) {
      id
      projectName
      address
      developerName
      clientType
      developerRating
      units
      percentageSold
    }
  }
`

const LeadUpdateFromWGNetClientMutation = gql`
  mutation UpdateLeadFromWGNetClientMutation(
    $id: ID!
    $wgnetClientId: ID
    $wgnetClientName: String
    $clientType: ClientType
    $developerRating: String
    $ownerId: Int
  ) {
    updateLeadFromWGNetClient(
      id: $id
      wgnetClientId: $wgnetClientId
      wgnetClientName: $wgnetClientName
      clientType: $clientType
      developerRating: $developerRating
      ownerId: $ownerId
    ) {
      id
      wgnetClientId
      wgnetClientName
      clientType
      developerRating
    }
  }
`

const ComponentWithData = graphql(LeadQuery, {
  props: ({ data: { lead, activities } }) => {
    if (!lead) return null
    const statusDates = activities
      .filter(({ type }) => {
        const validStatuses = [
          'LEAD_STATUS_CHANGED',
          'LEAD_CLOSED',
          'LEAD_ADDED',
        ]
        return validStatuses.indexOf(type) !== -1
      })
      .map(({ createdAt, targets }) => ({
        createdAt: Moment(createdAt).format('DD/MM/YYYY'),
        status: targets[0].status,
      }))
    return {
      lead,
      statusDates,
    }
  },
  options: (props) => ({
    variables: { id: props.id },
    forceFetch: true,
  }),
})(LeadDetailsHeader)

const ComponentWithDeveloperList = graphql(DeveloperListQuery, {
  props: ({ data: { developerList } }) => ({ developerList }),
})(ComponentWithData)

const ComponentWithLeadUpdateMutation = graphql(LeadUpdateMutation, {
  props: ({ mutate }) => ({
    onUpdateLead: ({
      id,
      projectName,
      status,
      address,
      developerName,
      developerRating,
      units,
      percentageSold,
      wgnetClientId,
      wgnetClientName,
      clientType,
    }) =>
      mutate({
        variables: {
          id,
          projectName,
          status,
          address,
          developerName,
          developerRating,
          units,
          percentageSold,
          wgnetClientId,
          wgnetClientName,
          clientType,
        },
        refetchQueries: ['Lead', 'DeveloperList', 'LeadActivities'],
      }),
  }),
})(ComponentWithDeveloperList)

const ComponentWithLeadUpdateFromWGNetClientMutation = graphql(
  LeadUpdateFromWGNetClientMutation,
  {
    props: ({ mutate }) => ({
      onUpdateLeadFromWGNetClient: ({
        id,
        developerRating,
        wgnetClientId,
        wgnetClientName,
        clientType,
        ownerId,
      }) =>
        mutate({
          variables: {
            id,
            developerRating,
            wgnetClientId,
            wgnetClientName,
            clientType,
            ownerId,
          },
          refetchQueries: ['Lead', 'LeadActivities'],
        }),
    }),
  },
)(ComponentWithLeadUpdateMutation)

const componentWithApollo = withApollo(
  ComponentWithLeadUpdateFromWGNetClientMutation,
)

export default componentWithApollo

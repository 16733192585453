import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import Graph from '../Graph'

const ProjectTrackerGraphs = (props) => {
  return (
    <div id="sales-performance-graphs">
      <div className="columns three won">
        <Graph data={props.won} className="won" />
      </div>
      <div className="columns three lost">
        <Graph data={props.lost} className="lost" />
      </div>
      <div className="columns three rejected">
        <Graph data={props.rejected} className="rejected" />
      </div>
      <div className="columns three">
        <Graph data={props.closed} className="closed" />
      </div>
    </div>
  )
}

ProjectTrackerGraphs.propTypes = {
  won: PropTypes.array,
  lost: PropTypes.array,
  rejected: PropTypes.array,
  closed: PropTypes.array,
}

ProjectTrackerGraphs.defaultProps = {
  won: [],
  lost: [],
  rejected: [],
  closed: [],
}

export default ProjectTrackerGraphs

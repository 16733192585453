import React from 'react'
import { useParams } from 'react-router-dom'
import LeadDetailsHeader from '../../../LeadDetailsHeader'
import ProjectDetailsPanel from '../../../ProjectDetailsPanel'
import LeadDetailsInteractions from '../../../LeadDetailsInteractions'
import LeadLinks from '../../../LeadLinks'
import LeadDetailsActivity from '../../../LeadDetailsActivity'
import LeadDetailsGoals from '../../../LeadDetailsGoals'
import LeadResourceLinks from '../../../LeadResourceLinks'

const LeadDetails = () => {
  const params = useParams()
  const id = parseInt(params.id, 10)

  return (
    <div id="lead-details">
      <div className="section-header">
        <div className="container">
          <LeadDetailsHeader id={id} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="five columns">
            <ProjectDetailsPanel id={id} />
            <LeadResourceLinks id={id} />
            <LeadLinks id={id} />
          </div>
          <div className="seven columns">
            <LeadDetailsInteractions id={id} />
            <LeadDetailsGoals id={id} />
            <LeadDetailsActivity id={id} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeadDetails

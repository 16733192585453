import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import ToggleField from '../../../core/components/ToggleField'

class UserProfileNotificationSettings extends Component {
  constructor(props) {
    super(props)
    const { user } = this.props
    this.state = user

    this.updateState = this.updateState.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user } = nextProps
    this.setState({ ...user })
  }

  updateState(key, value) {
    const state = this.state
    state[key] = value
    this.setState(state)
    this.props.updateNotificationSettings(state)
  }

  render() {
    const { weeklyNotification, deadlineNotification, commentNotification } =
      this.state

    return (
      <div id="user-profile-notification-settings">
        <div className="row">
          <div className="columns twelve">
            <h5>Email Notifications</h5>
            <ToggleField
              editable
              value={weeklyNotification}
              label="Weekly Summary"
              onChange={(value) =>
                this.updateState('weeklyNotification', value)
              }
            />
            <p>Get a summary of your upcoming goals and recent activity</p>
          </div>
        </div>
        <div className="row">
          <div className="columns twelve">
            <ToggleField
              editable
              value={deadlineNotification}
              label="Upcoming Goals"
              onChange={(value) =>
                this.updateState('deadlineNotification', value)
              }
            />
            <p>Get a reminder for upcoming goals to keep you on track</p>
          </div>
        </div>
        <div className="row">
          <div className="columns twelve">
            <ToggleField
              editable
              value={commentNotification}
              label="New Comment Added"
              onChange={(value) =>
                this.updateState('commentNotification', value)
              }
            />
            <p>
              Get notified when comments are added to one of your assigned leads
            </p>
          </div>
        </div>
      </div>
    )
  }
}

UserProfileNotificationSettings.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserProfileNotificationSettings

import React from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'
import './styles.scss'

const Messages = ({ messages, dismiss }) => {
  const messageList = messages.map(({ type, content, timestamp }) => {
    window.setTimeout(() => {
      dismiss(timestamp)
    }, 10000)

    return (
      <div key={timestamp} className={`message ${type}`}>
        {content}
        <MdClose
          className="close"
          size={20}
          onClick={() => dismiss(timestamp)}
        />
      </div>
    )
  })

  return (
    <div id="messages">
      <div className="container">
        <div className="row">
          <div className="columns twelve">{messageList}</div>
        </div>
      </div>
    </div>
  )
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      timestamp: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

Messages.defaultProps = {
  messages: [],
}

export default Messages

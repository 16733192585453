import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

import { ClientTypes } from '../../../../common/enumerations'

class ClientType extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ clientType: selection })
  }

  render() {
    const { visible, clientType } = this.props

    if (!visible) return null

    const clientTypes = Object.keys(ClientTypes)
    const options = clientTypes.sort().map((clientType) => ({
      value: clientType,
      label: ClientTypes[clientType],
    }))

    return (
      <div id="client-type-filter" className="filter columns two">
        <div className="label">Client Type</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={clientType}
            onChange={this.onChange}
            placeholder="All"
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default ClientType

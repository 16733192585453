import React, { Component } from 'react'
import { MdFileUpload } from 'react-icons/md'
import './styles.scss'

class LeadsUploadButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  handleFileUpload(e) {
    const { loading } = this.state
    if (loading) return

    this.setState({ loading: true })
    const file = e.target.files[0]
    this.props
      .uploadBuzzBuzzHomeCsv({
        file,
        name: file.name,
      })
      .then(() => {
        this.refs.file.value = null
        this.setState({ loading: false })
        this.props.onComplete()
      })
  }

  render() {
    const { user } = this.props
    const { loading } = this.state

    const icon = loading ? (
      <span className="Select-loading" />
    ) : (
      <MdFileUpload />
    )
    if (!user.admin) return null

    return (
      <span className="upload-btn">
        <input
          id="file"
          type="file"
          accept=".csv"
          ref="file"
          onChange={this.handleFileUpload}
          className="inputfile"
        />
        <label htmlFor="file" className="btn link">
          {icon}&nbsp; Upload Leads
        </label>
      </span>
    )
  }
}

LeadsUploadButton.defaultProps = {
  onComplete: () => {},
}

export default LeadsUploadButton

import React from 'react'
import LeadsBoard from './LeadsBoard'
import LeadsList from './LeadsList'
import EmptyMessage from './EmptyMessage'

const LeadsCatalog = (props) => {
  const { listView, leads } = props

  const currentView = listView ? (
    <LeadsList {...props} />
  ) : (
    <LeadsBoard {...props} />
  )
  null

  return (
    <div id="leads-catalog">
      {currentView}
      <EmptyMessage visible={leads.length === 0} />
    </div>
  )
}

LeadsCatalog.defaultProps = {
  listView: false,
}

export default LeadsCatalog

import { connect } from 'react-redux'

import AdminProfileCreateUser from './components/AdminProfileCreateUser'
import { userCreated } from './actions'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userCreated: (user) => dispatch(userCreated(user)),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminProfileCreateUser)

export default connector

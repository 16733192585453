import React, { Component } from 'react'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import './styles.scss'

class DateRange extends Component {
  constructor(props) {
    super(props)
    this.onStartDateChanged = this.onStartDateChanged.bind(this)
    this.onEndDateChanged = this.onEndDateChanged.bind(this)

    this.state = {
      minDate: null,
      maxDate: null,
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { leads } = props
    let minDate
    let maxDate

    if (leads) {
      leads.forEach((lead) => {
        const closedAt = Moment(lead.closedAt)
        if (closedAt.isBefore(minDate) || !minDate) minDate = closedAt.toDate()
        if (closedAt.isAfter(maxDate) || !maxDate) maxDate = closedAt.toDate()
      })
    }
    this.setState({ minDate, maxDate })
  }

  onStartDateChanged(date) {
    const startDate = date ? date.startOf('day') : null
    this.props.updateFilters({ startDate })
  }

  onEndDateChanged(date) {
    const endDate = date ? date.endOf('day') : null
    this.props.updateFilters({ endDate })
  }

  render() {
    const { projectTrackerFilters } = this.props
    const { minDate, maxDate } = this.state

    if (!projectTrackerFilters.startDate)
      projectTrackerFilters.startDate = minDate
    if (!projectTrackerFilters.endDate) projectTrackerFilters.endDate = maxDate

    return (
      <div id="date-range-filter" className="filter">
        <div className="label">Date Range</div>
        <div className="value">
          <DatePicker
            selected={projectTrackerFilters.startDate}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="Start"
            onChange={this.onStartDateChanged}
          />
          <DatePicker
            selected={projectTrackerFilters.endDate}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="End"
            onChange={this.onEndDateChanged}
          />
        </div>
      </div>
    )
  }
}
export default DateRange

import axios from 'axios'
import { getSessionKey } from '../../common/local-storage'
import { ADD_MESSAGE } from '../../modules/core/action-types'

export const syncContacts = () => {
  return (dispatch) => {
    const token = getSessionKey()
    const auth = token ? `Bearer ${token}` : null
    return axios({
      method: 'post',
      url: `${import.meta.env.REACT_APP_API_URL}/ingest/synccontacts`,
      headers: {
        Authorization: auth,
      },
    })
      .then((res) => {
        dispatch({
          type: ADD_MESSAGE,
          payload: { type: 'info', content: res.data.message },
        })
      })
      .catch((error) => {
        dispatch({
          type: ADD_MESSAGE,
          payload: { type: 'error', content: error.response.data.message },
        })
      })
  }
}

import {
  AUTHENTICATED,
  PASSWORD_CHANGE_REQUIRED,
  PASSWORD_CHANGED,
  SET_USER_AVATAR,
} from '../action-types'

const defaultUserState = {
  id: null,
  name: '',
  email: '',
  admin: false,
  weeklyNotification: false,
  deadlineNotification: false,
  commentNotification: false,
  passwordChangeRequired: false,
}

export const user = (state = defaultUserState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return Object.assign({}, state, action.payload, {
        id: `${action.payload.id}`,
      })
    case PASSWORD_CHANGE_REQUIRED:
      return Object.assign({}, state, {
        passwordChangeRequired: true,
      })
    case PASSWORD_CHANGED:
      return Object.assign({}, state, {
        passwordChangeRequired: false,
      })
    case SET_USER_AVATAR:
      return Object.assign({}, state, {
        avatarFilename: action.payload,
      })
    default:
      return state
  }
}

export default {
  user,
}

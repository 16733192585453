import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import UserProfilePassword from './components/UserProfilePassword'
import { passwordChanged } from './actions'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passwordChanged: () => dispatch(passwordChanged()),
  }
}

const PasswordUpdateMutation = gql`
  mutation PasswordUpdate(
    $id: ID!
    $currentPassword: String
    $newPassword: String
  ) {
    changeUserPassword(
      id: $id
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`

const ComponentWithMutation = graphql(PasswordUpdateMutation, {
  props: ({ ownProps: { passwordChanged }, mutate }) => ({
    onPasswordUpdate: ({ id, currentPassword, newPassword }) =>
      mutate({
        variables: { id, currentPassword, newPassword },
      }).then(() => {
        passwordChanged()
      }),
  }),
})(UserProfilePassword)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithMutation)

export default connector

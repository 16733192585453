import Moment from 'moment'

import {
  ClientTypes,
  ConstructionStatusTypes,
  LeadPriorityTypes,
  LeadStatusTypes,
  SalesStatusTypes,
  SourceTypes,
  ClosingOutcomeTypes,
  ClosingReasonTypes,
} from '../enumerations'

const getFormattedDate = (value) => {
  return Moment(value).isValid()
    ? Moment(value).utc().format('MMMM D, YYYY')
    : null
}

export default {
  priority: {
    label: 'Priority',
    value: (value) => LeadPriorityTypes[value],
  },
  status: {
    label: 'Status',
    value: (value) => LeadStatusTypes[value].label,
  },
  projectName: {
    label: 'Project Name',
  },
  developerName: {
    label: 'Client',
  },
  wgnetClientId: {
    label: 'WGNet Client',
  },
  wgnetClientName: {
    label: 'WGNet Client',
  },
  clientType: {
    label: 'Client Type',
    value: (value) => ClientTypes[value],
  },
  salesStatus: {
    label: 'Sales Status',
    value: (value) => SalesStatusTypes[value],
  },
  constructionStatus: {
    label: 'Construction Status',
    value: (value) => ConstructionStatusTypes[value],
  },
  zoningDetail: {
    label: 'Zoning Detail',
  },
  address: {
    label: 'Address',
  },
  developerRating: {
    label: 'Client Rating',
  },
  units: {
    label: 'Units',
  },
  percentageSold: {
    label: '% Sold',
  },
  salesStartedAt: {
    label: 'Sales Started',
    value: (value) => getFormattedDate(value),
  },
  source: {
    label: 'Source',
    value: (value) => SourceTypes[value],
  },
  closingOutcome: {
    label: 'Closing Outcome',
    value: (value) => ClosingOutcomeTypes[value],
  },
  closingReasons: {
    label: 'Closing Reason',
    value: (value) =>
      value
        ? value
            .split(',')
            .map((closingReason) => ClosingReasonTypes[closingReason])
            .join(', ')
        : '',
  },
  closingComment: {
    label: 'Closing Comment',
  },
  closedAt: {
    label: 'Closing Date',
    value: (value) => getFormattedDate(value),
  },
  createdAt: {
    label: 'Created On',
    value: (value) => getFormattedDate(value),
  },
  updatedAt: {
    label: 'Last Updated',
    value: (value) => getFormattedDate(value),
  },
}

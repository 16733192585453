import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdInsertInvitation, MdEdit } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa'
import Moment from 'moment'
import { Link } from 'react-router-dom'
import './styles.scss'

import LeadGoalEditForm from '../../../LeadGoalEditForm'

class GoalCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
    }

    this.onEditClick = this.onEditClick.bind(this)
    this.onEditCancel = this.onEditCancel.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  onEditClick() {
    this.setState({ editing: true })
  }

  onEditCancel() {
    this.setState({ editing: false })
  }

  onSave(updatedGoal) {
    this.setState({ editing: false })
  }

  render() {
    const { editing } = this.state

    return (
      <div className="activity-card goal">
        <div className="type-icon">
          <MdInsertInvitation size={20} />
        </div>
        <div className="panel">
          {(!editing && (
            <GoalView {...this.props} onEdit={this.onEditClick} />
          )) || (
            <LeadGoalEditForm
              {...this.props}
              onComplete={this.onSave}
              onCancel={this.onEditCancel}
            />
          )}
        </div>
      </div>
    )
  }
}

GoalCard.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  deadline: PropTypes.instanceOf(Moment).isRequired,
  onComplete: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  showLeadInfo: PropTypes.bool,
  lead: PropTypes.shape({
    id: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
  }),
}

GoalCard.defaultProps = {
  editable: true,
  showLeadInfo: false,
}

const GoalView = (props) => {
  const {
    deadline,
    description,
    onEdit,
    onComplete,
    editable,
    showLeadInfo,
    lead,
  } = props

  return (
    <div>
      <div className="meta">
        <GoalLeadInfo
          showLeadInfo={showLeadInfo}
          deadline={deadline}
          lead={lead}
        />
        <div className="action-buttons">
          <span className="btn primary" onClick={onComplete}>
            <FaCheck /> Complete
          </span>
          <br />
          <GoalEditButton editable={editable} onClick={onEdit} />
        </div>
      </div>
      <div className="content">{description}</div>
      <div className="deadline">
        {Moment(deadline).utc().format('MMMM D, YYYY')}
      </div>
    </div>
  )
}

const GoalEditButton = ({ editable, onClick }) => {
  if (!editable) return null
  return (
    <span className="btn edit" onClick={onClick}>
      <MdEdit /> Edit
    </span>
  )
}

const GoalLeadInfo = ({ showLeadInfo, deadline, lead }) => {
  if (!showLeadInfo) return null

  const verb = Moment().isBefore(deadline) ? 'due in' : 'overdue by'

  return (
    <div className="lead-info">
      Goal deadline for <Link to={`/leads/${lead.id}`}>{lead.projectName}</Link>{' '}
      {verb} {Moment(deadline).fromNow(true)}
    </div>
  )
}

export default GoalCard

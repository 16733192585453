import Moment from 'moment'

const getFormattedDate = (value) => {
  return Moment(value).isValid() ? Moment(value).format('MMMM D, YYYY') : null
}

export default {
  projectName: {
    label: 'Project Name',
  },
  description: {
    label: 'Description',
  },
  deadline: {
    label: 'Deadline',
    value: (value) => getFormattedDate(value),
  },
  complete: {
    label: 'Complete',
  },
}

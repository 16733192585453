import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const [searchParams] = useSearchParams()

    return (
      <Component
        {...props}
        router={{ location, navigate, params, searchParams }}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter

import React from 'react'
import './styles.scss'

import UserProfileAvatar from '../../../UserProfileAvatar'
import UserProfileBasics from '../../../UserProfileBasics'
import UserProfilePassword from '../../../UserProfilePassword'
import AdminProfileUserList from '../../../AdminProfileUserList'
import AdminProfileCreateUser from '../../../AdminProfileCreateUser'
import UserProfileNotificationSettings from '../../../UserProfileNotificationSettings'

const ProfileSettings = () => {
  return (
    <div id="user-profile">
      <div className="section-header">
        <div className="container">
          <h3>Profile Settings</h3>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="columns three">
            <UserProfileAvatar />
          </div>
          <div className="columns five">
            <UserProfileBasics />
            <UserProfilePassword />
            <AdminProfileUserList />
            <AdminProfileCreateUser />
            <UserProfileNotificationSettings />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileSettings

import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import AddLeadAssignmentForm from './components/AddLeadAssignmentForm'

const UsersQuery = gql`
  query Users {
    users(activeUsersOnly: true) {
      id
      name
      avatarFilename
    }
  }
`

const AddAssignedUserMutation = gql`
  mutation AddLeadAssignment($leadId: Int!, $userId: Int!) {
    addLeadAssignment(leadId: $leadId, userId: $userId) {
      id
    }
  }
`

const ComponentWithData = graphql(UsersQuery, {
  options: (props) => ({
    forceFetch: true,
  }),
})(AddLeadAssignmentForm)

const ComponentWithMutation = graphql(AddAssignedUserMutation, {
  props: ({ mutate }) => ({
    onSelect: ({ value: { leadId, userId } }) =>
      mutate({
        variables: { leadId, userId },
        refetchQueries: ['Lead', 'LeadActivities'],
      }),
  }),
})(ComponentWithData)

export default ComponentWithMutation

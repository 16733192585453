import {
  FORM_CLEARED,
  COMMENT_FORM_UPDATED,
  COMMENT_FORM_ERRORED,
} from '../core/action-types'

export const updateCommentForm = (formData) => ({
  type: COMMENT_FORM_UPDATED,
  payload: formData,
})

export const clearCommentForm = () => ({
  type: FORM_CLEARED,
})

export const validateCommentForm = ({ content }) => {
  const errors = {}
  if (!content || content.length === 0) errors.content = 1

  return {
    type: COMMENT_FORM_ERRORED,
    payload: errors,
  }
}

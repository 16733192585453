import React, { useEffect, useRef } from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'

const theme = (theme) => ({
  ...theme,
  borderRadius: 0,
})

const styles = {
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: 30,
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    marginBottom: 4,
    marginTop: 4,
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    padding: 0,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontSize: 12,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: 12,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontSize: 12,
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '0 8px',
    fontSize: 12,
  }),
}

export const DropdownSelect = (props) => {
  const selectRef = useRef(null)

  useEffect(() => {
    if (props.openMenuOnFocus) {
      selectRef.current?.focus()
    }
  }, [props.openMenuOnFocus, selectRef.current])

  return <Select ref={selectRef} {...props} theme={theme} styles={styles} />
}

export const CreatableSelect = (props) => {
  const selectRef = useRef(null)

  useEffect(() => {
    if (props.openMenuOnFocus) {
      selectRef.current?.focus()
    }
  }, [props.openMenuOnFocus, selectRef.current])

  return <Creatable ref={selectRef} {...props} theme={theme} styles={styles} />
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  MdInsertInvitation,
  MdCheck,
  MdTextsms,
  MdPerson,
  MdPersonOutline,
  MdSearch,
  MdList,
  MdClose,
  MdLink,
} from 'react-icons/md'
import './styles.scss'

import DeleteActivityButton from '../../../DeleteActivityButton'

import {
  LeadAddedContent,
  LeadUpdatedContent,
  LeadClosedContent,
  OwnersEditedContent,
  PairingRelatedContent,
} from './content'

import {
  LeadAddedMeta,
  LeadUpdatedMeta,
  LeadClosedMeta,
  GoalAddedMeta,
  GoalUpdatedMeta,
  GoalCompletedMeta,
  OwnerAddedMeta,
  OwnersAddedMeta,
  OwnerRemovedMeta,
  OwnersRemovedMeta,
  CommentAddedMeta,
  PairingCandidateMeta,
  PairingAcceptedMeta,
  PairingRejectedMeta,
} from './meta'

const ActivityCard = (props) => {
  const { activity, showLeadInfo } = props
  const { type, targets, changes, lead, isMultiDiff, diff } = activity

  let Icon, Meta, content
  let canDelete = false
  switch (type) {
    case 'LEAD_ADDED':
      Icon = MdSearch
      Meta = LeadAddedMeta
      content = <LeadAddedContent source={targets[0].source} />
      break
    case 'LEAD_UPDATED':
    case 'LEAD_STATUS_CHANGED':
      Icon = MdList
      Meta = LeadUpdatedMeta
      content = <LeadUpdatedContent fieldsChanged={changes} />
      break
    case 'LEAD_CLOSED':
      Icon = MdClose
      Meta = LeadClosedMeta
      content = (
        <LeadClosedContent
          closingOutcome={lead.closingOutcome}
          closingReasons={lead.closingReasons}
          closingComment={lead.closingComment}
          closedAt={lead.closedAt}
        />
      )
      break
    case 'GOAL_ADDED':
      Icon = MdInsertInvitation
      Meta = GoalAddedMeta
      content = targets[0].description
      break
    case 'GOAL_UPDATED':
      Icon = MdInsertInvitation
      Meta = GoalUpdatedMeta
      content = targets[0].description
      break
    case 'GOAL_COMPLETED':
      Meta = GoalCompletedMeta
      Icon = MdCheck
      content = targets[0].description
      break
    case 'OWNER_ADDED':
      Meta = isMultiDiff ? OwnersAddedMeta : OwnerAddedMeta
      Icon = MdPerson
      content = <OwnersEditedContent owners={targets} />
      break
    case 'OWNER_REMOVED':
      Meta = isMultiDiff ? OwnersRemovedMeta : OwnerRemovedMeta
      Icon = MdPersonOutline
      content = <OwnersEditedContent owners={targets} />
      break
    case 'COMMENT_ADDED':
      Icon = MdTextsms
      Meta = CommentAddedMeta
      content = targets[0].content
      canDelete = true
      break
    case 'PAIRING_CANDIDATE':
      Icon = MdLink
      Meta = PairingCandidateMeta
      content = <PairingRelatedContent type={type} diff={diff} />
      break
    case 'PAIRING_ACCEPTED':
      Icon = MdCheck
      Meta = PairingAcceptedMeta
      content = <PairingRelatedContent type={type} diff={diff} />
      break
    case 'PAIRING_REJECTED':
      Icon = MdClose
      Meta = PairingRejectedMeta
      content = <PairingRelatedContent type={type} diff={diff} />
      break
    default:
      return null
  }

  return (
    <div className="activity-card">
      <div className="type-icon">
        <Icon size={20} />
      </div>
      <div className="panel">
        <div>
          <DeleteActivityButton
            enabled={canDelete}
            id={activity.id}
            content={content}
          />
          <Meta showLeadInfo={showLeadInfo} {...activity} />
          <div className="content">{content}</div>
        </div>
      </div>
    </div>
  )
}

ActivityCard.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'LEAD_ADDED',
      'LEAD_UPDATED',
      'LEAD_STATUS_CHANGED',
      'LEAD_CLOSED',
      'GOAL_ADDED',
      'GOAL_UPDATED',
      'GOAL_COMPLETED',
      'COMMENT_ADDED',
      'OWNER_ADDED',
      'OWNER_REMOVED',
      'PAIRING_CANDIDATE',
      'PAIRING_ACCEPTED',
      'PAIRING_REJECTED',
    ]).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    createdAt: PropTypes.string.isRequired,
    targets: PropTypes.array.isRequired,
  }),
  showLeadInfo: PropTypes.bool,
}

ActivityCard.defaultProps = {
  showLeadInfo: false,
}

export default ActivityCard

import { Navigate } from 'react-router-dom'
// import { RouteObject } from 'react-router-dom'

import RequireAuth from './modules/core/components/RequireAuth'

import Authenticated from './modules/Authenticated'
import Unauthenticated from './modules/Unauthenticated'

import {
  LoginView,
  LeadsView,
  LeadDetailsView,
  ActivitiesView,
  DashboardView,
  ProjectTrackerView,
  ProfileSettingsView,
  PasswordRecoveryView,
  PasswordChangeView,
} from './modules/core/views'

function withAuth(element) {
  return <RequireAuth>{element}</RequireAuth>
}

/**
 * @type {RouteObject[]}
 */
const routes = [
  {
    path: '/',
    element: withAuth(<Authenticated />),
    children: [
      {
        index: true,
        element: <DashboardView />,
      },
      {
        path: 'leads',
        children: [
          {
            index: true,
            element: <LeadsView />,
          },
          {
            path: ':id',
            element: <LeadDetailsView />,
          },
        ],
      },
      {
        path: 'leads-list',
        element: <LeadsView listView />,
      },
      {
        path: 'activity',
        element: <ActivitiesView />,
      },
      {
        path: 'project-tracker',
        element: <ProjectTrackerView />,
      },
      {
        path: 'users/profile',
        element: <ProfileSettingsView />,
      },
      {
        path: 'password-change',
        element: <PasswordChangeView />,
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: '/auth',
    element: <Unauthenticated />,
    children: [
      {
        index: true,
        element: <LoginView />,
      },
      {
        path: 'recovery',
        element: <PasswordRecoveryView />,
      },
    ],
  },
]

export default routes

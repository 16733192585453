export const BETTER_PRICE = 'Better Price'
export const BETTER_TERMS = 'Better Terms'
export const CLIENT_REPUTATION = 'Client Reputation'
export const EXISTING_BROKER_RELATIONSHIP = 'Existing Broker Relationship'
export const EXISTING_SURETY_RELATIONSHIP = 'Existing Surety Relationship'
export const INSUFFICIENT_BUDGET = 'Insufficient Budget'
export const OUTSIDE_LOCATION = 'Outside Location'
export const PROJECT_SIZE = 'Project Size'
export const SHARE_THEIR_EXPOSURE = 'Share Their Exposure'
export const OTHER = 'Other'

export default {
  BETTER_PRICE,
  BETTER_TERMS,
  CLIENT_REPUTATION,
  EXISTING_BROKER_RELATIONSHIP,
  EXISTING_SURETY_RELATIONSHIP,
  INSUFFICIENT_BUDGET,
  OUTSIDE_LOCATION,
  PROJECT_SIZE,
  SHARE_THEIR_EXPOSURE,
  OTHER,
}

export type BasisBoxProps = {
  value: string
}

export default ({ value: basis }: BasisBoxProps) => {
  return (
    <div className="triage-item-cell">
      <span>
        <i>{basis}</i>
      </span>
    </div>
  )
}

import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

class ProjectName extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ projectName: selection })
  }

  render() {
    const { visible, projectNames, projectName } = this.props

    if (!visible) return null

    const options = projectNames
      .sort()
      .map((projectName) => ({ value: projectName, label: projectName }))

    return (
      <div id="project-name-filter" className="filter columns two">
        <div className="label">Project Name</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={projectName}
            onChange={this.onChange}
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default ProjectName

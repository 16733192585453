import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

import UserAvatar from '../../../core/components/UserAvatar'
import LeadDetailsDeleteLeadAssignment from '../../../LeadDetailsDeleteLeadAssignment'

class OwnersField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showWarning: false,
    }

    this.onDeleteAssignmentClicked = this.onDeleteAssignmentClicked.bind(this)
  }

  onDeleteAssignmentClicked(success) {
    if (!success) {
      this.setState({ showWarning: true })
      setTimeout(
        function () {
          this.setState({ showWarning: false })
        }.bind(this),
        2000,
      )
    }
  }

  render() {
    const { leadId, users } = this.props
    const canDeleteAssignment = users.length > 1
    const ownerList = users.map((user) => (
      <div className="assignedUser" key={user.id}>
        <UserAvatar user={user} />
        <LeadDetailsDeleteLeadAssignment
          enabled={canDeleteAssignment}
          leadId={parseInt(leadId, 10)}
          userId={parseInt(user.id, 10)}
          onClick={this.onDeleteAssignmentClicked}
        />
        {user.name}
      </div>
    ))

    const hasOwners = ownerList.length > 0
    const className = hasOwners ? 'value' : 'value invalid'
    const displayValue = hasOwners ? ownerList : 'Unassigned'
    const warning = this.state.showWarning ? (
      <span className="error-message">
        A project must have at least one owner
      </span>
    ) : null

    return (
      <div className="projectOwnersField">
        <span className="label">Project Owner(s)</span>
        <span className={className}>{displayValue}</span>
        {warning}
      </div>
    )
  }
}

OwnersField.propTypes = {
  users: PropTypes.array,
}

OwnersField.defaultProps = {
  users: [],
}

export default OwnersField

export const AUTHENTICATED = 'AUTHENTICATED'
export const FILTERS_UPDATED = 'FILTERS_UPDATED'
export const PROJECT_TRACKER_FILTERS_UPDATED = 'PROJECT_TRACKER_FILTERS_UPDATED'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const LEAD_DETAILS_FORM_UPDATED = 'LEAD_DETAILS_FORM_UPDATED'
export const FORM_CLEARED = 'FORM_CLEARED'
export const GOAL_FORM_UPDATED = 'GOAL_FORM_UPDATED'
export const GOAL_FORM_ERRORED = 'GOAL_FORM_ERRORED'
export const COMMENT_FORM_UPDATED = 'COMMENT_FORM_UPDATED'
export const COMMENT_FORM_ERRORED = 'COMMENT_FORM_ERRORED'
export const CSV_EXPORT_ERRORED = 'CSV_EXPORT_ERRORED'
export const CLOSE_TRANSITION = 'CLOSE_TRANSITION'
export const CLEAR_TRANSITION = 'CLEAR_TRANSITION'
export const TRANSITION_COMPLETE = 'TRANSITION_COMPLETE'
export const FILE_UPLOADED = 'FILE_UPLOADED'
export const CONTACTS_SYNCED = 'CONTACTS_SYNCED'
export const PASSWORD_CHANGE_REQUIRED = 'PASSWORD_CHANGE_REQUIRED'
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const SET_USER_AVATAR = 'SET_USER_AVATAR'

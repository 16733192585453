import React from 'react'
import PropTypes from 'prop-types'
import { MdRefresh } from 'react-icons/md'
import ProgressButton from '../../../core/components/ProgressButton'

const ContactsSyncButton = (props) => {
  return (
    <ProgressButton
      text="Sync"
      icon={<MdRefresh />}
      onClick={props.syncContacts}
      onComplete={props.onComplete}
    />
  )
}

ContactsSyncButton.propTypes = {
  syncContacts: PropTypes.func.isRequired,
}

ContactsSyncButton.defaultProps = {
  onComplete: () => {},
}

export default ContactsSyncButton

import React from 'react'
import { Outlet } from 'react-router-dom'

const Unauthenticated = () => (
  <div className="container">
    <Outlet />
  </div>
)

export default Unauthenticated

export const NONE = 'None'
export const LOW = 'Low'
export const MEDIUM = 'Medium'
export const HIGH = 'High'

export default {
  NONE,
  LOW,
  MEDIUM,
  HIGH,
}

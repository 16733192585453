import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import LeadResourceLinks from './components/LeadResourceLinks'

const LeadQuery = gql`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      developerName
    }
  }
`
const ComponentWithData = graphql(LeadQuery, {
  props: ({ data: { lead } }) => ({ lead }),
  options: (props) => ({
    variables: { id: props.id },
    forceFetch: true,
  }),
})(LeadResourceLinks)

export default ComponentWithData

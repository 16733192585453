import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import ResponsiveTable from '../ResponsiveTable'
import { useMutation, useQuery } from '@apollo/client'
import Button from '../../modules/core/components/Button'
import { PairingsListQuery, TriagePairingMutation } from './queries'
import { SelectionChangedEvent } from 'ag-grid-community'
import {
  GQLPairing,
  TriageItem,
  GQLPairingStatusFilter,
  GQLPairingResponse,
} from './types'
import LeadBox from './components/LeadBox'
import ProjectBox from './components/ProjectBox'
import BasisBox from './components/BasisBox'
import { GoalsListQuery } from '@/modules/ActivitiesList'
import { LeadListQuery } from '@/modules/LeadsCatalog'
import { GQLProject, GQLLead } from '@/modules/Pairings/types'

const triageStatusOptions = ['PENDING', 'ACCEPTED', 'REJECTED']

// there is a typing problem with this library: https://github.com/JedWatson/react-select/issues/5032
const selectOptions: any = triageStatusOptions.map((status) => ({
  value: status,
  label: status,
}))
const defaultOption = selectOptions[0]

type PairingsListProps = {
  onError: (message: string) => void
  onNotify: (message: string) => void
  refetch?: boolean
}

const PairingsList = (props: PairingsListProps) => {
  const { onError, onNotify, refetch } = props
  const {
    loading,
    error,
    data,
    refetch: refetchPairings,
  } = useQuery<GQLPairingResponse, GQLPairingStatusFilter>(PairingsListQuery, {
    variables: { triageStatus: 'PENDING' },
  })
  const [saveTriageStatus] = useMutation(TriagePairingMutation, {
    refetchQueries: [
      { query: GoalsListQuery },
      PairingsListQuery,
      LeadListQuery,
    ],
  })
  const currentUser = useSelector((state: any) => state.user)
  const [batchTriageStatus, setBatchTriageStatus] = useState(defaultOption)
  const [selections, setSelections] = useState<string[]>([])
  const [triaged, setTriaged] = useState<string[]>([])
  const [displayData, setDisplayData] = useState<GQLPairing[]>([])

  const { pairings } = data || {}

  useEffect(() => {
    if (refetch) {
      refetchPairings()
    }
  }, [refetch])

  useEffect(() => {
    const newDisplayData =
      pairings?.filter(
        (pairing: GQLPairing) => !triaged.includes(pairing.id),
      ) || []
    setDisplayData(newDisplayData)
  }, [pairings, triaged])

  const save = async (triageList: TriageItem[]) => {
    try {
      await saveTriageStatus({
        variables: {
          triageList,
        },
        update(cache, { data: { triagePairings } }) {
          triagePairings.forEach((pairing: any) => {
            // merge the partial type back into the Pairings cache
            const id = `Pairing:${pairing.id}`
            cache.modify({
              id,
              fields: {
                triageStatus(_cachedStatusVal) {
                  return pairing.triageStatus
                },
              },
            })
          })
        },
      })

      if (triageList.length < 2)
        onNotify(`Triage status updated to ${triageList[0].triageStatus}`)
      else
        onNotify(
          `Triage status updated to ${triageList[0].triageStatus} for ${triageList.length} pairings`,
        )
      setTriaged([...triaged, ...triageList.map((_) => _.id)])
      return true
    } catch (error) {
      console.error('valueSetter failed:', error)
      onError('Failed to update triage status. Please try again.')
      return false
    }
  }

  const batchSave = async () => {
    const triageList = selections.map((id) => ({
      id,
      triageStatus: batchTriageStatus.value,
      triagedBy: currentUser.id,
    }))
    await save(triageList)
  }

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const idList = event.api.getSelectedRows().map((row) => row.id)
    setSelections(idList)
  }

  const columnDefs = [
    {
      headerName: '',
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      maxWidth: 50,
      sortable: false,
    },
    {
      headerName: 'Lead',
      field: 'lead',
      cellRenderer: LeadBox,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          {
            displayKey: 'semiFulltextSearch',
            displayName: 'contains text',
            predicate: (filterValue: string, cellValue: any) => {
              return new RegExp(filterValue, 'i').test(
                `${cellValue.projectName} ${cellValue.address}`,
              )
            },
          },
        ],
      },
      sort: 'asc',
      comparator: (a: GQLLead, b: GQLLead) =>
        a.projectName.localeCompare(b.projectName),
      flex: 3,
      minWidth: 300,
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: 'Matching WG Net Project',
      field: 'wgnetProject',
      cellRenderer: ProjectBox,
      wrapText: true,
      autoHeight: true,
      flex: 4,
      minWidth: 400,
      sortable: true,
      comparator: (a: GQLProject, b: GQLProject) =>
        a.name.localeCompare(b.name),
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: 'Reason for Match',
      field: 'basis',
      cellRenderer: BasisBox,
      wrapText: true,
      autoHeight: true,
      maxWidth: 200,
      minWidth: 100,
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: 'Triage Status',
      field: 'triageStatus',
      maxWidth: 150,
      minWidth: 100,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: triageStatusOptions,
      },
      editable: true,
      singleClickEdit: true,
      suppressKeyboardEvent: true,
      valueSetter: (params: any) => {
        const oldValue = params.data.triageStatus
        const newValue = params.newValue

        if (oldValue !== newValue) {
          save([
            {
              id: params.data.id,
              triageStatus: newValue,
              triagedBy: currentUser.id,
            },
          ])
        }
      },
    },
  ]

  if (loading || !data) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return (
    <div className="triage-view">
      <div className="batcher">
        <Select
          className="triage-selector"
          value={batchTriageStatus}
          onChange={(newValue) =>
            setBatchTriageStatus(newValue || defaultOption)
          }
          options={selectOptions}
          getOptionValue={(_: any) => _.value}
          getOptionLabel={(_: any) => _.label}
        />
        <Button label="Batch Triage" onClick={batchSave} />
      </div>
      <div className="scrollpane">
        <ResponsiveTable
          rowData={displayData}
          columnDefs={columnDefs}
          rowSelection="multiple"
          domLayout="normal"
          enableFilter={true}
          scrollbarSize={10}
          onSelectionChanged={onSelectionChanged}
          stopEditingWhenGridLosesFocus={true}
          rowDeselection={true}
          rowHeight={150}
          reactiveCustomComponents={true}
        />
      </div>
    </div>
  )
}

export default PairingsList

import React, { Component } from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import './styles.scss'

import { LeadStatusTypes } from '../../../../common/enumerations'

class StatusType extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection) {
    this.props.onChange({ statusType: selection })
  }

  render() {
    const { visible, statusType } = this.props

    if (!visible) return null

    const statusTypes = Object.keys(LeadStatusTypes)
    const options = statusTypes.map((statusType) => ({
      value: statusType,
      label: LeadStatusTypes[statusType].label,
    }))

    return (
      <div id="status-type-filter" className="filter columns two">
        <div className="label">Status</div>
        <div className="value">
          <DropdownSelect
            options={options}
            value={statusType}
            onChange={this.onChange}
            placeholder="All"
            isClearable
          />
        </div>
      </div>
    )
  }
}

export default StatusType

import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import Moment from 'moment'

import LeadDetailsGoals from './components/LeadDetailsGoals'

const GoalsQuery = gql`
  query LeadGoals($id: ID!) {
    lead(id: $id) {
      status
      upcomingGoals {
        id
        description
        deadline
        leadId
      }
      missedGoals {
        id
        description
        deadline
        leadId
      }
    }
  }
`

const GoalCompleteMutation = gql`
  mutation CompleteGoal($id: ID!, $leadId: Int!) {
    completeGoal(id: $id, leadId: $leadId) {
      id
    }
  }
`

const ComponentWithGoalsData = graphql(GoalsQuery, {
  props: ({ ownProps: { onComplete }, data: { lead } }) => {
    if (!lead || lead.status === 'CLOSED') return null

    const { missedGoals, upcomingGoals } = lead
    const goalNeeded = missedGoals.concat(upcomingGoals).length === 0

    const augmentGoal = (goal) => ({
      ...goal,
      deadline: Moment(goal.deadline),
      onComplete: () => onComplete(goal),
    })

    missedGoals.forEach(augmentGoal)
    upcomingGoals.forEach(augmentGoal)

    return {
      missedGoals,
      upcomingGoals,
      goalNeeded,
    }
  },
  options: ({ id }) => ({
    variabled: { id },
    forceFetch: true,
  }),
})(LeadDetailsGoals)

const ComponentWithGoalCompleteMutation = graphql(GoalCompleteMutation, {
  props: ({ ownProps, mutate }) => ({
    onComplete: ({ id }) =>
      mutate({
        variables: { id, leadId: ownProps.id },
        refetchQueries: ['LeadGoals', 'LeadActivities', 'Lead'],
      }),
  }),
})(ComponentWithGoalsData)

export default ComponentWithGoalCompleteMutation

export const labels: { [key: string]: string } = {
  APPLICATION_DENIED: 'Application Denied',
  APPLICATION_RECEIVED: 'Application Received',
  APPLICATION_SENT: 'Application Sent',
  AVOID: 'Avoid',
  CLOSED: 'Closed',
  COMPLETE: 'Complete',
  CONSTRUCTION: 'Construction',
  EXISTING: 'Existing',
  IN_PROCESS: 'In Process',
  LOST: 'Lost',
  NEW: 'New',
  OWNER_ASSIGNED: 'Owner Assigned',
  PENDING: 'Pending',
  POTENTIAL: 'Potential',
  PRE_CONSTRUCTION: 'Pre-Construction',
  REGISTRATION: 'Registration',
  SELLING: 'Selling',
  TC_ACCEPTED: 'T & C Accepted',
  TC_CANCELLED: 'T & C Cancelled',
  TC_ISSUED: 'T & C Issued',
  TC_NOT_ACCEPTED: 'T & C Not Accepted',
  TERMS_SENT: 'Terms Sent',
  UNDERWRITING: 'Underwriting',
}

export const clientTypeLabels: { [key: string]: string } = {
  ['new']: 'Non-Existing',
  ['existing']: 'Existing',
}

export const toLabel = (key: string) => labels[key] || key
export const toClientTypeLabel = (key: string) =>
  key ? clientTypeLabels[key.toLowerCase()] || key : 'unknown'

import React from 'react'
import { DropdownSelect } from '../../../core/components/DropdownSelect'
import UserAvatar from '../../../core/components/UserAvatar'
import './styles.scss'

const AssignedUser = (props) => {
  const valueRenderer = (option) => {
    return (
      <span className="option">
        <UserAvatar user={option.user} />
        {option.label}
      </span>
    )
  }

  const onChange = (selection) => {
    props.onChange({ assignedUser: selection })
  }

  const { visible, userList, assignedUser } = props

  if (!visible) return null

  const options = [{ value: null, label: 'Unassigned', user: {} }].concat(
    userList.sort(),
  )

  return (
    <div id="assigned-user-filter" className="filter columns two">
      <div className="label">Project Owner</div>
      <div className="value">
        <DropdownSelect
          placeholder="All"
          options={options}
          value={assignedUser}
          onChange={onChange}
          formatOptionLabel={valueRenderer}
          isClearable
        />
      </div>
    </div>
  )
}

export default AssignedUser

import React from 'react'
import { Link } from 'react-router-dom'

import UserAvatar from '../UserAvatar'

export const Meta = (props, info) => {
  const { user, createdAt, showLeadInfo, lead } = props
  const { action, preposition, hideUser } = info

  const metaText = showLeadInfo ? (
    <span>
      {action}{' '}
      <span>
        {preposition} <Link to={`/leads/${lead.id}`}>{lead.projectName}</Link>
      </span>{' '}
      on {createdAt}
    </span>
  ) : (
    <span>
      {action} {action ? 'on' : ''} {createdAt}
    </span>
  )

  if (!hideUser) {
    return (
      <div className="meta">
        <UserAvatar user={user} />
        <span className="user">{user.name}</span> {metaText}
      </div>
    )
  } else {
    return <div className="meta">{metaText}</div>
  }
}

export const LeadAddedMeta = (props) =>
  Meta(props, { action: '', preposition: '', hideUser: true })
export const LeadUpdatedMeta = (props) =>
  Meta(props, { action: 'updated the lead', preposition: 'for' })
export const LeadClosedMeta = (props) =>
  Meta(props, { action: 'closed the lead', preposition: 'for' })
export const GoalAddedMeta = (props) =>
  Meta(props, { action: 'added a goal', preposition: 'for' })
export const GoalUpdatedMeta = (props) =>
  Meta(props, { action: 'updated a goal', preposition: 'for' })
export const GoalCompletedMeta = (props) =>
  Meta(props, { action: 'marked a goal as complete', preposition: 'for' })
export const OwnerAddedMeta = (props) =>
  Meta(props, { action: 'added an owner', preposition: 'to' })
export const OwnersAddedMeta = (props) =>
  Meta(props, { action: 'added owners', preposition: 'to' })
export const OwnerRemovedMeta = (props) =>
  Meta(props, { action: 'removed an owner', preposition: 'from' })
export const OwnersRemovedMeta = (props) =>
  Meta(props, { action: 'removed owners', preposition: 'from' })
export const CommentAddedMeta = (props) =>
  Meta(props, { action: 'added a comment', preposition: 'to' })
export const PairingCandidateMeta = (props) =>
  Meta(props, { action: 'proposed a wgnet project link', preposition: 'for' })
export const PairingAcceptedMeta = (props) =>
  Meta(props, { action: 'accepted a wgnet project link', preposition: 'for' })
export const PairingRejectedMeta = (props) =>
  Meta(props, { action: 'rejected a wgnet project link', preposition: 'for' })
